import React, { useEffect, useState } from "react"
import { Card, Col, Container, Row } from "reactstrap"
import { useTranslation } from "react-i18next"
import { useLocation, useNavigate } from "react-router-dom"
import useFilters from "components/Shared/useFilters"
import Title from "components/Shared/Title"
import Filters from "components/Shared/Filters"
import {
  baseFilterOptions,
  commonFilterOptions,
} from "components/Common/constants"
import SingleNews from "./SingleNews"
import DeleteModal from "components/Common/DeleteModal"
import TopTitle from "components/Shared/TopTitle"
import { useDispatch, useSelector } from "react-redux"
import {
  deleteNews,
  getAllNews,
  updateNewsStatus,
} from "store/actions/newsAction"
import { getDateRange } from "components/Common/Utils"
import Spinners from "components/Shared/Spinners"
import NoDataAvailable from "components/Shared/NoDataAvailable"
import Paginations from "components/Common/Pagination"
import { showAlert } from "store/reducers/alertReducer"

const News = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  document.title = t("News | Takween")

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { search } = useLocation()
  const pageNumber = Number(new URLSearchParams(search).get("page")) || 1

  const name = useSelector(state => state.Login.userProfile?.name) || ""

  const {
    loading,
    isLoading,
    totalCount,
    totalPages,
    currentPage,
    perPage,
    news,
  } = useSelector(state => state.News)

  const [
    filters,
    keyword,
    setKeyword,
    dateRange,
    setDateRange,
    flatpickrRef,
    selectedId,
    setSelectedId,
    itemIdToDelete,
    setItemIdToDelete,
    showDeleteModal,
    setShowDeleteModal,
    isApiCalled,
    setIsApiCalled,
    handleFilterChange,
    resetFilters,
  ] = useFilters({
    status: -1,
    orderBy: 2,
  })

  useEffect(() => {
    fetchAllNews()
  }, [])

  const fetchAllNews = page => {
    const { status, orderBy } = filters
    const payload = {
      perPage: perPage,
      pageNo: page || pageNumber,
      status: status,
      orderBy,
      keyword,
      dateRange: getDateRange(dateRange),
    }
    dispatch(getAllNews(payload))
    setIsApiCalled(true)

    navigate(`/news?page=${page || pageNumber}`)
  }

  useEffect(() => {
    if (isApiCalled) {
      handleSearch()
    }
  }, [keyword, filters])

  useEffect(() => {
    if (isApiCalled) {
      if (dateRange.min && dateRange.max) handleSearch()
    }
  }, [dateRange])

  const handleSearch = () => {
    fetchAllNews(1)
  }

  const onPageChange = page => {
    fetchAllNews(page)
  }

  const handleStatusClick = (id, status) => {
    setSelectedId(id)
    const payload = {
      id,
      status: status === 1 ? "0" : "1",
    }
    dispatch(updateNewsStatus(payload))
      .then(action => {
        const { statusCode, success, message } = action?.payload
        if (statusCode === 200 && success) {
          dispatch(showAlert({ text: message, type: "success" }))
          setSelectedId(null)
        } else {
          dispatch(showAlert({ text: message, type: "error" }))
        }
      })
      .catch(error => {})
  }

  const handleDelete = () => {
    const payload = {
      id: itemIdToDelete,
      status: "2", // 2 FOR DELETE
    }
    dispatch(deleteNews(payload))
      .then(action => {
        const { statusCode, success, message } = action?.payload
        if (statusCode === 200 && success) {
          dispatch(showAlert({ text: message, type: "success" }))
          setItemIdToDelete(null)
          setShowDeleteModal(false)
        } else {
          dispatch(showAlert({ text: message, type: "error" }))
        }
      })
      .catch(error => {})
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <TopTitle
            title={t("News")}
            subTitle={t(
              `Hi, {{name}}. Welcome back to your Marketing portal!`,
              { name: name }
            )}
            createBtnText={t("Create News")}
            onCreateBtnClick={() => {
              navigate("/news/create")
            }}
          />
          <Row>
            <Col lg={12}>
              <Card>
                <Title title={t("News List")} />
                <div className="pb-2"></div>
                <Filters
                  isGlobalFilter={true}
                  SearchPlaceholder={t("Search")}
                  keyword={keyword}
                  setKeyword={setKeyword}
                  filters={commonFilterOptions}
                  selectedFilters={filters}
                  handleFilterChange={handleFilterChange}
                  handleReset={() => resetFilters()}
                  isRangeDate={true}
                  flatpickrRef={flatpickrRef}
                  dateRange={dateRange}
                  setDateRange={setDateRange}
                  isResetButton={true}
                />
              </Card>
            </Col>
          </Row>
          <Row>
            {loading ? (
              <Spinners />
            ) : news.length === 0 ? (
              <NoDataAvailable />
            ) : (
              news.map(singleNews => (
                <SingleNews
                  t={t}
                  key={singleNews.id}
                  news={singleNews}
                  handleStatusClick={handleStatusClick}
                  setShowDeleteModal={setShowDeleteModal}
                  isLoading={isLoading}
                  selectedId={selectedId}
                  setItemIdToDelete={setItemIdToDelete}
                />
              ))
            )}
            {!loading && news.length > 0 && (
              <Paginations
                currentPage={currentPage}
                totalPages={totalPages}
                totalCount={totalCount}
                onPageChange={onPageChange}
              />
            )}
          </Row>
        </Container>
      </div>
      <DeleteModal
        show={showDeleteModal}
        onDeleteClick={handleDelete}
        onCloseClick={() => {
          setItemIdToDelete(null)
          setShowDeleteModal(false)
        }}
        isLoading={isLoading}
        buttonText={t("Delete")}
        bodyTitle={t("Are you sure you want to delete this {{name}}?", {
          name: t("News"),
        })}
      />
    </React.Fragment>
  )
}

export default News
