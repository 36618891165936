import React from "react"
import PropTypes from "prop-types"
import ReactApexChart from "react-apexcharts"
import getChartColorsArray from "../../components/Common/ChartsDynamicColor"
import { getMonthLabels } from "components/Common/Utils"

const StackedColumnChart = ({
  dataColors,
  periodData,
  height = null,
  chartLabels = [],
}) => {
  const stackedColumnChartColors = getChartColorsArray(dataColors)
  const options = {
    chart: {
      stacked: !0,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: !0,
      },
    },
    plotOptions: {
      bar: {
        horizontal: !1,
        borderRadius: 8,
        columnWidth: "16%",
        // endingShape: "rounded"
      },
    },
    dataLabels: {
      enabled: !1,
    },
    xaxis: {
      show: true,
      categories: chartLabels,
      labels: {
        show: true,
        style: {
          colors: ["#0C3553"],
          fontSize: "16px",
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: ["#0C3553"],
          fontSize: "16px",
        },
      },
    },
    colors: stackedColumnChartColors,
    legend: {
      position: "bottom",
    },
    fill: {
      opacity: 1,
    },
  }

  return (
    <React.Fragment>
      <ReactApexChart
        options={options}
        series={[...periodData]}
        type="bar"
        height={height || "359"}
        className="apex-charts"
      />
    </React.Fragment>
  )
}

StackedColumnChart.propTypes = {
  periodData: PropTypes.any,
}
export default StackedColumnChart
