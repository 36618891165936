import axios from "axios"
import getAccessToken from "./accessToken"

//apply base url for axios
const API_URL = process.env.REACT_APP_API_BASE_URL
const axiosApi = axios.create({
  baseURL: API_URL,
})

// axiosApi.defaults.headers.common["accept-language"] = "en"

axiosApi.interceptors.request.use(
  async config => {
    const token = await getAccessToken()
    if (token) {
      config.headers.Authorization = token
    }

    const language =
      typeof localStorage !== "undefined"
        ? localStorage.getItem("I18N_LANGUAGE")
        : null
    config.headers["Accept-Language"] = language === "sa" ? "ar" : "en"

    return config
  },
  error => {
    return Promise.reject(error)
  }
)

axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)

export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function patch(url, config = {}) {
  return await axiosApi
    .patch(url, { ...config })
    .then(response => response.data)
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function del(url, data, config = {}) {
  return await axiosApi
    .delete(url, { ...config, data: { ...data } })
    .then(response => response.data)
}

export async function formDataPost(url, data, config = {}) {
  return axiosApi.post(url, data, { ...config }).then(response => response.data)
}

export async function formDataPut(url, data, config = {}) {
  return axiosApi.put(url, data, { ...config }).then(response => response.data)
}

export async function exportData(url, data, config) {
  return axiosApi
    .post(url, data, { ...config, responseType: "blob" })
    .then(response => response.data)
}
