import React from "react"
import { useTranslation } from "react-i18next"

const NoDataAvailable = ({ text }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  return (
    <div>
      <h5 className="text-center py-5 fw-bold text-primary">
        {text || t("No data available")}
      </h5>
    </div>
  )
}

export default NoDataAvailable
