import React from "react"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap"
import { useTranslation } from "react-i18next"
import { ErrorMessage, Field, Form, Formik } from "formik"
import Select from "react-select"
import {
  pushNotificationSchema,
  smsNotificationSchema,
} from "components/Common/validation"
import { useNavigate } from "react-router-dom"
import TopTitle from "components/Shared/TopTitle"
import CustomLabel from "components/Shared/CustomLabel"
import CustomErrorMessage from "components/Shared/CustomErrorMessage"
import FormButtons from "components/Shared/FormButtons"
import { useDispatch, useSelector } from "react-redux"
import { getCustomersList } from "store/actions/customersAction"
import { showAlert } from "store/reducers/alertReducer"
import { SMSBroadCast } from "store/actions/notificationsActions"

const initialValues = {
  type: "all",
  body: "",
  customers: [],
  smsLanguage: "en",
}

const SMSNotifications = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  document.title = t("SMS | Takween")
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { isLoading } = useSelector(state => state.Notifications)

  const name = useSelector(state => state.Login.userProfile?.name) || ""
  const { customersList } = useSelector(state => state.Customers)

  const handleOnSubmit = (values, { resetForm }) => {
    const { type, body, customers, smsLanguage } = values
    const updatedCustomers =
      type === "specific-customers"
        ? customers?.map(item => item.value) || []
        : []

    const payload = {
      type: type,
      customers: updatedCustomers,
      message: body,
      smsLanguage,
    }

    dispatch(SMSBroadCast(payload))
      .then(action => {
        const { statusCode, success, message } = action?.payload
        if (statusCode === 200 && success) {
          dispatch(showAlert({ text: message, type: "success" }))
          resetForm()
        } else {
          dispatch(showAlert({ text: message, type: "error" }))
        }
      })
      .catch(error => {})
  }

  const fetchCustomersList = value => {
    const payload = {
      keyword: value,
    }
    dispatch(getCustomersList(payload))
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <TopTitle
            title={t("Notification Control")}
            subTitle={t(
              `Hi, {{name}}. Welcome back to your Marketing portal!`,
              { name: name }
            )}
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <h2 className="fs_18_700">{t("SMS Notifications")}</h2>
                  <Formik
                    initialValues={initialValues}
                    validationSchema={smsNotificationSchema}
                    onSubmit={handleOnSubmit}
                  >
                    {({ values, errors, touched, setFieldValue }) => (
                      <Form>
                        <Row className="mb-4">
                          <Col md={12}>
                            <div className="form_field">
                              <CustomLabel
                                htmlFor="body"
                                label={t("Message")}
                                isRequired={true}
                              />
                              <Field
                                as="textarea"
                                id="body"
                                name="body"
                                rows={4}
                                placeholder={t("Message")}
                                className={`form-control ${
                                  touched.body && errors.body
                                    ? "is-invalid"
                                    : ""
                                }`}
                              />
                              <CustomErrorMessage name={"body"} />
                            </div>
                          </Col>

                          <Col lg={6}>
                            <div className="form_field">
                              <CustomLabel
                                htmlFor="smsLanguage"
                                label={t("Select SMS Language")}
                                isRequired={true}
                              />
                              <div className="select_wrapper">
                                <Field
                                  as="select"
                                  name="smsLanguage"
                                  className={`form-control b_r_12 ${
                                    errors.smsLanguage && touched.smsLanguage
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  onChange={e => {
                                    const selectedValue = e.target.value
                                    setFieldValue("smsLanguage", selectedValue)
                                  }}
                                >
                                  <option hidden>
                                    {t("Select SMS Language")}
                                  </option>
                                  <option value={"en"}>{t("English")}</option>
                                  <option value={"ar"}>{t("Arabic")}</option>
                                </Field>
                              </div>
                              <CustomErrorMessage name={"status"} />
                            </div>
                          </Col>

                          <Col md={6}>
                            <div className="mt-3 g_label">
                              <label htmlFor="name">{t("Select Type")}</label>
                              <div className="d-flex ">
                                <div className="form-check mx-2">
                                  <Field
                                    className="form-check-input"
                                    type="radio"
                                    name="type"
                                    id="all"
                                    value="all"
                                    onClick={() => {
                                      setFieldValue("type", "all")
                                    }}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="all"
                                  >
                                    {t("All")}
                                  </label>
                                </div>
                                <div className="form-check mx-2">
                                  <Field
                                    className="form-check-input"
                                    type="radio"
                                    name="type"
                                    id="specific-customers"
                                    value="specific-customers"
                                    onClick={() => {
                                      setFieldValue(
                                        "type",
                                        "specific-customers"
                                      )
                                    }}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="specific-customers"
                                  >
                                    {t("Specific Customers")}
                                  </label>
                                </div>
                              </div>
                            </div>
                          </Col>
                          {values.type === "specific-customers" && (
                            <Col md={6} className="g_label">
                              <>
                                <label htmlFor="">
                                  <span className="text-danger">*</span>{" "}
                                  {t("Please Type To Search Customer")}
                                </label>
                                <Select
                                  value={values.customers}
                                  isMulti={true}
                                  closeMenuOnSelect={false}
                                  onChange={selectedValue => {
                                    setFieldValue("customers", selectedValue)
                                  }}
                                  options={customersList?.map(item => ({
                                    label: `${item.name || ""} ${
                                      item.phone || ""
                                    }`,
                                    value: item._id,
                                  }))}
                                  onInputChange={value =>
                                    fetchCustomersList(value)
                                  }
                                  placeholder={t(
                                    "Please Type To Search Customer"
                                  )}
                                  className={`select2-selection b_r_12 ${
                                    touched.customers && errors.customers
                                      ? "danger_color_border"
                                      : ""
                                  } `}
                                />
                                <CustomErrorMessage name={"customers"} />
                              </>
                            </Col>
                          )}
                          <Col lg={12}>
                            <FormButtons
                              buttonText={t("Send")}
                              isLoading={isLoading}
                            />
                          </Col>
                        </Row>
                      </Form>
                    )}
                  </Formik>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default SMSNotifications
