import React from "react"
import { Spinner } from "reactstrap"

const Spinners = () => {
  return (
    <div className="page-content">
      <Spinner color="primary" className="position-absolute top-50 start-50" />
    </div>
  )
}

export default Spinners
