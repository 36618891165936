import React, { useEffect } from "react"
import {
  Badge,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Table,
} from "reactstrap"
import { useTranslation } from "react-i18next"
import BackButton from "components/Shared/BackButton"
import NoDataAvailable from "components/Shared/NoDataAvailable"
import DetailsTitle from "components/Shared/DetailsTitle"
import DetailsInnerDiv from "components/Shared/DetailsInnerDiv"
import { getUserDetails } from "store/actions/usersAction"
import { useDispatch, useSelector } from "react-redux"
import { useLocation } from "react-router-dom"
import FormateDate from "components/Shared/FormateDate"
import { ShimmerThumbnail } from "react-shimmer-effects"

const UserDetails = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  document.title = t("User Details | Takween")

  const dispatch = useDispatch()
  const { loading, userDetails } = useSelector(state => state.Users)

  const { search } = useLocation()
  const id = new URLSearchParams(search).get("id")

  useEffect(() => {
    const fetchUserDetails = () => {
      const payload = {
        id,
      }
      dispatch(getUserDetails(payload))
    }
    fetchUserDetails()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <DetailsTitle title={t("User Details")} />

                  <div className="">
                    {loading ? (
                      <ShimmerThumbnail
                        height={250}
                        rounded
                        className={"mb-0"}
                      />
                    ) : Object.values(userDetails).length === 0 ? (
                      <p className="text-center my-3">
                        <NoDataAvailable />
                      </p>
                    ) : (
                      <Row className="g_details">
                        <Col md={3}>
                          <DetailsInnerDiv
                            label={t("Id")}
                            value={userDetails._id || "-"}
                          />
                        </Col>
                        <Col md={3}>
                          <DetailsInnerDiv
                            label={t("First Name")}
                            value={userDetails.firstName || "-"}
                          />
                        </Col>
                        <Col md={3}>
                          <DetailsInnerDiv
                            label={t("Last Name")}
                            value={userDetails.lastName || "-"}
                          />
                        </Col>
                        <Col md={3}>
                          <DetailsInnerDiv
                            label={t("Name")}
                            value={userDetails.name || "-"}
                          />
                        </Col>
                        <Col md={3}>
                          <DetailsInnerDiv
                            label={t("Email")}
                            value={userDetails.email || "-"}
                          />
                        </Col>
                        <Col md={3}>
                          <DetailsInnerDiv
                            label={t("Account Type")}
                            value={t(userDetails.accountType || "-")}
                          />
                        </Col>
                        <Col md={3}>
                          <DetailsInnerDiv
                            label={t("Phone Number")}
                            value={userDetails.phoneNumber || "-"}
                          />
                        </Col>
                        <Col md={3}>
                          <DetailsInnerDiv
                            label={t("Employee Id")}
                            value={userDetails.employeeId || "-"}
                          />
                        </Col>
                        <Col md={3}>
                          <DetailsInnerDiv
                            label={t("Status")}
                            value={
                              userDetails.status === 1
                                ? t("Active")
                                : t("Deactive")
                            }
                            valueClass={
                              userDetails.status === 1
                                ? "active_color"
                                : "danger_color"
                            }
                          />
                        </Col>
                        <Col md={3}>
                          <DetailsInnerDiv
                            label={t("Created At")}
                            value={FormateDate(userDetails.createdAt) || "-"}
                          />
                        </Col>
                        <Col md={3}>
                          <DetailsInnerDiv
                            label={t("Updated At")}
                            value={FormateDate(userDetails.updatedAt) || "-"}
                          />
                        </Col>
                      </Row>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default UserDetails
