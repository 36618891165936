import Tooltip from "components/Shared/Tooltip"
import React from "react"
import { Card, CardBody, CardTitle, Col } from "reactstrap"
import Banner from "assets/images/garbage/banner.png"
import { Link, useNavigate } from "react-router-dom"
import Switch from "components/Shared/Switch"
import TrashIcon from "assets/images/icons/trash.svg"
import EditIcon from "assets/images/icons/edit.svg"
import { useTranslation } from "react-i18next"

const SingleBanner = ({
  t,
  banner,
  setShowDeleteModal,
  handleStatusClick,
  selectedId,
  isLoading,
  setItemIdToDelete,
}) => {
  const navigate = useNavigate()
  const {
    i18n: { language },
  } = useTranslation()
  return (
    <Col md={4}>
      <Card className="border rounded-4">
        <CardBody style={{ padding: 15 }}>
          <div className="single_banner">
            <Link to={`/banners/banner-details?id=${banner._id}`}>
              <h2 className="fs_18_700">
                {language === "sa" ? banner.titleAr : banner.title}
              </h2>
              <img
                src={banner.imageUrl}
                alt=""
                className="banner_img"
                height={250}
              />
            </Link>
            <div className="mt-3 g_between">
              <div>
                <img
                  src={EditIcon}
                  id={`edit_${banner._id}`}
                  alt="Edit Icon"
                  width={24}
                  height={24}
                  className="cursor-pointer"
                  onClick={() => navigate(`/banners/update?id=${banner._id}`)}
                />
                <img
                  src={TrashIcon}
                  alt="Trash Icon"
                  width={24}
                  height={24}
                  id={`delete_${banner._id}`}
                  className="cursor-pointer ms-3"
                  onClick={() => {
                    setItemIdToDelete(banner._id)
                    setShowDeleteModal(true)
                  }}
                />
              </div>
              <div style={{ marginRight: 10 }}>
                <Switch
                  onClickHandler={() => {
                    handleStatusClick(banner._id, banner.status)
                  }}
                  isChecked={banner.status === 1 ? true : false}
                  disabled={selectedId === banner._id && isLoading}
                />
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    </Col>
  )
}

export default SingleBanner
