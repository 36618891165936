import React, { useEffect, useState } from "react"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import TopTitle from "components/Shared/TopTitle"
import { Field, FieldArray, Form, Formik } from "formik"
import CustomLabel from "components/Shared/CustomLabel"
import CustomField from "components/Shared/CustomField"
import FormButtons from "components/Shared/FormButtons"
import { handleSquareFileUpload } from "components/Common/Utils"
import FileUpload from "components/Shared/FileUpload"
import CustomErrorMessage from "components/Shared/CustomErrorMessage"
import { createUnitSchema } from "components/Common/validation"
import TrashIcon from "assets/images/icons/trash.svg"
import {
  unitAreaOptions,
  unitStatusOptions,
  unitTypeOptions,
} from "components/Common/constants"
import { useDispatch, useSelector } from "react-redux"
import { getRoomTypesList } from "store/actions/roomTypesAction"
import { createUnit } from "store/actions/unitsAction"
import { showAlert } from "store/reducers/alertReducer"
import { getProjectsList } from "store/actions/projectsAction"

const initialValues = {
  name: "",
  nameAr: "",
  project: "",
  description: "",
  descriptionAr: "",
  address: "",
  // latitude: "",
  // longitude: "",
  unitType: "",
  area: "",
  areaUnit: "",
  image: "",
  unitPrice: "",
  realEstateTax: "",
  // pursuit: "",
  // questTax: "",
  statusType: "",
  isListedForRent: true,
  roomDetails: [{ roomType: "", count: "" }],
}

const CreateUnit = ({ mode }) => {
  // mode = projectUnits (For project unit), allUnits (For all Units)
  const {
    t,
    i18n: { language },
  } = useTranslation()
  document.title = t("Create Unit | Takween")

  const navigate = useNavigate()
  const { projectId } = useParams()

  const [searchParams] = useSearchParams()
  const dispatch = useDispatch()

  const { loading, isLoading } = useSelector(state => state.Units)
  const { projectsList } = useSelector(state => state.Projects)
  const { roomTypesList } = useSelector(state => state.RoomTypes)

  const [formValues, setFormValues] = useState(initialValues)

  useEffect(() => {
    fetchRoomTypesList()
    mode === "allUnits" && fetchProjectsList()
  }, [])

  const fetchRoomTypesList = () => {
    dispatch(getRoomTypesList())
  }

  const fetchProjectsList = () => {
    dispatch(getProjectsList())
  }

  const handleOnSubmit = values => {
    const {
      name,
      nameAr,
      description,
      descriptionAr,
      address,
      // latitude,
      // longitude,
      unitType,
      area,
      areaUnit,
      doorPassword,
      image,
      unitPrice,
      realEstateTax,
      // pursuit,
      // questTax,
      statusType,
      // isListedForRent,
      roomDetails,
      project,
    } = values

    let formData = new FormData()
    formData.append("name", name)
    formData.append("nameAr", nameAr)
    formData.append("description", description)
    formData.append("descriptionAr", descriptionAr)
    formData.append("address", address)
    // formData.append("latitude", latitude)
    // formData.append("longitude", longitude)
    formData.append("generalDetails[unitType]", unitType)
    formData.append("generalDetails[area]", area)
    formData.append("generalDetails[areaUnit]", areaUnit)
    formData.append("generalDetails[doorPassword]", doorPassword)
    formData.append("priceDetails[unitPrice]", unitPrice)
    formData.append("priceDetails[realEstateTax]", realEstateTax)
    // formData.append("priceDetails[persuit]", pursuit)
    // formData.append("priceDetails[questTax]", questTax)
    roomDetails?.forEach((item, index) => {
      formData.append(`roomDetails[${index}][roomType]`, item.roomType)
      formData.append(`roomDetails[${index}][count]`, item.count)
    })

    formData.append("statusType", statusType)
    formData.append("projectId", mode === "allUnits" ? project : projectId)
    // formData.append("isListedForRent", isListedForRent)
    formData.append("image", image)

    dispatch(createUnit(formData))
      .then(action => {
        const { statusCode, success, message, data } = action?.payload
        if (statusCode === 200 && success) {
          const { _id, projectId } = data
          dispatch(showAlert({ text: message, type: "success" }))
          // FOR PROJECT UNIT
          if (mode === "projectUnits") {
            navigate(`/projects/${projectId}/units/update?id=${_id}`)
          } else if (mode === "allUnits") {
            navigate(`/projects/${projectId}/units/update?id=${_id}`)
          }
          console.log(data)
        } else {
          dispatch(showAlert({ text: message, type: "error" }))
        }
      })
      .catch(error => {})
  }

  return (
    <React.Fragment>
      <div className="page-content create_project_page">
        <Container fluid>
          <TopTitle
            title={t("Create Unit")}
            subTitle={t("Feel the details for create a unit")}
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Formik
                    initialValues={formValues}
                    validationSchema={createUnitSchema(mode)}
                    onSubmit={handleOnSubmit}
                  >
                    {({ values, errors, touched, setFieldValue }) => (
                      <Form>
                        <Row>
                          <Col lg={6}>
                            <div className="form_field">
                              <CustomLabel
                                htmlFor="name"
                                label={t("Name(English)")}
                                isRequired={true}
                              />
                              <CustomField
                                name="name"
                                type="text"
                                placeholder={t("Name(English)")}
                                touched={touched}
                                errors={errors}
                              />
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="form_field">
                              <CustomLabel
                                htmlFor="nameAr"
                                label={t("Name(Arabic)")}
                                isRequired={true}
                              />
                              <CustomField
                                name="nameAr"
                                type="text"
                                placeholder={t("Name(Arabic)")}
                                touched={touched}
                                errors={errors}
                                isArabic={true}
                              />
                            </div>
                          </Col>
                          {mode === "allUnits" && (
                            <Col lg={6}>
                              <div className="form_field">
                                <CustomLabel
                                  htmlFor="project"
                                  label={t("Project")}
                                  isRequired={true}
                                />
                                <div className="select_wrapper">
                                  <Field
                                    as="select"
                                    name="project"
                                    className={`form-control b_r_12 ${
                                      errors.project && touched.project
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    onChange={e => {
                                      const selectedValue = e.target.value
                                      setFieldValue("project", selectedValue)
                                    }}
                                  >
                                    <option hidden>{t("Project")}</option>
                                    {projectsList?.map(item => (
                                      <option key={item._id} value={item._id}>
                                        {language === "sa"
                                          ? item.nameAr
                                          : item.name}
                                      </option>
                                    ))}
                                  </Field>
                                </div>
                                <CustomErrorMessage name={"project"} />
                              </div>
                            </Col>
                          )}
                          <Col lg={6}>
                            <div className="form_field">
                              <CustomLabel
                                htmlFor="unitType"
                                label={t("Unit Type")}
                                isRequired={true}
                              />
                              <div className="select_wrapper">
                                <Field
                                  as="select"
                                  name="unitType"
                                  className={`form-control b_r_12 ${
                                    errors.unitType && touched.unitType
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  onChange={e => {
                                    const selectedValue = e.target.value
                                    setFieldValue("unitType", selectedValue)
                                  }}
                                >
                                  <option hidden>{t("Unit Type")}</option>
                                  {unitTypeOptions?.map(item => (
                                    <option key={item.value} value={item.value}>
                                      {t(item.label)}
                                    </option>
                                  ))}
                                </Field>
                              </div>
                              <CustomErrorMessage name={"unitType"} />
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="form_field">
                              <CustomLabel
                                htmlFor="statusType"
                                label={t("Unit Status")}
                                isRequired={true}
                              />
                              <div className="select_wrapper">
                                <Field
                                  as="select"
                                  name="statusType"
                                  className={`form-control b_r_12 ${
                                    errors.statusType && touched.statusType
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  onChange={e => {
                                    const selectedValue = e.target.value
                                    setFieldValue("statusType", selectedValue)
                                  }}
                                >
                                  <option hidden>{t("Unit Status")}</option>
                                  {unitStatusOptions?.map(item => (
                                    <option key={item.value} value={item.value}>
                                      {t(item.label)}
                                    </option>
                                  ))}
                                </Field>
                              </div>
                              <CustomErrorMessage name={"statusType"} />
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="form_field">
                              <CustomLabel
                                htmlFor="area"
                                label={t("Area")}
                                isRequired={true}
                              />
                              <CustomField
                                name="area"
                                type="text"
                                placeholder={t("Area")}
                                touched={touched}
                                errors={errors}
                              />
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="form_field">
                              <CustomLabel
                                htmlFor="areaUnit"
                                label={t("Area Unit")}
                                isRequired={true}
                              />
                              <div className="select_wrapper">
                                <Field
                                  as="select"
                                  name="areaUnit"
                                  className={`form-control b_r_12 ${
                                    errors.areaUnit && touched.areaUnit
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  onChange={e => {
                                    const selectedValue = e.target.value
                                    setFieldValue("areaUnit", selectedValue)
                                  }}
                                >
                                  <option hidden>{t("Area Unit")}</option>
                                  {unitAreaOptions?.map(item => (
                                    <option key={item.value} value={item.value}>
                                      {t(item.label)}
                                    </option>
                                  ))}
                                </Field>
                              </div>
                              <CustomErrorMessage name={"areaUnit"} />
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="form_field">
                              <CustomLabel
                                htmlFor="doorPassword"
                                label={t("Door Password")}
                                isRequired={true}
                              />
                              <CustomField
                                name="doorPassword"
                                type="text"
                                placeholder={t("Door Password")}
                                touched={touched}
                                errors={errors}
                              />
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="form_field">
                              <CustomLabel
                                htmlFor="unitPrice"
                                label={t("Unit Price")}
                                isRequired={true}
                              />
                              <CustomField
                                name="unitPrice"
                                type="text"
                                placeholder={t("Unit Price")}
                                touched={touched}
                                errors={errors}
                              />
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="form_field">
                              <CustomLabel
                                htmlFor="realEstateTax"
                                label={t("Real Estate Tax")}
                                isRequired={true}
                              />
                              <CustomField
                                name="realEstateTax"
                                type="text"
                                placeholder={t("Real Estate Tax")}
                                touched={touched}
                                errors={errors}
                              />
                            </div>
                          </Col>
                          {/* <Col lg={6}>
                            <div className="form_field">
                              <CustomLabel
                                htmlFor="pursuit"
                                label={t("Pursuit")}
                                isRequired={true}
                              />
                              <CustomField
                                name="pursuit"
                                type="text"
                                placeholder={t("Pursuit")}
                                touched={touched}
                                errors={errors}
                              />
                            </div>
                          </Col> */}
                          {/* <Col lg={6}>
                            <div className="form_field">
                              <CustomLabel
                                htmlFor="questTax"
                                label={t("Quest Tax")}
                                isRequired={true}
                              />
                              <CustomField
                                name="questTax"
                                type="text"
                                placeholder={t("Quest Tax")}
                                touched={touched}
                                errors={errors}
                              />
                            </div>
                          </Col> */}
                          <Col lg={6}>
                            <div className="form_field">
                              <CustomLabel
                                htmlFor="address"
                                label={t("Address")}
                                isRequired={true}
                              />
                              <CustomField
                                name="address"
                                type="text"
                                placeholder={t("Address")}
                                touched={touched}
                                errors={errors}
                              />
                            </div>
                          </Col>
                          {/* <Col lg={6}>
                            <div className="form_field">
                              <CustomLabel
                                htmlFor="latitude"
                                label={t("Latitude")}
                                isRequired={true}
                              />
                              <CustomField
                                name="latitude"
                                type="text"
                                placeholder={t("Latitude")}
                                touched={touched}
                                errors={errors}
                              />
                            </div>
                          </Col> */}
                          {/* <Col lg={6}>
                            <div className="form_field">
                              <CustomLabel
                                htmlFor="longitude"
                                label={t("Longitude")}
                                isRequired={true}
                              />
                              <CustomField
                                name="longitude"
                                type="text"
                                placeholder={t("Longitude")}
                                touched={touched}
                                errors={errors}
                              />
                            </div>
                          </Col> */}
                          {/* <Col lg={6}>
                            <div className="mt-3 g_label">
                              <CustomLabel
                                htmlFor="isListedForRent"
                                label={t("Is For Rent")}
                              />

                              <div className="d-flex ">
                                <div className="form-check mx-2">
                                  <Field
                                    className="form-check-input"
                                    type="radio"
                                    name="isListedForRent"
                                    id="rent"
                                    value="rent"
                                    checked={values.isListedForRent === true}
                                    onClick={() => {
                                      setFieldValue("isListedForRent", true)
                                    }}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="rent"
                                  >
                                    {t("For Rent")}
                                  </label>
                                </div>
                                <div className="form-check mx-2">
                                  <Field
                                    className="form-check-input"
                                    type="radio"
                                    name="isListedForRent"
                                    id="sell"
                                    value="sell"
                                    checked={values.isListedForRent === false}
                                    onClick={() => {
                                      setFieldValue("isListedForRent", false)
                                    }}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="sell"
                                  >
                                    {t("For Sale")}
                                  </label>
                                </div>
                              </div>
                            </div>
                          </Col> */}
                          {mode === "allUnits" && <Col lg={6}></Col>}
                          <Col lg={6}>
                            <div className="form_field">
                              <CustomLabel
                                htmlFor="description"
                                label={t("Description(English)")}
                                isRequired={true}
                              />
                              <Field
                                as="textarea"
                                id="description"
                                name="description"
                                rows={5}
                                placeholder={t("Description(English)")}
                                className={`form-control b_r_12 ${
                                  errors.description && touched.description
                                    ? "is-invalid"
                                    : ""
                                }`}
                              />
                              <CustomErrorMessage name={"description"} />
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="form_field">
                              <CustomLabel
                                htmlFor="descriptionAr"
                                label={t("Description(Arabic)")}
                                isRequired={true}
                              />
                              <Field
                                as="textarea"
                                id="descriptionAr"
                                name="descriptionAr"
                                rows={5}
                                placeholder={t("Description(Arabic)")}
                                className={`form-control b_r_12 ${
                                  errors.descriptionAr && touched.descriptionAr
                                    ? "is-invalid"
                                    : ""
                                }`}
                              />
                              <CustomErrorMessage name={"descriptionAr"} />
                            </div>
                          </Col>
                          <FieldArray name="roomDetails">
                            {({ remove, push }) => (
                              <div>
                                <div className="g_between mt-2">
                                  <h2 className="fs_18_700 ">
                                    {t("Add Amenities Details")}
                                  </h2>
                                  <button
                                    className="cancel_btn"
                                    type="button"
                                    onClick={() =>
                                      push({
                                        roomType: "",
                                        count: "",
                                      })
                                    }
                                  >
                                    {t("Add More")}
                                  </button>
                                </div>
                                {values?.roomDetails?.map((field, index) => (
                                  <div key={index} className="primary_bg_10">
                                    <Row>
                                      <Col lg={6}>
                                        <div className="form_field">
                                          <CustomLabel
                                            htmlFor={`roomDetails.${index}.roomType`}
                                            label={t("Amenities")}
                                            isRequired={true}
                                          />
                                          <div className="select_wrapper">
                                            <Field
                                              as="select"
                                              name={`roomDetails.${index}.roomType`}
                                              id={`roomDetails.${index}.roomType`}
                                              className={`form-control  ${
                                                errors.roomDetails?.[index]
                                                  ?.roomType &&
                                                touched.roomDetails?.[index]
                                                  ?.roomType
                                                  ? "is-invalid"
                                                  : ""
                                              } `}
                                              onChange={e => {
                                                const selectedValue =
                                                  e.target.value
                                                setFieldValue(
                                                  `roomDetails.${index}.roomType`,
                                                  selectedValue
                                                )
                                              }}
                                            >
                                              <option hidden>
                                                {t("Amenities")}
                                              </option>
                                              {roomTypesList?.map(item => (
                                                <option
                                                  key={item._id}
                                                  value={item._id}
                                                >
                                                  {language === "sa"
                                                    ? item.nameAr
                                                    : item.name}
                                                </option>
                                              ))}
                                            </Field>
                                          </div>
                                          <CustomErrorMessage
                                            name={`roomDetails.${index}.roomType`}
                                          />
                                        </div>
                                      </Col>
                                      <Col lg={6}>
                                        <div className="form_field">
                                          <CustomLabel
                                            htmlFor={`roomDetails.${index}.count`}
                                            label={t("Number Of Count")}
                                            isRequired={true}
                                          />
                                          <div className="d-flex align-items-center">
                                            <Field
                                              name={`roomDetails.${index}.count`}
                                              type={"number"}
                                              id={`roomDetails.${index}.count`}
                                              placeholder={t("Count")}
                                              className={`form-control  ${
                                                errors.roomDetails?.[index]
                                                  ?.count &&
                                                touched.roomDetails?.[index]
                                                  ?.count
                                                  ? "is-invalid"
                                                  : ""
                                              } `}
                                            />
                                            {values.roomDetails.length > 1 && (
                                              <img
                                                src={TrashIcon}
                                                alt="Trash Icon"
                                                width={24}
                                                height={24}
                                                className="cursor-pointer mx-3"
                                                onClick={() => remove(index)}
                                              />
                                            )}
                                          </div>
                                          <CustomErrorMessage
                                            name={`roomDetails.${index}.count`}
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                  </div>
                                ))}
                              </div>
                            )}
                          </FieldArray>
                          <Col lg={6}>
                            <div className="form_field">
                              <CustomLabel
                                htmlFor="image"
                                label={t("Upload Feature Image")}
                                isRequired={true}
                              />
                              <FileUpload
                                name="image"
                                value={values.image}
                                touched={touched.image}
                                error={errors.image}
                                labelText={t("Upload Feature Image")}
                                setFieldValue={setFieldValue}
                                handleFileUpload={event =>
                                  handleSquareFileUpload(
                                    event,
                                    setFieldValue,
                                    "image"
                                  )
                                }
                              />
                              <CustomErrorMessage name={"image"} />
                              <p className="fs_14_500 opacity-75">
                                {t(
                                  "Recommended Image Size: Square (e.g., 540x540 px)"
                                )}
                              </p>
                            </div>
                          </Col>
                          <Col lg={12}>
                            <FormButtons
                              buttonText={t("Create")}
                              isLoading={isLoading}
                            />
                          </Col>
                        </Row>
                      </Form>
                    )}
                  </Formik>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default CreateUnit
