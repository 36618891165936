import React from "react"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import { useTranslation } from "react-i18next"
import Title from "components/Shared/Title"
import DetailsTitle from "components/Shared/DetailsTitle"
import { Field, Form, Formik } from "formik"
import { changePasswordValues } from "constants/initialValues"
import CustomField from "components/Shared/CustomField"
import CustomLabel from "components/Shared/CustomLabel"
import FormButtons from "components/Shared/FormButtons"
import { changePasswordSchema } from "components/Common/validation"
import { useDispatch, useSelector } from "react-redux"
import { changeUserPassword } from "store/actions/authAction"
import { showAlert } from "store/reducers/alertReducer"

const ChangePassword = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  document.title = t("Change Password | Takween")

  const dispatch = useDispatch()

  const { isLoading } = useSelector(state => state.Login)

  const handleOnSubmit = values => {
    dispatch(changeUserPassword(values))
      .then(action => {
        const { statusCode, success, message } = action?.payload
        if (statusCode === 200 && success) {
          dispatch(showAlert({ text: message, type: "success" }))
        } else {
          dispatch(showAlert({ text: message, type: "error" }))
        }
      })
      .catch(error => {})
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <h1 className="main_title">{t("Change Password")}</h1>
          <Row>
            <Col lg={5}>
              <Card>
                <CardBody>
                  <DetailsTitle
                    title={t("Change Password")}
                    isBackButton={false}
                  />
                  <Formik
                    initialValues={changePasswordValues}
                    validationSchema={changePasswordSchema}
                    onSubmit={handleOnSubmit}
                  >
                    {({ values, errors, touched, setFieldValue }) => (
                      <Form>
                        <Row>
                          <Col lg={12}>
                            <div className="form_field">
                              <CustomLabel
                                htmlFor="password"
                                label={t("Current Password")}
                                isRequired={true}
                              />
                              <CustomField
                                name="password"
                                type="password"
                                placeholder={t("Current Password")}
                                touched={touched}
                                errors={errors}
                              />
                            </div>
                          </Col>
                          <Col lg={12}>
                            <div className="form_field">
                              <CustomLabel
                                htmlFor="newPassword"
                                label={t("New Password")}
                                isRequired={true}
                              />
                              <CustomField
                                name="newPassword"
                                type="password"
                                placeholder={t("New Password")}
                                touched={touched}
                                errors={errors}
                              />
                            </div>
                          </Col>
                          <Col lg={12}>
                            <div className="form_field">
                              <CustomLabel
                                htmlFor="confirmPassword"
                                label={t("Confirm Password")}
                                isRequired={true}
                              />
                              <CustomField
                                name="confirmPassword"
                                type="password"
                                placeholder={t("Confirm Password")}
                                touched={touched}
                                errors={errors}
                              />
                            </div>
                          </Col>

                          <Col lg={12}>
                            <FormButtons
                              buttonText={t("Change")}
                              isLoading={isLoading}
                            />
                          </Col>
                        </Row>
                      </Form>
                    )}
                  </Formik>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ChangePassword
