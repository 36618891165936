import { useRef, useState } from "react"

const useFilters = (
  initialFilters,
  initialKeyword = "",
  initialDateRange = {}
) => {
  const [filters, setFilters] = useState(initialFilters)
  const [keyword, setKeyword] = useState(initialKeyword)
  const [dateRange, setDateRange] = useState(initialDateRange)
  const [selectedId, setSelectedId] = useState(null)
  const [itemIdToDelete, setItemIdToDelete] = useState(null)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [isApiCalled, setIsApiCalled] = useState(false)

  const flatpickrRef = useRef(null)

  const handleFilterChange = (selectedValue, filterName) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [filterName]: selectedValue,
    }))
  }

  const resetFilters = () => {
    setFilters(initialFilters)
    setKeyword(initialKeyword)
    setDateRange(initialDateRange)
    if (flatpickrRef.current) {
      flatpickrRef.current.flatpickr.clear()
    }
  }

  return [
    filters,
    keyword,
    setKeyword,
    dateRange,
    setDateRange,
    flatpickrRef,
    selectedId,
    setSelectedId,
    itemIdToDelete,
    setItemIdToDelete,
    showDeleteModal,
    setShowDeleteModal,
    isApiCalled,
    setIsApiCalled,
    handleFilterChange,
    resetFilters,
  ]
}

export default useFilters
