// ATTRIBUTE (PROJECT => FACILITY AND SERVICES)

import { createAsyncThunk } from "@reduxjs/toolkit"
import {
  del,
  formDataPost,
  formDataPut,
  get,
  patch,
  post,
  put,
} from "helpers/api_helper"
import {
  CREATE_ATTRIBUTE,
  DELETE_ATTRIBUTE,
  GET_ALL_ATTRIBUTE,
  GET_ATTRIBUTE_DETAILS,
  GET_ATTRIBUTE_LISTS,
  UPDATE_ATTRIBUTE,
  UPDATE_ATTRIBUTE_STATUS,
} from "helpers/url_helper"

export const getAllAttributes = createAsyncThunk(
  GET_ALL_ATTRIBUTE,
  async payload => {
    const response = await post(GET_ALL_ATTRIBUTE, payload)
    return response
  }
)

export const getAttributesList = createAsyncThunk(
  GET_ATTRIBUTE_LISTS,
  async payload => {
    const response = await post(GET_ATTRIBUTE_LISTS, payload)
    return response
  }
)

export const getAttributeDetails = createAsyncThunk(
  GET_ATTRIBUTE_DETAILS,
  async payload => {
    const response = await post(GET_ATTRIBUTE_DETAILS, payload)
    return response
  }
)

export const updateAttributeStatus = createAsyncThunk(
  UPDATE_ATTRIBUTE_STATUS,
  async payload => {
    const response = await patch(UPDATE_ATTRIBUTE_STATUS, payload)
    return response
  }
)

export const deleteAttribute = createAsyncThunk(
  DELETE_ATTRIBUTE,
  async payload => {
    const response = await del(DELETE_ATTRIBUTE, payload)
    return response
  }
)

export const createAttribute = createAsyncThunk(
  CREATE_ATTRIBUTE,
  async payload => {
    const response = await formDataPost(CREATE_ATTRIBUTE, payload)
    return response
  }
)

export const updateAttribute = createAsyncThunk(
  UPDATE_ATTRIBUTE,
  async payload => {
    const response = await formDataPut(UPDATE_ATTRIBUTE, payload)
    return response
  }
)
