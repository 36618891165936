import React from "react"

const Switch = ({ isChecked, onClickHandler, disabled = false }) => {
  return (
    <div className="form-check form-switch form-switch-md" style={{ width: 0 }}>
      <input
        type="checkbox"
        checked={isChecked}
        onClick={onClickHandler}
        disabled={disabled}
        className="form-check-input"
        id="customSwitchsizemd"
      />
    </div>
  )
}

export default Switch
