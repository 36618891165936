import i18next from "i18next"

const baseFilterOptions = [
  {
    name: "status",
    type: "select",
    get options() {
      return [
        { label: i18next.t("All"), value: 2 },
        { label: i18next.t("Buying"), value: 1 },
        { label: i18next.t("Rental"), value: 0 },
      ]
    },
    get placeholder() {
      return i18next.t("All")
    },
  },
  {
    name: "orderBy",
    type: "select",
    get options() {
      return [
        { label: i18next.t("Ascending"), value: 1 },
        { label: i18next.t("Descending"), value: 2 },
      ]
    },
    get placeholder() {
      return i18next.t("Ascending")
    },
  },
]
const ascDescOptions = [
  {
    name: "orderBy",
    type: "select",
    get options() {
      return [
        { label: i18next.t("Ascending"), value: 1 },
        { label: i18next.t("Descending"), value: 2 },
      ]
    },
    get placeholder() {
      return i18next.t("Ascending")
    },
  },
]
const commonFilterOptions = [
  {
    name: "status",
    type: "select",
    get options() {
      return [
        { label: i18next.t("All"), value: -1 },
        { label: i18next.t("Active"), value: 1 },
        { label: i18next.t("Deactive"), value: 0 },
      ]
    },
    get placeholder() {
      return i18next.t("All")
    },
  },
  {
    name: "orderBy",
    type: "select",
    get options() {
      return [
        { label: i18next.t("Ascending"), value: 1 },
        { label: i18next.t("Descending"), value: 2 },
      ]
    },
    get placeholder() {
      return i18next.t("Ascending")
    },
  },
]

// const userTypeOptions = [
//   {
//     label: "Finance",
//     value: "finance",
//   },
//   {
//     label: "Sales",
//     value: "sales",
//   },
//   {
//     label: "Marketing",
//     value: "marketing",
//   },
//   {
//     label: "Customer Support",
//     value: "customerService",
//   },
//   {
//     label: "Super Admin",
//     value: "superadmin",
//   },
// ]

// PROJECTS

const projectPurposeOptions = [
  { label: "Residential", value: "Residential" },
  { label: "Commercial", value: "Commercial" },
  { label: "Industrial", value: "Industrial" },
  { label: "Recreational", value: "Recreational" },
  { label: "Agricultural", value: "Agricultural" },
  { label: "Mixed-Use", value: "Mixed-Use" },
  { label: "Educational", value: "Educational" },
  { label: "Healthcare", value: "Healthcare" },
]

const projectInterfaceOptions = [
  { label: "North", value: "North" },
  { label: "Northeast", value: "Northeast" },
  { label: "East", value: "East" },
  { label: "Southeast", value: "Southeast" },
  { label: "South", value: "South" },
  { label: "Southwest", value: "Southwest" },
  { label: "West", value: "West" },
  { label: "Northwest", value: "Northwest" },
]
const projectStatusOptions = [
  { label: "Coming Soon", value: 1 },
  { label: "Available For Sale", value: 2 },
  { label: "Sold", value: 3 },
]

const distanceOptions = [
  {
    label: "Minute",
    value: "Min",
  },
  {
    label: "Hour",
    value: "Hour",
  },
  {
    label: "Meter",
    value: "Meter",
  },
  {
    label: "KM",
    value: "KM",
  },
]

// UNITS

const unitTypeOptions = [
  { label: "Villa", value: "Villa" },
  { label: "Apartment", value: "Apartment" },
  { label: "Townhouse", value: "Townhouse" },
  { label: "Penthouse", value: "Penthouse" },
  { label: "Bungalow", value: "Bungalow" },
  { label: "Duplex", value: "Duplex" },
  { label: "Studio", value: "Studio" },
  { label: "Loft", value: "Loft" },
]

const unitStatusOptions = [
  { label: "Coming Soon", value: 1 },
  { label: "Available for Sale", value: 2 },
  { label: "Sold", value: 3 },
  { label: "Reserved", value: 4 },
]

const unitAreaOptions = [
  { label: "Sq/ft", value: "Sq/ft" },
  { label: "Meter", value: "Meter" },
]

const yearOptions = [
  {
    label: "Month",
    value: "month",
  },
  {
    label: "Year",
    value: "year",
  },
]

// ATTRIBUTE
const attributeTypeOptions = [
  // {
  //   label: "Facilities",
  //   value: "facilities",
  // },
  // {
  //   label: "Services",
  //   value: "services",
  // },
  // {
  //   label: "After Sale Service",
  //   value: "after-sale-service",
  // },
  {
    label: "Facilities And Services",
    value: "facilities-and-services",
  },
]

const customerSupportStatusOptions = [
  { value: 1, label: "Pending", className: "pending_status", textColor: "" },
  { value: 2, label: "Open", className: "open_status", textColor: "" },
  {
    value: 3,
    label: "In progress",
    className: "inprogress_status",
    textColor: "",
  },
  { value: 4, label: "Resolved", className: "rended_status", textColor: "" },
  { value: 5, label: "Closed", className: "closed_status", textColor: "" },
  { value: 6, label: "Rejected", className: "reject_status", textColor: "" },
]

const customerSupportSubjectOptions = [
  { value: "Finance", label: "Finance" },
  { value: "Sales", label: "Sales" },
  { value: "CustomerSupport", label: "Customer Support" },
  { value: "Marketing", label: "Marketing" },
  { value: "Other", label: "Other" },
]
const customerSupportPriorityOptions = [
  { value: "high", label: "High" },
  { value: "low", label: "Low" },
  { value: "medium", label: "Medium" },
  { value: "urgent", label: "Urgent" },
]

const customerSupportChannelOptions = [
  { value: "App", label: "App" },
  { value: "WhatsApp", label: "WhatsApp" },
]

const notificationActionTypeOptions = [
  { value: 1, label: "Booking Confirmation" },
  { value: 2, label: "Down Payment Request Received" },
  { value: 3, label: "Down Payment Approved" },
  { value: 4, label: "Down Payment Rejected" },
  { value: 5, label: "Payment Hold Request" },
  { value: 6, label: "Full Payment Request" },
  { value: 7, label: "Full Payment Request Received" },
  { value: 8, label: "Full Payment Approved" },
  { value: 9, label: "Full Payment Rejected" },
  { value: 10, label: "Payment Contract Uploaded" },
  { value: 11, label: "Delivery Letter Uploaded" },
  { value: 12, label: "Ownership Document Uploaded" },
  { value: 13, label: "Ownership Document Request Approved" },
  { value: 14, label: "Ownership Document Request Rejected" },
  { value: 15, label: "Tax Payment Request Received" },
  { value: 16, label: "Tax Payment Approved" },
  { value: 17, label: "Tax Payment Rejected" },
  { value: 18, label: "Tax Document Approved" },
  { value: 19, label: "Tax Document Rejected" },
  { value: 20, label: "Deed Document Requested" },
  { value: 21, label: "Deed Document Uploaded" },
  { value: 22, label: "Cancelled" },
]
const notificationTypeOptions = [
  { value: "BUYING_JOURNEY", label: "Buying Journey" },
]

const paymentMethods = [
  { value: "sadad", label: "Sadad" },
  { value: "applepay", label: "Apple pay" },
  { value: "cheque", label: "Cheque" },
  { value: "bank", label: "Bank" },
]

const paymentTypes = [
  { value: 1, label: "Down Payment" },
  { value: 2, label: "Full Payment" },
  { value: 3, label: "Tax" },
  { value: 4, label: "Installment For Rent" },
]

const transactionsTypes = [
  { value: 1, label: "Buying" },
  { value: 2, label: "Renting" },
]

const paymentStatusOptions = [
  { value: 0, label: "Pending" },
  { value: 1, label: "Received" },
  { value: 2, label: "Failed" },
]

const projectStatusType = [
  { value: 1, label: "Coming Soon" },
  { value: 2, label: "Available For Sale" },
  { value: 3, label: "Sold" },
]

const projectTypes = [
  { value: 1, label: "Buy" },
  { value: 2, label: "Rent" },
]

// SALES REPORTS
const salesReportOptions = [
  { value: "buying", label: "Buying" },
  { value: "renting", label: "Renting" },
]

const salesByOptions = [
  { value: "project", label: "Project" },
  { value: "unit", label: "Unit" },
  { value: "user", label: "User" },
]

const paymentReportGroupByOptions = [
  { value: "project", label: "Project" },
  { value: "unit", label: "Unit" },
  { value: "user", label: "User" },
]
const paymentReportTransTypeOptions = [
  { value: 1, label: "Buying" },
  { value: 2, label: "Renting" },
]

const paymentReportPaymentTypeOptions = [
  { value: 1, label: "Down Payment" },
  { value: 2, label: "Full Payment" },
  { value: 3, label: "Tax Payment" },
  { value: 4, label: "Rent Payment" },
]

const paymentStatus = [
  { value: -1, label: "All" },
  { value: 1, label: "Active" },
  { value: 2, label: "Cancelled" },
  { value: 3, label: "Completed" },
]

export {
  baseFilterOptions,
  commonFilterOptions,
  ascDescOptions,
  projectPurposeOptions,
  projectInterfaceOptions,
  projectStatusOptions,
  distanceOptions,
  unitTypeOptions,
  unitStatusOptions,
  unitAreaOptions,
  yearOptions,
  attributeTypeOptions,
  customerSupportStatusOptions,
  customerSupportSubjectOptions,
  customerSupportPriorityOptions,
  customerSupportChannelOptions,
  notificationActionTypeOptions,
  notificationTypeOptions,
  paymentMethods,
  paymentTypes,
  transactionsTypes,
  paymentStatusOptions,
  projectStatusType,
  projectTypes,
  salesReportOptions,
  salesByOptions,
  paymentReportGroupByOptions,
  paymentReportTransTypeOptions,
  paymentReportPaymentTypeOptions,
  paymentStatus,
}
