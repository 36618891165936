import React, { useEffect, useState } from "react"
import { Card, CardBody, Col, Nav, NavItem, NavLink, Row } from "reactstrap"
import { useTranslation } from "react-i18next"
import StackedColumnChart from "pages/Dashboard/StackedColumnChart"
import { useDispatch, useSelector } from "react-redux"
import { getDashboardData } from "store/actions/commonAction"
import { getDashboardDateRange } from "components/Common/Utils"
import { ShimmerThumbnail } from "react-shimmer-effects"
import ResetWhite from "assets/images/icons/resetWhite.svg"

const AdminDashboard = ({ dateRange, setDateRange }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  const dispatch = useDispatch()

  const { loading, dashboardData } = useSelector(state => state.Common)

  const [periodData, setPeriodData] = useState([])
  const [chartLabels, setChartLabels] = useState([])
  const [duration, setDuration] = useState("year")

  useEffect(() => {
    fetchDashboardData()
  }, [])

  useEffect(() => {
    if (dateRange[0] && dateRange[1]) {
      fetchDashboardData()
    }
  }, [dateRange])

  const fetchDashboardData = ({ isReset = false } = {}) => {
    const payload = {
      type: "admin",
      filter: {
        dateRange: isReset ? {} : getDashboardDateRange(dateRange),
      },
    }
    dispatch(getDashboardData(payload))
  }

  useEffect(() => {
    if (dashboardData) {
      updateChartData()
    }
  }, [duration, dashboardData])

  const updateChartData = () => {
    let selectedData = []
    let labels = []

    if (duration === "month" && dashboardData?.paymentByMonth?.length) {
      // Create a single series object with data for all months
      selectedData = [
        {
          name: t("Total Amount"),
          data: dashboardData.paymentByMonth.map(data => data.totalAmount),
        },
      ]

      // Create labels for all months
      labels = dashboardData.paymentByMonth.map(
        data => `${getMonthLabels([data])[0]}`
      )
    } else if (duration === "week" && dashboardData?.paymentByWeek?.length) {
      const weeksCount = dashboardData.paymentByWeek.length
      selectedData = [
        {
          name: t("Total Amount"),
          data: dashboardData.paymentByWeek.map(data => data.totalAmount),
        },
      ]
      labels = dashboardData.paymentByWeek.map(
        data => `${t("Week")} ${data.week}`
      )
    } else if (duration === "year" && dashboardData?.paymentByYear?.length) {
      selectedData = [
        {
          name: t("Total Amount"),
          data: dashboardData.paymentByYear.map(data => data.totalAmount),
        },
      ]
      labels = dashboardData.paymentByYear.map(data => `${data.year}`)
    }

    setPeriodData(selectedData)
    setChartLabels(labels)
  }

  const getMonthLabels = (data = []) => {
    return data.map(item => {
      // Check if item has a 'date' field; otherwise, use 'month' and 'year' to construct it
      const date = item.date
        ? new Date(item.date) // Parse date if it's already in the item
        : new Date(item.year, item.month - 1) // Construct date from year and month (month is 0-indexed)

      // Format to "Jan 2024", etc.
      return `${date.toLocaleString("default", {
        month: "short",
      })} ${date.getFullYear()}`
    })
  }

  const changeDuration = duration => {
    setDuration(duration)
  }

  const handleReset = () => {
    setDateRange([])
    setDuration("month")
    fetchDashboardData({ isReset: true })
  }

  return (
    <Row className="">
      <Col lg={12}>
        <Card>
          <CardBody>
            {loading ? (
              <ShimmerThumbnail height={450} rounded className={"mb-0"} />
            ) : (
              <>
                <div>
                  <Row>
                    <Col>
                      <h2 className="fs_20_600">{t("Total Payment")}</h2>
                      <p className="fs_20_600 opacity-50">
                        {dashboardData?.totalPayment ?? "-"} {t("SAR")}
                      </p>
                    </Col>
                    <Col>
                      <h2 className="fs_20_600">{t("Total Down Payment")}</h2>
                      <p className="fs_20_600 opacity-50">
                        {dashboardData?.totalDownPayment ?? "-"} {t("SAR")}
                      </p>
                    </Col>
                    <Col>
                      <h2 className="fs_20_600">{t("Total Full Payment")}</h2>
                      <p className="fs_20_600 opacity-50">
                        {dashboardData?.totalFullPayment ?? "-"} {t("SAR")}
                      </p>
                    </Col>
                    <Col style={{ minWidth: 335 }}>
                      <div className="d-flex admin_duration_section">
                        <div className="ms-auto">
                          <Nav pills>
                            <NavItem>
                              <NavLink
                                className={duration === "week" ? "active" : ""}
                                href="#"
                                onClick={() => changeDuration("week")}
                              >
                                {t("Week")}
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                className={duration === "month" ? "active" : ""}
                                href="#"
                                onClick={() => changeDuration("month")}
                              >
                                {t("Month")}
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                className={duration === "year" ? "active" : ""}
                                href="#"
                                onClick={() => changeDuration("year")}
                              >
                                {t("Year")}
                              </NavLink>
                            </NavItem>
                            <button
                              className="reset_btn dashboard_reset_btn"
                              style={{
                                marginTop: 5,
                                height: 35,
                                marginLeft: 10,
                              }}
                              onClick={handleReset}
                            >
                              <img
                                src={ResetWhite}
                                alt=""
                                style={{ paddingBottom: 10 }}
                              />
                            </button>
                          </Nav>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div style={{ direction: "ltr" }}>
                  <StackedColumnChart
                    periodData={periodData}
                    dataColors='["#0C3553","#D5B086","#3CB3C1"]'
                    height={392}
                    chartLabels={chartLabels}
                  />
                </div>
              </>
            )}
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default AdminDashboard
