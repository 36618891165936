import React, { useRef } from "react"
import calenderIcon from "assets/images/icons/calendar.svg"
import ArrowDown from "assets/images/icons/arrow-down.svg"
import Flatpickr from "react-flatpickr"
import "flatpickr/dist/themes/material_blue.css"
import { useTranslation } from "react-i18next"
import { convertDateFormat } from "components/Common/Utils"

const DashboardDateFilter = ({ dateRange, setDateRange }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const flatpickrRef = useRef(null)
  const filterContainerRef = useRef(null)

  const handleFilterClick = () => {
    if (flatpickrRef.current) {
      flatpickrRef.current.flatpickr.open()
    }
  }

  const onCalendarOpen = () => {
    if (flatpickrRef.current && flatpickrRef.current.calendarContainer) {
      flatpickrRef.current.calendarContainer.classList.add("custom-flatpickr")
    }
  }

  return (
    <div>
      <div
        className="dashboard_common_date d-flex mb-4"
        ref={filterContainerRef}
      >
        <div className="calender_icon g_center">
          <img
            src={calenderIcon}
            alt="calender icon"
            className=""
            width={24}
            height={24}
          />
        </div>
        <div
          className="filter_period g_between cursor-pointer"
          onClick={handleFilterClick}
        >
          <div>
            <h2 className="fs_16 fw_600">{t("Filter Period")}</h2>
            <p className="fs_12 fw_400 mb-0">
              {dateRange.length === 0
                ? t("Select date range")
                : `${dateRange[0]} - ${dateRange[1] || t("Select max date")}`}
            </p>
          </div>
          <img src={ArrowDown} alt="" width={24} height={24} className="ms-3" />
        </div>
      </div>
      <div className="position-relative">
        {/* Flatpickr without showing the input field */}
        <Flatpickr
          ref={flatpickrRef}
          options={{
            mode: "range",
            dateFormat: "Y-m-d",
            positionElement: filterContainerRef.current,
          }}
          onReady={(selectedDates, dateStr, instance) => {
            flatpickrRef.current = instance

            instance.calendarContainer.classList.add(
              "dashboard_filter_flatpickr"
            )
          }}
          value={dateRange}
          onChange={selectedDates => {
            const formattedDates = selectedDates.map(date =>
              convertDateFormat(date)
            )
            setDateRange(formattedDates)
          }}
          style={{ display: "none" }}
          onOpen={onCalendarOpen}
        />
      </div>
    </div>
  )
}

export default DashboardDateFilter
