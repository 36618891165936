import DetailsInnerDiv from "components/Shared/DetailsInnerDiv"
import DetailsTitle from "components/Shared/DetailsTitle"
import FormateDate from "components/Shared/FormateDate"
import Title from "components/Shared/Title"
import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { ShimmerThumbnail } from "react-shimmer-effects"
import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap"
import { userDetails } from "store/actions/authAction"

const UserProfile = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  //meta title
  document.title = t("Profile | Takween")
  const dispatch = useDispatch()

  const { loading, userProfile } = useSelector(state => state.Login)

  useEffect(() => {
    const fetchUserDetails = () => {
      dispatch(userDetails())
    }

    fetchUserDetails()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <h1 className="main_title">{t("Profile")}</h1>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  {loading ? (
                    <ShimmerThumbnail height={250} rounded className={"mb-0"} />
                  ) : (
                    <>
                      <DetailsTitle
                        title={t("Personal Information")}
                        isBackButton={false}
                      />
                      <Row className="g_details">
                        <Col lg={4}>
                          <DetailsInnerDiv
                            label={t("Id")}
                            value={userProfile?._id || "-"}
                          />
                        </Col>
                        <Col lg={4}>
                          <DetailsInnerDiv
                            label={t("First Name")}
                            value={userProfile?.firstName || "-"}
                          />
                        </Col>
                        <Col lg={4}>
                          <DetailsInnerDiv
                            label={t("Last Name")}
                            value={userProfile?.lastName || "-"}
                          />
                        </Col>
                        {console.log(userProfile?.accountType)}
                        <Col lg={4}>
                          <DetailsInnerDiv
                            label={t("Account Type")}
                            value={t(userProfile?.accountType || "-")}
                            valueClass="text-capitalize"
                          />
                        </Col>
                        {/* <Col lg={3}>
                          <DetailsInnerDiv
                            label={t("Phone Number")}
                            value={userProfile?.phoneNumber || "-"}
                          />
                        </Col> */}
                        <Col lg={4}>
                          <DetailsInnerDiv
                            label={t("Status")}
                            value={
                              userProfile?.status === 1
                                ? t("Active")
                                : t("Deactive") || "-"
                            }
                            valueClass={`${
                              userProfile?.status === 1
                                ? "active_color"
                                : "danger_color"
                            }`}
                          />
                        </Col>
                        <Col lg={4}>
                          <DetailsInnerDiv
                            label={t("Email")}
                            value={userProfile?.email || "-"}
                          />
                        </Col>
                        <Col lg={4}>
                          <DetailsInnerDiv
                            label={t("Created At")}
                            value={FormateDate(userProfile?.createdAt) || "-"}
                          />
                        </Col>
                        <Col lg={4}>
                          <DetailsInnerDiv
                            label={t("Updated At")}
                            value={FormateDate(userProfile?.updatedAt) || "-"}
                          />
                        </Col>
                      </Row>
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default UserProfile
