import { createSlice } from "@reduxjs/toolkit"
import {
  createServiceItem,
  deleteServiceItem,
  getAllServiceItems,
  getServiceItemDetails,
  getServiceItemList,
  updateServiceItem,
  updateServiceItemStatus,
} from "store/actions/serviceItemsAction"

const initialState = {
  loading: true,
  isLoading: false,
  perPage: 10,
  totalCount: null,
  totalPages: null,
  currentPage: null,
  serviceItems: [],
  serviceItemsList: [],
  serviceItemDetails: {},
}

const serviceItems = createSlice({
  name: "service items",
  initialState,
  reducers: {},
  extraReducers: builder => {
    // GET ALL
    builder
      .addCase(getAllServiceItems.pending, state => {
        state.loading = true
      })
      .addCase(getAllServiceItems.fulfilled, (state, action) => {
        state.loading = false
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { data, totalCount, totalPages, currentPage } = action.payload
          state.serviceItems = data
          state.totalCount = totalCount
          state.totalPages = totalPages
          state.currentPage = currentPage
        }
      })
      .addCase(getAllServiceItems.rejected, (state, action) => {
        state.loading = false
      })

    // GET LIST
    builder
      .addCase(getServiceItemList.pending, state => {})
      .addCase(getServiceItemList.fulfilled, (state, action) => {
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { data } = action.payload
          state.serviceItemsList = data
        }
      })
      .addCase(getServiceItemList.rejected, (state, action) => {})

    // GET DETAILS
    builder
      .addCase(getServiceItemDetails.pending, state => {
        state.loading = true
        state.serviceItemDetails = {}
      })
      .addCase(getServiceItemDetails.fulfilled, (state, action) => {
        state.loading = false
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { data } = action.payload
          state.serviceItemDetails = data
        }
      })
      .addCase(getServiceItemDetails.rejected, (state, action) => {
        state.loading = false
      })

    // UPDATE STATUS
    builder
      .addCase(updateServiceItemStatus.pending, state => {
        state.isLoading = true
      })
      .addCase(updateServiceItemStatus.fulfilled, (state, action) => {
        state.isLoading = false
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { id, status } = action.meta.arg
          state.serviceItems = state.serviceItems.map(item =>
            item._id === id ? { ...item, status: Number(status) } : item
          )
        }
      })
      .addCase(updateServiceItemStatus.rejected, (state, action) => {
        state.isLoading = false
      })

    // DELETE
    builder
      .addCase(deleteServiceItem.pending, state => {
        state.isLoading = true
      })
      .addCase(deleteServiceItem.fulfilled, (state, action) => {
        state.isLoading = false
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { id } = action.meta.arg
          state.serviceItems = state.serviceItems.filter(
            item => item._id !== id
          )
          if (state.totalCount !== null) {
            state.totalCount -= 1
          }
        }
      })
      .addCase(deleteServiceItem.rejected, (state, action) => {
        state.isLoading = false
      })

    // CREATE
    builder
      .addCase(createServiceItem.pending, state => {
        state.isLoading = true
      })
      .addCase(createServiceItem.fulfilled, (state, action) => {
        state.isLoading = false
      })
      .addCase(createServiceItem.rejected, (state, action) => {
        state.isLoading = false
      })

    // UPDATE
    builder
      .addCase(updateServiceItem.pending, state => {
        state.isLoading = true
      })
      .addCase(updateServiceItem.fulfilled, (state, action) => {
        state.isLoading = false
      })
      .addCase(updateServiceItem.rejected, (state, action) => {
        state.isLoading = false
      })
  },
})

export default serviceItems.reducer
