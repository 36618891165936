import { createAsyncThunk } from "@reduxjs/toolkit"
import { del, formDataPost, formDataPut, get, patch, post, put } from "helpers/api_helper"
import {
  CREATE_MAINTENANCE_USER,
  DELETE_MAINTENANCE_USER,
  GET_ALL_MAINTENANCE_USERS,
  GET_MAINTENANCE_USER_DETAILS,
  UPDATE_MAINTENANCE_USER,
  UPDATE_MAINTENANCE_USER_STATUS,
} from "helpers/url_helper"

export const getAllMaintenanceUsers = createAsyncThunk(
  GET_ALL_MAINTENANCE_USERS,
  async payload => {
    const response = await post(GET_ALL_MAINTENANCE_USERS, payload)
    return response
  }
)

export const getMaintenanceUserDetails = createAsyncThunk(
  GET_MAINTENANCE_USER_DETAILS,
  async payload => {
    const response = await post(GET_MAINTENANCE_USER_DETAILS, payload)
    return response
  }
)

export const updateMaintenanceUserStatus = createAsyncThunk(
  UPDATE_MAINTENANCE_USER_STATUS,
  async payload => {
    const response = await patch(UPDATE_MAINTENANCE_USER_STATUS, payload)
    return response
  }
)

export const deleteMaintenanceUser = createAsyncThunk(
  DELETE_MAINTENANCE_USER,
  async payload => {
    const response = await del(DELETE_MAINTENANCE_USER, payload)
    return response
  }
)

export const createMaintenanceUser = createAsyncThunk(
  CREATE_MAINTENANCE_USER,
  async payload => {
    const response = await formDataPost(CREATE_MAINTENANCE_USER, payload)
    return response
  }
)

export const updateMaintenanceUser = createAsyncThunk(UPDATE_MAINTENANCE_USER, async payload => {
  const response = await formDataPut(UPDATE_MAINTENANCE_USER, payload)
  return response
})
