import React from "react"
import { Card, CardBody, Col, Tooltip } from "reactstrap"
import partner1 from "assets/images/garbage/partner1.png"
import Switch from "components/Shared/Switch"
import TrashIcon from "assets/images/icons/trash.svg"
import EditIcon from "assets/images/icons/edit.svg"

const SinglePartner = ({
  t,
  item,
  setShowDeleteModal,
  setModalType,
  setIsModal,
  handleStatusClick,
  selectedId,
  setSelectedId,
  isLoading,
  setItemIdToDelete,
}) => {
  return (
    <Col md={4}>
      <Card className="border rounded-4">
        <CardBody>
          <img
            src={item.imageUrl}
            alt=""
            className="banner_img h_center img-fluid"
            style={{ margin: "33px auto", maxHeight: 162 }}
          />
          <div className="mt-3 g_between">
            <div>
              <img
                src={EditIcon}
                id={`edit_${item._id}`}
                alt="Edit Icon"
                width={24}
                height={24}
                className="cursor-pointer"
                onClick={() => {
                  setModalType("UPDATE")
                  setIsModal(true)
                  setSelectedId(item._id)
                }}
              />
              <img
                src={TrashIcon}
                alt="Trash Icon"
                width={24}
                height={24}
                id={`delete_${item._id}`}
                className="cursor-pointer ms-3"
                onClick={() => {
                  setShowDeleteModal(true)
                  setItemIdToDelete(item._id)
                }}
              />
            </div>
            <div style={{ marginRight: 10 }}>
              <Switch
                onClickHandler={() => {
                  handleStatusClick(item._id, item.status)
                }}
                isChecked={item.status === 1 ? true : false}
                disabled={selectedId === item._id && isLoading}
              />
            </div>
          </div>
        </CardBody>
      </Card>
    </Col>
  )
}

export default SinglePartner
