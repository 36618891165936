import React from "react"
import { useNavigate } from "react-router-dom"
import BackArrow from "assets/images/icons/back-arrow.svg"

const BackButton = () => {
  const navigate = useNavigate()

  const goBack = () => {
    if (window.history.state && window.history.state.idx > 0) {
      navigate(-1)
    } else {
      navigate("/dashboard")
    }
  }

  return (
    <img
      src={BackArrow}
      alt="back arrow"
      onClick={goBack}
      className="cursor-pointer"
    />
    // <i
    //   className="bx bx-arrow-back me-1 fs-5 cursor-pointer"
    //   onClick={goBack}
    // ></i>
  )
}

export default BackButton
