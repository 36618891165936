import { createStore, applyMiddleware, compose } from "redux"
import createSagaMiddleware from "redux-saga"
import { configureStore } from "@reduxjs/toolkit"
import rootReducer from "./reducers"
import rootSaga from "./sagas"

const sagaMiddleware = createSagaMiddleware()

const initializeStateFromLocalStorage = () => {
  const authUser = JSON.parse(localStorage.getItem("authUser"))
  const isLoggedIn = localStorage.getItem("isLoggedIn") === "true"
  const userType = localStorage.getItem("userType")
  // const userRole = authUser ? authUser.role : []
  // const accountType = localStorage.getItem("accountType")
  return {
    Login: {
      authUser: authUser || {},
      isLoggedIn,
      userType,
      // accountType,
    },
  }
}

const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(sagaMiddleware),
  preloadedState: initializeStateFromLocalStorage(), // Initialize state from localStorage
  devTools: true,
})
sagaMiddleware.run(rootSaga)

export default store
