import { createSlice } from "@reduxjs/toolkit"
import {
  createUser,
  deleteUser,
  getAllUsers,
  getUserDetails,
  getRolesList,
  updateUserStatus,
  updateUser,
} from "store/actions/usersAction"

const initialState = {
  loading: true,
  isLoading: false,
  perPage: 10,
  totalCount: null,
  totalPages: null,
  currentPage: null,
  users: [],
  rolesList: [],
  userDetails: {},
}

const usersSlice = createSlice({
  name: "Users", //
  initialState,
  reducers: {},
  extraReducers: builder => {
    // GET ALL USERS
    builder
      .addCase(getAllUsers.pending, state => {
        state.loading = true
      })
      .addCase(getAllUsers.fulfilled, (state, action) => {
        state.loading = false
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { data, totalCount, totalPages, currentPage } = action.payload
          state.users = data
          state.totalCount = totalCount
          state.totalPages = totalPages
          state.currentPage = currentPage
        }
      })
      .addCase(getAllUsers.rejected, (state, action) => {
        state.loading = false
      })

    // GET USERS LIST
    builder
      .addCase(getRolesList.pending, state => {})
      .addCase(getRolesList.fulfilled, (state, action) => {
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { data } = action.payload
          state.rolesList = data
        }
      })
      .addCase(getRolesList.rejected, (state, action) => {})

    // GET USER DETAILS
    builder
      .addCase(getUserDetails.pending, state => {
        state.loading = true
      })
      .addCase(getUserDetails.fulfilled, (state, action) => {
        state.loading = false
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { data } = action.payload
          state.userDetails = data
        }
      })
      .addCase(getUserDetails.rejected, (state, action) => {
        state.loading = false
      })

    // UPDATE STATUS
    builder
      .addCase(updateUserStatus.pending, state => {
        state.isLoading = true
      })
      .addCase(updateUserStatus.fulfilled, (state, action) => {
        state.isLoading = false
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { id, status } = action.meta.arg
          state.users = state.users.map(item =>
            item._id === id ? { ...item, status: Number(status) } : item
          )
        }
      })
      .addCase(updateUserStatus.rejected, (state, action) => {
        state.isLoading = false
      })

    // DELETE 
    builder
      .addCase(deleteUser.pending, state => {
        state.isLoading = true
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.isLoading = false
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { id } = action.meta.arg
          state.users = state.users.filter(item => item._id !== id)
          if (state.totalCount !== null) {
            state.totalCount -= 1
          }
        }
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.isLoading = false
      })

    // CREATE 
    builder
      .addCase(createUser.pending, state => {
        state.isLoading = true
      })
      .addCase(createUser.fulfilled, (state, action) => {
        state.isLoading = false
      })
      .addCase(createUser.rejected, (state, action) => {
        state.isLoading = false
      })

    // UPDATE 
    builder
      .addCase(updateUser.pending, state => {
        state.isLoading = true
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.isLoading = false
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.isLoading = false
      })
  },
})

export default usersSlice.reducer
