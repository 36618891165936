import React, { useEffect } from "react"
import {
  Badge,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Table,
} from "reactstrap"
import { useTranslation } from "react-i18next"
import BackButton from "components/Shared/BackButton"
import NoDataAvailable from "components/Shared/NoDataAvailable"
import DetailsTitle from "components/Shared/DetailsTitle"
import DetailsInnerDiv from "components/Shared/DetailsInnerDiv"
import { useDispatch, useSelector } from "react-redux"
import { useLocation } from "react-router-dom"
import { getMaintenanceUserDetails } from "store/actions/maintenanceUsersAction"
import { ShimmerThumbnail } from "react-shimmer-effects"
import FormateDate from "components/Shared/FormateDate"

const MaintenanceUserDetails = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  document.title = t("Maintenance User Details | Takween")

  const dispatch = useDispatch()
  const { loading, maintenanceUserDetails } = useSelector(
    state => state.MaintenanceUsers
  )

  const { search } = useLocation()
  const id = new URLSearchParams(search).get("id")

  useEffect(() => {
    const fetchMaintenanceUserDetails = () => {
      const payload = {
        id,
      }
      dispatch(getMaintenanceUserDetails(payload))
    }
    fetchMaintenanceUserDetails()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <DetailsTitle title={t("Maintenance User Details")} />

                  <div>
                    {loading ? (
                      <ShimmerThumbnail
                        height={250}
                        rounded
                        className={"mb-0"}
                      />
                    ) : Object.values(maintenanceUserDetails).length === 0 ? (
                      <p className="text-center my-3">
                        <NoDataAvailable />
                      </p>
                    ) : (
                      <Row className="g_details">
                        <Col md={3}>
                          <DetailsInnerDiv
                            label={t("Id")}
                            value={maintenanceUserDetails._id}
                          />
                        </Col>{" "}
                        <Col md={3}>
                          <DetailsInnerDiv
                            label={t("Name")}
                            value={maintenanceUserDetails.name}
                          />
                        </Col>
                        <Col md={3}>
                          <DetailsInnerDiv
                            label={t("Phone Code")}
                            value={maintenanceUserDetails.phoneCode}
                          />
                        </Col>
                        <Col md={3}>
                          <DetailsInnerDiv
                            label={t("Phone Number")}
                            value={maintenanceUserDetails.phoneNumber}
                          />
                        </Col>
                        <Col md={3}>
                          <DetailsInnerDiv
                            label={t("Email")}
                            value={maintenanceUserDetails.email}
                          />
                        </Col>
                        <Col md={3}>
                          <DetailsInnerDiv
                            label={t("National Id")}
                            value={maintenanceUserDetails.nationalId || "-"}
                          />
                        </Col>
                        <Col md={3}>
                          <DetailsInnerDiv
                            label={t("Serial No")}
                            value={maintenanceUserDetails.serialNo || "-"}
                          />
                        </Col>
                        <Col md={3}>
                          <DetailsInnerDiv
                            label={t("Service Name")}
                            value={
                              language === "sa"
                                ? maintenanceUserDetails?.serviceId?.nameAr
                                : maintenanceUserDetails?.serviceId?.name || "-"
                            }
                          />
                        </Col>
                        <Col md={3}>
                          <DetailsInnerDiv
                            label={t("Status")}
                            value={
                              maintenanceUserDetails.status === 1
                                ? t("Active")
                                : t("Deactive")
                            }
                            valueClass={
                              maintenanceUserDetails.status === 1
                                ? "active_color"
                                : "danger_color"
                            }
                          />
                        </Col>
                        <Col md={3}>
                          <DetailsInnerDiv
                            label={t("Last Logged In")}
                            value={
                              FormateDate(
                                maintenanceUserDetails.lastLoggedIn
                              ) || "-"
                            }
                          />
                        </Col>
                      </Row>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default MaintenanceUserDetails
