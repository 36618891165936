import React, { useState } from "react"
import { SketchPicker } from "react-color"

const ColorPicker = ({ label = null, color = null, onChange = () => {} }) => {
  const [displayColorPicker, setDisplayColorPicker] = useState(false)

  const handleClick = () => {
    setDisplayColorPicker(true)
  }

  const handleClose = () => {
    setDisplayColorPicker(false)
  }

  const handleChange = color => {
    onChange(color)
  }

  return (
    <div className="color_container position-relative">
      <label className="fw-bold text-primary font-size-16">{label}</label>
      <div className="color_inner_div">
        <div
          className="color_picker_div"
          style={{ background: color }}
          onClick={handleClick}
        ></div>
        <p className="text-primary fs_18_700 px-3 mb-0">{color}</p>

        {displayColorPicker && (
          <div
            style={{
              position: "absolute",
              zIndex: "2",
              top: "85px",
              left: "5px",
            }}
          >
            <div
              style={{
                position: "fixed",
                top: "0px",
                right: "0px",
                bottom: "0px",
                left: "0px",
              }}
              onClick={handleClose}
            />
            <SketchPicker
              color={color}
              onChangeComplete={handleChange}
              disableAlpha={false}
            />
          </div>
        )}
      </div>
      {displayColorPicker && <div style={{ height: 315 }}></div>}
    </div>
  )
}

export default ColorPicker
