import { createAsyncThunk } from "@reduxjs/toolkit"
import {
  del,
  formDataPost,
  formDataPut,
  get,
  patch,
  post,
  put,
} from "helpers/api_helper"
import {
  CREATE_BANNER,
  DELETE_BANNER,
  GET_ALL_BANNERS,
  GET_BANNER_DETAILS,
  UPDATE_BANNER,
  UPDATE_BANNER_STATUS,
} from "helpers/url_helper"

export const geAllBanners = createAsyncThunk(GET_ALL_BANNERS, async payload => {
  const response = await post(GET_ALL_BANNERS, payload)
  return response
})

export const updateBannerStatus = createAsyncThunk(
  UPDATE_BANNER_STATUS,
  async payload => {
    const response = await patch(UPDATE_BANNER_STATUS, payload)
    return response
  }
)

export const getBannerDetails = createAsyncThunk(
  GET_BANNER_DETAILS,
  async payload => {
    const response = await post(GET_BANNER_DETAILS, payload)
    return response
  }
)

export const deleteBanner = createAsyncThunk(DELETE_BANNER, async payload => {
  const response = await del(DELETE_BANNER, payload)
  return response
})

export const createBanner = createAsyncThunk(CREATE_BANNER, async payload => {
  const response = await formDataPost(CREATE_BANNER, payload)
  return response
})

export const updateBanner = createAsyncThunk(UPDATE_BANNER, async payload => {
  const response = await formDataPut(UPDATE_BANNER, payload)
  return response
})
