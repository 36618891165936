import React from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import {
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
} from "reactstrap"

const Paginations = ({
  currentPage,
  totalPages,
  onPageChange = () => {},
  totalCount,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  const handleClickPrevious = () => {
    onPageChange(Math.max(currentPage - 1, 1)) // Update data for previous page
  }

  const handleClickNext = () => {
    onPageChange(Math.min(currentPage + 1, totalPages)) // Update data for next page
  }

  const handleClickPage = page => {
    onPageChange(page) // Update data for selected page
  }

  // Function to generate page numbers with ellipsis
  const generatePageNumbers = () => {
    const pageNumbers = []
    const range = 2 // Number of page numbers to show before and after the current page
    let start = Math.max(1, currentPage - range)
    let end = Math.min(totalPages, currentPage + range)

    // Add ellipsis and first page if currentPage is not at the beginning
    if (currentPage > range + 1) {
      pageNumbers.push(
        <PaginationItem key={1}>
          <PaginationLink href="#" onClick={() => handleClickPage(1)}>
            1
          </PaginationLink>
        </PaginationItem>
      )
      pageNumbers.push(
        <PaginationItem key="ellipsis-start" disabled>
          <PaginationLink href="#">...</PaginationLink>
        </PaginationItem>
      )
    }

    // Generate page numbers around the current page
    for (let i = start; i <= end; i++) {
      pageNumbers.push(
        <PaginationItem key={i} active={currentPage === i}>
          <PaginationLink href="#" onClick={() => handleClickPage(i)}>
            {i}
          </PaginationLink>
        </PaginationItem>
      )
    }

    // Add ellipsis and last page if currentPage is not at the end
    if (currentPage < totalPages - range) {
      pageNumbers.push(
        <PaginationItem key="ellipsis-end" disabled>
          <PaginationLink href="#">...</PaginationLink>
        </PaginationItem>
      )
      pageNumbers.push(
        <PaginationItem key={totalPages}>
          <PaginationLink href="#" onClick={() => handleClickPage(totalPages)}>
            {totalPages}
          </PaginationLink>
        </PaginationItem>
      )
    }

    return pageNumbers
  }

  return (
    <div className="align-items-center pt-2">
      <Col sm={12} md={12}>
        <div className={"paginationWrapper g_between"}>
          <h2 className="mb-0 fs_16 fw_500">
            {t("Total Records")}: {totalCount}
          </h2>
          <ul className={"pagination gap-2 mb-0"}>
            <li
              className={`paginate_button page-item previous ${
                currentPage === 1 ? "disabled" : ""
              }`}
            >
              <button
                className="transparent_btn pagination_prev_btn"
                onClick={handleClickPrevious}
                disabled={currentPage === 1}
              >
                {t("Previous")}
              </button>
            </li>
            {/* {generatePageNumbers()} */}
            <li
              className={`paginate_button page-item next ${
                currentPage === totalPages ? "disabled" : ""
              }`}
            >
              <button
                className="transparent_btn pagination_next_btn"
                onClick={handleClickNext}
                disabled={currentPage === totalPages}
              >
                {t("Next")}
              </button>
            </li>
          </ul>
        </div>
      </Col>
    </div>
  )
}

export default Paginations
