import { createSlice } from "@reduxjs/toolkit"
import {
  createMaintenanceUser,
  deleteMaintenanceUser,
  getAllMaintenanceUsers,
  getMaintenanceUserDetails,
  updateMaintenanceUser,
  updateMaintenanceUserStatus,
} from "store/actions/maintenanceUsersAction"

const initialState = {
  loading: true,
  isLoading: false,
  perPage: 10,
  totalCount: null,
  totalPages: null,
  currentPage: null,
  maintenanceUsers: [],
  maintenanceUserDetails: {},
}
const maintenanceUsersSlice = createSlice({
  name: "maintenance users",
  initialState,
  reducers: {},
  extraReducers: builder => {
    // GET ALL
    builder
      .addCase(getAllMaintenanceUsers.pending, state => {
        state.loading = true
      })
      .addCase(getAllMaintenanceUsers.fulfilled, (state, action) => {
        state.loading = false
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { data, totalCount, totalPages, currentPage } = action.payload
          state.maintenanceUsers = data
          state.totalCount = totalCount
          state.totalPages = totalPages
          state.currentPage = currentPage
        }
      })
      .addCase(getAllMaintenanceUsers.rejected, (state, action) => {
        state.loading = false
      })

    // GET LIST
    // builder
    //   .addCase(getRolesList.pending, state => {})
    //   .addCase(getRolesList.fulfilled, (state, action) => {
    //     const { statusCode, success } = action.payload
    //     if (statusCode === 200 && success) {
    //       const { data } = action.payload
    //       state.rolesList = data //
    //     }
    //   })
    //   .addCase(getRolesList.rejected, (state, action) => {})

    // GET DETAILS
    builder
      .addCase(getMaintenanceUserDetails.pending, state => {
        state.loading = true
        state.maintenanceUserDetails = {}
      })
      .addCase(getMaintenanceUserDetails.fulfilled, (state, action) => {
        state.loading = false
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { data } = action.payload
          state.maintenanceUserDetails = data
        }
      })
      .addCase(getMaintenanceUserDetails.rejected, (state, action) => {
        state.loading = false
      })

    // UPDATE STATUS
    builder
      .addCase(updateMaintenanceUserStatus.pending, state => {
        state.isLoading = true
      })
      .addCase(updateMaintenanceUserStatus.fulfilled, (state, action) => {
        state.isLoading = false
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { id, status } = action.meta.arg
          state.maintenanceUsers = state.maintenanceUsers.map(item =>
            item._id === id ? { ...item, status: Number(status) } : item
          )
        }
      })
      .addCase(updateMaintenanceUserStatus.rejected, (state, action) => {
        state.isLoading = false
      })

    // DELETE
    builder
      .addCase(deleteMaintenanceUser.pending, state => {
        state.isLoading = true
      })
      .addCase(deleteMaintenanceUser.fulfilled, (state, action) => {
        state.isLoading = false
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { id } = action.meta.arg
          state.maintenanceUsers = state.maintenanceUsers.filter(
            item => item._id !== id
          ) //
          if (state.totalCount !== null) {
            state.totalCount -= 1
          }
        }
      })
      .addCase(deleteMaintenanceUser.rejected, (state, action) => {
        state.isLoading = false
      })

    // CREATE
    builder
      .addCase(createMaintenanceUser.pending, state => {
        state.isLoading = true
      })
      .addCase(createMaintenanceUser.fulfilled, (state, action) => {
        state.isLoading = false
      })
      .addCase(createMaintenanceUser.rejected, (state, action) => {
        state.isLoading = false
      })

    // UPDATE
    builder
      .addCase(updateMaintenanceUser.pending, state => {
        state.isLoading = true
      })
      .addCase(updateMaintenanceUser.fulfilled, (state, action) => {
        state.isLoading = false
      })
      .addCase(updateMaintenanceUser.rejected, (state, action) => {
        state.isLoading = false
      })
  },
})

export default maintenanceUsersSlice.reducer
