import { createAsyncThunk } from "@reduxjs/toolkit"
import {
  del,
  formDataPost,
  formDataPut,
  get,
  patch,
  post,
  put,
} from "helpers/api_helper"
import {
  CREATE_PARTNER,
  DELETE_PARTNER,
  GET_ALL_PARTNERS,
  GET_PARTNER_DETAILS,
  UPDATE_PARTNER,
  UPDATE_PARTNER_STATUS,
} from "helpers/url_helper"

export const getAllPartners = createAsyncThunk(
  GET_ALL_PARTNERS,
  async payload => {
    const response = await post(GET_ALL_PARTNERS, payload)
    return response
  }
)

export const updatePartnerStatus = createAsyncThunk(
  UPDATE_PARTNER_STATUS,
  async payload => {
    const response = await patch(UPDATE_PARTNER_STATUS, payload)
    return response
  }
)

export const getPartnerDetails = createAsyncThunk(
  GET_PARTNER_DETAILS,
  async payload => {
    const response = await post(GET_PARTNER_DETAILS, payload)
    return response
  }
)

export const deletePartner = createAsyncThunk(DELETE_PARTNER, async payload => {
  const response = await del(DELETE_PARTNER, payload)
  return response
})

export const createPartner = createAsyncThunk(CREATE_PARTNER, async payload => {
  const response = await formDataPost(CREATE_PARTNER, payload)
  return response
})

export const updatePartner = createAsyncThunk(UPDATE_PARTNER, async payload => {
  const response = await formDataPut(UPDATE_PARTNER, payload)
  return response
})
