import React, { useState } from "react"
import { Card, Col, Container, Row, Table } from "reactstrap"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import useFilters from "components/Shared/useFilters"
import Title from "components/Shared/Title"
import { baseFilterOptions } from "components/Common/constants"
import Filters from "components/Shared/Filters"
import NoDataAvailable from "components/Shared/NoDataAvailable"
import FormateDate from "components/Shared/FormateDate"
import Paginations from "components/Common/Pagination"

const salesHistory = [
  {
    _id: "1",
    projectName: "Skyline Towers",
    unitNumber: "A101",
    customerName: "John Doe",
    customerPhone: "+1234567890",
    customerEmail: "johndoe@example.com",
    paymentMethod: "Saddad",
    status: 1,
    paymentDate: "2024-01-15T00:00:00Z",
    totalAmount: 500000,
    paymentStatus: "Paid",
  },
  {
    _id: "2",
    projectName: "Green Valley",
    unitNumber: "B202",
    customerName: "Jane Smith",
    customerPhone: "+0987654321",
    customerEmail: "janesmith@example.com",
    paymentMethod: "Bank Cheque",
    status: 0,
    paymentDate: "2023-12-20T00:00:00Z",
    totalAmount: 450000,
    paymentStatus: "Pending",
  },
  {
    _id: "3",
    projectName: "Ocean View",
    unitNumber: "C303",
    customerName: "Michael Johnson",
    customerPhone: "+1122334455",
    customerEmail: "michaelj@example.com",
    paymentMethod: "Saddad",
    status: 1,
    paymentDate: "2024-02-10T00:00:00Z",
    totalAmount: 750000,
    paymentStatus: "Paid",
  },
  {
    _id: "4",
    projectName: "Sunset Apartments",
    unitNumber: "D404",
    customerName: "Emily Davis",
    customerPhone: "+5566778899",
    customerEmail: "emilydavis@example.com",
    paymentMethod: "Bank Cheque",
    status: 0,
    paymentDate: "2024-03-05T00:00:00Z",
    totalAmount: 600000,
    paymentStatus: "Pending",
  },
  {
    _id: "5",
    projectName: "City Center Plaza",
    unitNumber: "E505",
    customerName: "David Brown",
    customerPhone: "+6677889900",
    customerEmail: "davidbrown@example.com",
    paymentMethod: "Saddad",
    status: 1,
    paymentDate: "2023-11-30T00:00:00Z",
    totalAmount: 820000,
    paymentStatus: "Paid",
  },
  {
    _id: "6",
    projectName: "Lakeside Residences",
    unitNumber: "F606",
    customerName: "Sarah Wilson",
    customerPhone: "+9988776655",
    customerEmail: "sarahwilson@example.com",
    paymentMethod: "Bank Cheque",
    status: 1,
    paymentDate: "2024-01-10T00:00:00Z",
    totalAmount: 580000,
    paymentStatus: "Pending",
  },
  {
    _id: "7",
    projectName: "Maple Woods",
    unitNumber: "G707",
    customerName: "James Miller",
    customerPhone: "+2233445566",
    customerEmail: "jamesmiller@example.com",
    paymentMethod: "Saddad",
    status: 0,
    paymentDate: "2023-12-05T00:00:00Z",
    totalAmount: 730000,
    paymentStatus: "Paid",
  },
  {
    _id: "8",
    projectName: "Riverside Heights",
    unitNumber: "H808",
    customerName: "Patricia Anderson",
    customerPhone: "+3344556677",
    customerEmail: "patriciaanderson@example.com",
    paymentMethod: "Bank Cheque",
    status: 1,
    paymentDate: "2024-03-25T00:00:00Z",
    totalAmount: 640000,
    paymentStatus: "Pending",
  },
  {
    _id: "9",
    projectName: "Park Lane",
    unitNumber: "I909",
    customerName: "Robert Clark",
    customerPhone: "+4455667788",
    customerEmail: "robertclark@example.com",
    paymentMethod: "Saddad",
    status: 1,
    paymentDate: "2024-02-28T00:00:00Z",
    totalAmount: 510000,
    paymentStatus: "Paid",
  },
  {
    _id: "10",
    projectName: "Pine Grove",
    unitNumber: "J1010",
    customerName: "Linda Martinez",
    customerPhone: "+5566778899",
    customerEmail: "lindamartinez@example.com",
    paymentMethod: "Bank Cheque",
    status: 0,
    paymentDate: "2023-11-15T00:00:00Z",
    totalAmount: 680000,
    paymentStatus: "Pending",
  },
]

const SalesHistory = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  document.title = t("Sales History | Takween")

  const [keyword, setKeyword] = useState("")
  const navigate = useNavigate()

  const [filters, handleFilterChange] = useFilters({
    status: 2,
    orderBy: 1,
  })

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg={12}>
              <Card>
                <Title title={t("Sales History List")} />
                <Filters
                  isGlobalFilter={true}
                  SearchPlaceholder={t("Search")}
                  keyword={keyword}
                  setKeyword={setKeyword}
                  filters={baseFilterOptions}
                  selectedFilters={filters}
                  handleFilterChange={handleFilterChange}
                />

                {salesHistory.length === 0 ? (
                  <NoDataAvailable />
                ) : (
                  <div className="table-responsive">
                    <Table className="table mb-0">
                      <thead className="full_th">
                        <tr>
                          <th>{t("S. No")}</th>
                          <th>{t("Project Name")}</th>
                          <th>{t("Unit Number")}</th>
                          <th>{t("Amount")}</th>
                          <th>{t("Customer Name")}</th>
                          <th>{t("Customer Number")}</th>
                          <th>{t("Payment Method")}</th>
                          <th>{t("Payment Date")}</th>
                          <th>{t("Status")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {salesHistory.map((sales, index) => (
                          <tr
                            key={sales._id}
                            className="cursor-pointer"
                            onClick={() => {
                              navigate(`/sales-history/details?id=${sales._id}`)
                            }}
                          >
                            <th scope="row">{index + 1}</th>
                            <td>{sales.projectName}</td>
                            <td>{sales.unitNumber}</td>
                            <td>{sales.totalAmount} SAR</td>
                            <td>{sales.customerName}</td>
                            <td>{sales.customerPhone}</td>
                            <td>{sales.paymentMethod}</td>
                            <td>{FormateDate(sales.paymentDate)}</td>
                            <td>
                              {sales.status === 0 && <p>Rented</p>}
                              {sales.status === 1 && <p>Sold</p>}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                )}
                <Paginations currentPage={1} totalPages={5} totalCount={5} />
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default SalesHistory
