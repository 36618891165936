import { createSlice } from "@reduxjs/toolkit"
import {
  getAllNotifications,
  getNotificationDetails,
  pushNotificationBroadCast,
  SMSBroadCast,
  updateNotification,
  updateNotificationStatus,
} from "store/actions/notificationsActions"

const initialState = {
  loading: true,
  isLoading: false,
  isGettingDetails: true,
  perPage: 10,
  totalCount: null,
  totalPages: null,
  currentPage: null,
  notifications: [],
  notificationDetails: {},
}
const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {},
  extraReducers: builder => {
    // GET ALL
    builder
      .addCase(getAllNotifications.pending, state => {
        state.loading = true
      })
      .addCase(getAllNotifications.fulfilled, (state, action) => {
        state.loading = false
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { data, totalCount, totalPages, currentPage } = action.payload
          state.notifications = data
          state.totalCount = totalCount
          state.totalPages = totalPages
          state.currentPage = currentPage
        }
      })
      .addCase(getAllNotifications.rejected, (state, action) => {
        state.loading = false
      })

    // GET DETAILS
    builder
      .addCase(getNotificationDetails.pending, state => {
        state.isGettingDetails = true
        state.notificationDetails = {}
      })
      .addCase(getNotificationDetails.fulfilled, (state, action) => {
        state.isGettingDetails = false
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { data } = action.payload
          state.notificationDetails = data
        }
      })
      .addCase(getNotificationDetails.rejected, (state, action) => {
        state.isGettingDetails = false
      })

    // UPDATE STATUS
    builder
      .addCase(updateNotificationStatus.pending, state => {
        state.isLoading = true
      })
      .addCase(updateNotificationStatus.fulfilled, (state, action) => {
        state.isLoading = false
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { id, status } = action.meta.arg
          state.notifications = state.notifications.map(item =>
            item._id === id ? { ...item, status: Number(status) } : item
          )
        }
      })
      .addCase(updateNotificationStatus.rejected, (state, action) => {
        state.isLoading = false
      })

    // UPDATE
    builder
      .addCase(updateNotification.pending, state => {
        state.isLoading = true
      })
      .addCase(updateNotification.fulfilled, (state, action) => {
        state.isLoading = false
        const { statusCode, success, data } = action.payload
        if (statusCode === 200 && success) {
          const { id } = action.meta.arg
          state.notifications = state.notifications.map(notification =>
            notification.id === id ? data : notification
          )
        }
      })
      .addCase(updateNotification.rejected, (state, action) => {
        state.isLoading = false
      })

    // UPDATE
    builder
      .addCase(pushNotificationBroadCast.pending, state => {
        state.isLoading = true
      })
      .addCase(pushNotificationBroadCast.fulfilled, (state, action) => {
        state.isLoading = false
      })
      .addCase(pushNotificationBroadCast.rejected, (state, action) => {
        state.isLoading = false
      })

    // UPDATE
    builder
      .addCase(SMSBroadCast.pending, state => {
        state.isLoading = true
      })
      .addCase(SMSBroadCast.fulfilled, (state, action) => {
        state.isLoading = false
      })
      .addCase(SMSBroadCast.rejected, (state, action) => {
        state.isLoading = false
      })
  },
})

export default notificationsSlice.reducer
