import React, { useEffect } from "react"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import { useTranslation } from "react-i18next"
import TopTitle from "components/Shared/TopTitle"
import DetailsInnerDiv from "components/Shared/DetailsInnerDiv"
import DetailsTitle from "components/Shared/DetailsTitle"
import { useDispatch, useSelector } from "react-redux"
import { useLocation } from "react-router-dom"
import NoDataAvailable from "components/Shared/NoDataAvailable"
import { ShimmerThumbnail } from "react-shimmer-effects"
import { getServiceDetails } from "store/actions/servicesAction"

const ServiceDetails = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  document.title = t("Service Details | Takween")

  const dispatch = useDispatch()
  const { loading, serviceDetails } = useSelector(state => state.Services)
  const name = useSelector(state => state.Login.userProfile?.name) || ""

  const { search } = useLocation()
  const id = new URLSearchParams(search).get("id")

  useEffect(() => {
    const fetchDetails = () => {
      const payload = {
        id,
      }
      dispatch(getServiceDetails(payload))
    }
    fetchDetails()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <TopTitle
            title={t("Service Details")}
            subTitle={t(
              `Hi, {{name}}. Welcome back to your Marketing portal!`,
              { name: name }
            )}
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <DetailsTitle title={t("Service Details")} />
                  {loading ? (
                    <ShimmerThumbnail height={400} rounded className={"mb-0"} />
                  ) : Object.values(serviceDetails).length === 0 ? (
                    <p className="text-center my-3">
                      <NoDataAvailable />
                    </p>
                  ) : (
                    <Row className="g_details">
                      <Col md={3}>
                        <DetailsInnerDiv
                          label={t("Id")}
                          value={serviceDetails._id}
                        />
                      </Col>
                      <Col lg={3}>
                        <div className="d-flex mt-3">
                          <div>
                            <p className="fs_14_600 opacity-50 mb-2">
                              {t("Icon")}
                            </p>
                            {serviceDetails.imageUrl ? (
                              <img
                                src={serviceDetails.imageUrl}
                                alt=""
                                className="img-fluid rounded-3"
                                style={{ objectFit: "contain" }}
                              />
                            ) : (
                              "-"
                            )}
                          </div>
                        </div>
                      </Col>
                      <Col md={3}>
                        <DetailsInnerDiv
                          label={t("Name")}
                          value={
                            language === "sa"
                              ? serviceDetails.nameAr
                              : serviceDetails.name
                          }
                        />
                      </Col>
                      <Col md={3}>
                        <DetailsInnerDiv
                          label={t("Status")}
                          value={
                            serviceDetails.status === 1
                              ? t("Active")
                              : t("Deactive")
                          }
                          valueClass={`${
                            serviceDetails.status === 1
                              ? "active_color"
                              : "danger_color"
                          }`}
                        />
                      </Col>
                    </Row>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ServiceDetails
