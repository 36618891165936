import { updateProfileSchema } from "components/Common/validation"
import CustomField from "components/Shared/CustomField"
import CustomLabel from "components/Shared/CustomLabel"
import FormButtons from "components/Shared/FormButtons"
import Spinners from "components/Shared/Spinners"
import { updateProfileValues } from "constants/initialValues"
import { Form, Formik } from "formik"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import { updateUserDetails, userDetails } from "store/actions/authAction"
import { showAlert } from "store/reducers/alertReducer"

const UpdateProfile = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  document.title = t("Update Profile | Takween")
  const dispatch = useDispatch()

  const { loading, userProfile, isLoading } = useSelector(state => state.Login)

  const [formValues, setFormValues] = useState(updateProfileValues)

  useEffect(() => {
    const fetchUserDetails = () => {
      dispatch(userDetails())
    }
    fetchUserDetails()
  }, [])

  useEffect(() => {
    if (userProfile) {
      const { firstName, lastName, phoneNumber, email } = userProfile
      setFormValues(prev => ({
        ...prev,
        firstName,
        lastName,
        phoneNumber,
        email,
      }))
    }
  }, [userProfile])

  const handleOnSubmit = values => {
    dispatch(updateUserDetails(values))
      .then(action => {
        const { statusCode, success, message, data } = action?.payload
        if (statusCode === 200 && success) {
          dispatch(showAlert({ text: message, type: "success" }))
        } else {
          dispatch(showAlert({ text: message, type: "error" }))
        }
      })
      .catch(error => {})
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <h1 className="main_title">{t("Update Profile")}</h1>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  {loading ? (
                    <Spinners />
                  ) : (
                    <Formik
                      initialValues={formValues}
                      enableReinitialize={true}
                      validationSchema={updateProfileSchema}
                      onSubmit={handleOnSubmit}
                    >
                      {({ values, errors, touched }) => (
                        <Form>
                          <Row>
                            <Col lg={6}>
                              <div className="form_field">
                                <CustomLabel
                                  htmlFor="firstName"
                                  label={t("First Name")}
                                  isRequired={true}
                                />
                                <CustomField
                                  name="firstName"
                                  type="text"
                                  placeholder={t("First Name")}
                                  touched={touched}
                                  errors={errors}
                                />
                              </div>
                            </Col>
                            <Col lg={6}>
                              <div className="form_field">
                                <CustomLabel
                                  htmlFor="lastName"
                                  label={t("Last Name")}
                                  isRequired={true}
                                />
                                <CustomField
                                  name="lastName"
                                  type="text"
                                  placeholder={t("Last Name")}
                                  touched={touched}
                                  errors={errors}
                                />
                              </div>
                            </Col>
                            <Col lg={6}>
                              <div className="form_field">
                                <CustomLabel
                                  htmlFor="phoneNumber"
                                  isRequired={true}
                                  label={t("Phone Number")}
                                />
                                <CustomField
                                  name="phoneNumber"
                                  type="text"
                                  placeholder={t("Phone Number")}
                                  touched={touched}
                                  errors={errors}
                                />
                              </div>
                            </Col>
                            <Col lg={6}>
                              <div className="form_field">
                                <CustomLabel
                                  htmlFor="email"
                                  label={t("Email")}
                                  isRequired={true}
                                />
                                <CustomField
                                  name="email"
                                  type="email"
                                  isReadOnly={true}
                                  placeholder={t("Email")}
                                  touched={touched}
                                  errors={errors}
                                />
                              </div>
                            </Col>
                            <Col lg={12}>
                              <FormButtons
                                buttonText={t("Update")}
                                isLoading={isLoading}
                              />
                            </Col>
                          </Row>
                        </Form>
                      )}
                    </Formik>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default UpdateProfile
