import { createSlice } from "@reduxjs/toolkit"
import {
  createAttribute,
  deleteAttribute,
  getAllAttributes,
  getAttributeDetails,
  getAttributesList,
  updateAttribute,
  updateAttributeStatus,
} from "store/actions/attributeAction"

const initialState = {
  loading: true,
  isLoading: false,
  perPage: 10,
  totalCount: null,
  totalPages: null,
  currentPage: null,
  attributes: [],
  attributeLists: [],
  afterSalesServiceLists: [],
  attributeDetails: {},
}
const AttributesSlice = createSlice({
  name: "attribute",
  initialState,
  reducers: {},
  extraReducers: builder => {
    // GET ALL
    builder
      .addCase(getAllAttributes.pending, state => {
        state.loading = true
      })
      .addCase(getAllAttributes.fulfilled, (state, action) => {
        state.loading = false
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { data, totalCount, totalPages, currentPage } = action.payload
          state.attributes = data
          state.totalCount = totalCount
          state.totalPages = totalPages
          state.currentPage = currentPage
        }
      })
      .addCase(getAllAttributes.rejected, (state, action) => {
        state.loading = false
      })

    // GET LIST
    builder
      .addCase(getAttributesList.pending, state => {})
      .addCase(getAttributesList.fulfilled, (state, action) => {
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { data } = action.payload
          const { type } = action.meta.arg
          if (type[0] === "after-sale-service") {
            state.afterSalesServiceLists = data
          } else {
            state.attributeLists = data
          }
        }
      })
      .addCase(getAttributesList.rejected, (state, action) => {})

    // GET DETAILS
    builder
      .addCase(getAttributeDetails.pending, state => {
        state.getAttributeDetails = {}
        state.loading = true
      })
      .addCase(getAttributeDetails.fulfilled, (state, action) => {
        state.loading = false
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { data } = action.payload
          state.attributeDetails = data
        }
      })
      .addCase(getAttributeDetails.rejected, (state, action) => {
        state.loading = false
      })

    // UPDATE STATUS
    builder
      .addCase(updateAttributeStatus.pending, state => {
        state.isLoading = true
      })
      .addCase(updateAttributeStatus.fulfilled, (state, action) => {
        state.isLoading = false
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { id, status } = action.meta.arg
          state.attributes = state.attributes.map(item =>
            item._id === id ? { ...item, status: Number(status) } : item
          )
        }
      })
      .addCase(updateAttributeStatus.rejected, (state, action) => {
        state.isLoading = false
      })

    // DELETE
    builder
      .addCase(deleteAttribute.pending, state => {
        state.isLoading = true
      })
      .addCase(deleteAttribute.fulfilled, (state, action) => {
        state.isLoading = false
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { id } = action.meta.arg
          state.attributes = state.attributes.filter(item => item._id !== id)
          if (state.totalCount !== null) {
            state.totalCount -= 1
          }
        }
      })
      .addCase(deleteAttribute.rejected, (state, action) => {
        state.isLoading = false
      })

    // CREATE
    builder
      .addCase(createAttribute.pending, state => {
        state.isLoading = true
      })
      .addCase(createAttribute.fulfilled, (state, action) => {
        state.isLoading = false
      })
      .addCase(createAttribute.rejected, (state, action) => {
        state.isLoading = false
      })

    // UPDATE
    builder
      .addCase(updateAttribute.pending, state => {
        state.isLoading = true
      })
      .addCase(updateAttribute.fulfilled, (state, action) => {
        state.isLoading = false
      })
      .addCase(updateAttribute.rejected, (state, action) => {
        state.isLoading = false
      })
  },
})

export default AttributesSlice.reducer
