import i18next from "i18next"

export const getFieldRequiredMessage = () => i18next.t("Field is required")

export const getFieldOnlyLetters = () =>
  i18next.t("Field can only contain letters")

export const getValidArabicMessage = () =>
  i18next.t("Please enter a valid Arabic name")

export const getMinOneCustomer = () =>
  i18next.t("At least one customer must be selected")

export const getMinOneOption = () =>
  i18next.t("At least one option must be selected")

export const getValidEmail = () =>
  i18next.t("Please enter a valid email address")

export const getPasswordMinSix = () =>
  i18next.t("Password must be at least 6 characters long")

export const getPasswordError = () =>
  i18next.t("Password must include one uppercase letter, one number, and one special character")

export const getMin6PasswordMessage = () =>
  i18next.t("Password must be at least 6 characters")

export const getPasswordMatchMessage = () => i18next.t("Passwords must match")

export const getOnlyNumMessage = () =>
  i18next.t("This field must contain only numbers")

export const getGreaterThanZeroMessage = () =>
  i18next.t("Field should be greater than 0")
