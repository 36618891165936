import React, { useEffect } from "react"
import {
  Badge,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Table,
} from "reactstrap"
import { useTranslation } from "react-i18next"
import BackButton from "components/Shared/BackButton"
import TopTitle from "components/Shared/TopTitle"
import DetailsInnerDiv from "components/Shared/DetailsInnerDiv"
import DetailsTitle from "components/Shared/DetailsTitle"
import { useDispatch, useSelector } from "react-redux"
import { useLocation } from "react-router-dom"
import { getNewsDetails } from "store/actions/newsAction"
import NoDataAvailable from "components/Shared/NoDataAvailable"
import { ShimmerThumbnail } from "react-shimmer-effects"

const NewsDetails = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  document.title = t("News Details | Takween")

  const dispatch = useDispatch()
  const { loading, newsDetails } = useSelector(state => state.News)

  const { search } = useLocation()
  const id = new URLSearchParams(search).get("id")

  const name = useSelector(state => state.Login.userProfile?.name) || "";


  useEffect(() => {
    const fetchDetails = () => {
      const payload = {
        id,
      }
      dispatch(getNewsDetails(payload))
    }
    fetchDetails()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <TopTitle
            title={t("News Details")}
            subTitle={t(
              `Hi, {{name}}. Welcome back to your Marketing portal!`,
              { name: name }
            )}
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <DetailsTitle title={t("News Details")} />
                  {loading ? (
                    <ShimmerThumbnail height={400} rounded className={"mb-0"} />
                  ) : Object.values(newsDetails).length === 0 ? (
                    <p className="text-center my-3">
                      <NoDataAvailable />
                    </p>
                  ) : (
                    <Row className="g_details">
                      <Col lg={4}>
                        <div className="d-flex mt-3">
                          <div>
                            <img
                              src={newsDetails.imageUrl}
                              alt=""
                              className="img-fluid rounded-3"
                              style={{ objectFit: "contain" }}
                            />
                          </div>
                        </div>
                      </Col>
                      <Col lg={8}>
                        <Row>
                          <Col md={6}>
                            <DetailsInnerDiv
                              label={t("Id")}
                              value={newsDetails._id}
                            />
                          </Col>
                          <Col md={6}>
                            <DetailsInnerDiv
                              label={t("Status")}
                              value={
                                newsDetails.status === 1
                                  ? t("Active")
                                  : t("Deactive")
                              }
                              valueClass={`${
                                newsDetails.status === 1
                                  ? "active_color"
                                  : "danger_color"
                              }`}
                            />
                          </Col>
                          <Col md={12}>
                            <DetailsInnerDiv
                              label={t("Title(English)")}
                              value={newsDetails.title}
                            />
                          </Col>
                          <Col md={12}>
                            <DetailsInnerDiv
                              label={t("Title(عربي)")}
                              value={newsDetails.titleAr}
                            />
                          </Col>
                        </Row>
                      </Col>

                      <Col md={12}>
                        <div className="details_inner_div">
                          <p>{t("Description(English)")}</p>
                          <h3 className="fs_14_500">
                            {newsDetails.description}
                          </h3>
                        </div>
                      </Col>
                      <Col md={12}>
                        <div className="details_inner_div">
                          <p>{t("Description(Arabic)")}</p>
                          <h3 className="fs_14_500">
                            {newsDetails.descriptionAr}
                          </h3>
                        </div>
                      </Col>
                    </Row>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default NewsDetails
