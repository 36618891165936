import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Field, FieldArray, Form, Formik } from "formik"
import { Col, Row } from "reactstrap"
import CustomLabel from "components/Shared/CustomLabel"
import CustomField from "components/Shared/CustomField"
import CustomErrorMessage from "components/Shared/CustomErrorMessage"
import Select from "react-select"
import { facilityAndServicesSchema } from "components/Common/validation"
import { useDispatch, useSelector } from "react-redux"
import { getAttributesList } from "store/actions/attributeAction"
import { updateProject } from "store/actions/projectsAction"
import { showAlert } from "store/reducers/alertReducer"
import { updateUnit } from "store/actions/unitsAction"
import { useNavigate } from "react-router-dom"

const initialValues = {
  facilitiesAndServices: [
    // {
    //   name: "",
    //   nameAr: "",
    //   description: "",
    //   descriptionAr: "",
    //   lists: [],
    // },
  ],
}

const FacilityAndServices = ({ actionType, id }) => {
  // actionType UNIT(For unit), PROJECT(For project)
  const [formValues, setFormValues] = useState(initialValues)
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const navigate = useNavigate()

  const dispatch = useDispatch()
  const { isLoading, projectDetails } = useSelector(state => state.Projects)
  const { isLoading: isUnitLoading, unitDetails } = useSelector(
    state => state.Units
  )

  const { attributeLists } = useSelector(state => state.Attributes)

  useEffect(() => {
    const fetchAttributeList = () => {
      const payload = {
        type: ["facilities-and-services"],
      }
      dispatch(getAttributesList(payload))
    }
    fetchAttributeList()
  }, [])

  useEffect(() => {
    const details = actionType === "PROJECT" ? projectDetails : unitDetails
    if (details) {
      const { facilitiesAndServices } = details
      if (facilitiesAndServices?.length > 0) {
        // const transformedFacilitiesAndServices = facilitiesAndServices?.map(
        //   item => ({
        //     ...item,
        //     lists: item?.lists?.map(list => ({
        //       value: list._id,
        //       label: list.name,
        //     })),
        //   })
        // )
        const transformedFacilitiesAndServices = facilitiesAndServices?.map(
          item => ({
            value: item._id,
            label: language === "sa" ? item.nameAr : item.name,
          })
        )

        setFormValues(prev => ({
          ...prev,
          facilitiesAndServices: transformedFacilitiesAndServices,
        }))
      }
    }
  }, [projectDetails, unitDetails, actionType, language])

  const handleOnSubmit = values => {
    const { facilitiesAndServices } = values

    // const updatedValues = {
    //   ...values,
    //   facilitiesAndServices: values?.facilitiesAndServices?.map(field => ({
    //     ...field,
    //     lists: field?.lists?.map(item => item.value),
    //   })),
    // }

    let data = new FormData()
    data.append("id", id)
    // Loop through each facility and service to dynamically add to FormData
    // updatedValues?.facilitiesAndServices?.forEach((facility, index) => {
    //   data.append(`facilitiesAndServices[${index}][name]`, facility.name)
    //   data.append(`facilitiesAndServices[${index}][nameAr]`, facility.nameAr)
    //   data.append(
    //     `facilitiesAndServices[${index}][description]`,
    //     facility.description
    //   )
    //   data.append(
    //     `facilitiesAndServices[${index}][descriptionAr]`,
    //     facility.descriptionAr
    //   )

    //   // Loop through each list item and append it as facilitiesAndServices[0][lists][0], facilitiesAndServices[0][lists][1], etc.
    //   facility?.lists?.forEach((listId, listIndex) => {
    //     data.append(
    //       `facilitiesAndServices[${index}][lists][${listIndex}]`,
    //       listId
    //     )
    //   })
    // })

    facilitiesAndServices.forEach((item, index) => {
      data.append(`facilitiesAndServices[${index}]`, item.value)
    })

    const updatedAction = actionType === "PROJECT" ? updateProject : updateUnit

    dispatch(updatedAction(data))
      .then(action => {
        const { statusCode, success, message } = action?.payload
        if (statusCode === 200 && success) {
          dispatch(showAlert({ text: message, type: "success" }))
        } else {
          dispatch(showAlert({ text: message, type: "error" }))
        }
      })
      .catch(error => {})
  }

  return (
    <div>
      <Formik
        initialValues={formValues}
        validationSchema={facilityAndServicesSchema}
        onSubmit={handleOnSubmit}
        enableReinitialize={true}
      >
        {({ values, errors, touched, setFieldValue }) => (
          <Form>
            <Col lg={12}>
              <div className="g_label">
                <CustomLabel
                  htmlFor={`facilitiesAndServices`}
                  label={t("Lists")}
                  isRequired={true}
                />
                <Select
                  value={values.facilitiesAndServices}
                  isMulti={true}
                  closeMenuOnSelect={false}
                  onChange={selectedValue =>
                    setFieldValue(`facilitiesAndServices`, selectedValue)
                  }
                  options={attributeLists?.map(item => ({
                    label: language === "sa" ? item.nameAr : item.name,
                    value: item._id,
                  }))}
                  placeholder={t("Select Lists")}
                  className={`select2-selection b_r_12 ${
                    touched.facilitiesAndServices &&
                    errors.facilitiesAndServices
                      ? "danger_color_border"
                      : ""
                  } `}
                />
                <CustomErrorMessage name={`facilitiesAndServices`} />
              </div>
            </Col>
            {/* <FieldArray name="facilitiesAndServices">
              {({ remove, push }) => ( */}
            <div>
              {/* {values.facilitiesAndServices.map((field, index) => (
                    <div key={index} className="primary_bg_10">
                      <Row>
                        <Col lg={6}>
                          <div className="form_field">
                            <CustomLabel
                              htmlFor={`facilitiesAndServices.${index}.name`}
                              label={t("Name(English)")}
                              isRequired={true}
                            />
                            <Field
                              name={`facilitiesAndServices.${index}.name`}
                              type={"text"}
                              id={`facilitiesAndServices.${index}.name`}
                              placeholder={t("Name(English)")}
                              className={`form-control  ${
                                errors.facilitiesAndServices?.[index]?.name &&
                                touched.facilitiesAndServices?.[index]?.name
                                  ? "is-invalid"
                                  : ""
                              } `}
                            />
                            <CustomErrorMessage
                              name={`facilitiesAndServices.${index}.name`}
                            />
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="form_field">
                            <CustomLabel
                              htmlFor={`facilitiesAndServices.${index}.nameAr`}
                              label={t("Name(Arabic)")}
                              isRequired={true}
                            />
                            <Field
                              name={`facilitiesAndServices.${index}.nameAr`}
                              type={"text"}
                              id={`facilitiesAndServices.${index}.nameAr`}
                              placeholder={t("Name(Arabic)")}
                              className={`form-control rtl ${
                                errors.facilitiesAndServices?.[index]?.name &&
                                touched.facilitiesAndServices?.[index]?.name
                                  ? "is-invalid"
                                  : ""
                              } `}
                            />
                            <CustomErrorMessage
                              name={`facilitiesAndServices.${index}.nameAr`}
                            />
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="form_field">
                            <CustomLabel
                              htmlFor={`facilitiesAndServices.${index}.description`}
                              label={t("Description(English)")}
                              isRequired={true}
                            />
                            <Field
                              as="textarea"
                              id={`facilitiesAndServices.${index}.description`}
                              name={`facilitiesAndServices.${index}.description`}
                              rows={5}
                              placeholder={t("Description(English)")}
                              className={`form-control b_r_12 ${
                                errors.facilitiesAndServices?.[index]
                                  ?.description &&
                                touched.facilitiesAndServices?.[index]
                                  ?.description
                                  ? "is-invalid"
                                  : ""
                              }`}
                            />
                            <CustomErrorMessage
                              name={`facilitiesAndServices.${index}.description`}
                            />
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="form_field">
                            <CustomLabel
                              htmlFor={`facilitiesAndServices.${index}.descriptionAr`}
                              label={t("Description(Arabic)")}
                              isRequired={true}
                            />
                            <Field
                              as="textarea"
                              id={`facilitiesAndServices.${index}.descriptionAr`}
                              name={`facilitiesAndServices.${index}.descriptionAr`}
                              rows={5}
                              placeholder={t("Description(Arabic)")}
                              className={`form-control rtl b_r_12 ${
                                errors.facilitiesAndServices?.[index]
                                  ?.descriptionAr &&
                                touched.facilitiesAndServices?.[index]
                                  ?.descriptionAr
                                  ? "is-invalid"
                                  : ""
                              }`}
                            />
                            <CustomErrorMessage
                              name={`facilitiesAndServices.${index}.descriptionAr`}
                            />
                          </div>
                        </Col>
                        <Col lg={12}>
                          <div className="g_label">
                            <CustomLabel
                              htmlFor={`facilitiesAndServices.${index}.lists`}
                              label={t("Lists")}
                              isRequired={true}
                            />
                            <Select
                              value={values.facilitiesAndServices[index].lists}
                              isMulti={true}
                              closeMenuOnSelect={false}
                              onChange={selectedValue =>
                                setFieldValue(
                                  `facilitiesAndServices.${index}.lists`,
                                  selectedValue
                                )
                              }
                              options={attributeLists?.map(item => ({
                                label: item.name,
                                value: item._id,
                              }))}
                              placeholder={t("Select Lists")}
                              className={`select2-selection b_r_12 ${
                                touched.facilitiesAndServices?.[index]?.lists &&
                                errors.facilitiesAndServices?.[index]?.lists
                                  ? "danger_color_border"
                                  : ""
                              } `}
                            />
                            <CustomErrorMessage
                              name={`facilitiesAndServices.${index}.lists`}
                            />
                          </div>
                        </Col>
                        <Col lg={12}>
                          <div className="d-flex justify-content-end mt-3">
                            {values.facilitiesAndServices.length > 1 && (
                              <button
                                type="button"
                                className="reject_btn mx-2"
                                onClick={() => remove(index)}
                              >
                                {t("Remove")}
                              </button>
                            )}
                          </div>
                        </Col>
                      </Row>
                      <hr />
                    </div>
                  ))} */}

              <div className="d-flex justify-content-end mt-3 gap-3">
                {/* <button
                      type="button"
                      className="cancel_btn"
                      onClick={() =>
                        push({
                          name: "",
                          nameAr: "",
                          description: "",
                          descriptionAr: "",
                          lists: [],
                        })
                      }
                    >
                      {t("Add More")}
                    </button> */}
                <button
                  type="button"
                  className="cancel_btn"
                  onClick={() => navigate(-1)}
                >
                  {t("Cancel")}
                </button>
                <button
                  className="primary_btn"
                  type="submit"
                  disabled={isLoading || isUnitLoading}
                >
                  {t("Update")}
                </button>
              </div>
            </div>
            {/* )}
            </FieldArray> */}
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default FacilityAndServices
