import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Field, FieldArray, Form, Formik } from "formik"
import { Col, Row } from "reactstrap"
import CustomLabel from "components/Shared/CustomLabel"
import CustomErrorMessage from "components/Shared/CustomErrorMessage"
import {
  nearbyLandmarksSchema,
  unitFeatureSchema,
} from "components/Common/validation"
import { useDispatch, useSelector } from "react-redux"
import { yearOptions } from "components/Common/constants"
import { getAttributesList } from "store/actions/attributeAction"
import { getServicesList } from "store/actions/servicesAction"
import { showAlert } from "store/reducers/alertReducer"
import { updateUnit } from "store/actions/unitsAction"
import { useNavigate } from "react-router-dom"

const initialGuarantees = [
  {
    serviceId: "",
    duration: null,
    durationUnit: "",
  },
]

const initialValues = {
  gaurantees: initialGuarantees,
  // afterSaleServices: [],
}

const Feature = ({ id }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const navigate = useNavigate()

  const [formValues, setFormValues] = useState(initialValues)
  const dispatch = useDispatch()
  const { isLoading, unitDetails } = useSelector(state => state.Units)

  const { afterSalesServiceLists } = useSelector(state => state.Attributes)
  const { servicesList } = useSelector(state => state.Services)

  useEffect(() => {
    const fetchAttributeList = () => {
      const payload = {
        type: ["after-sale-service"],
      }
      dispatch(getAttributesList(payload))
    }
    const fetchServicesList = () => {
      const payload = {
        type: ["features"],
      }
      dispatch(getServicesList(payload))
    }
    fetchAttributeList()
    fetchServicesList()
  }, [])

  useEffect(() => {
    if (unitDetails) {
      const { afterSaleServices = [], guarantees = [] } = unitDetails
      // const updatedAfterSalesServices =
      //   afterSaleServices?.map(item => item._id) || []
      const updatedGuarantees = guarantees?.map(item => ({
        serviceId: item?.serviceId?._id,
        duration: item?.duration,
        durationUnit: item?.durationUnit,
      }))

      const selectedGuarantees =
        updatedGuarantees.length > 0 ? updatedGuarantees : initialGuarantees

      setFormValues({
        gaurantees: selectedGuarantees,
        // afterSaleServices: updatedAfterSalesServices,
      })
    }
  }, [unitDetails, afterSalesServiceLists, servicesList])

  const handleOnSubmit = values => {
    const {
      gaurantees,
      // afterSaleServices
    } = values

    let data = new FormData()
    data.append("id", id)

    gaurantees?.forEach((item, index) => {
      data.append(`guarantees[${index}][serviceId]`, item.serviceId)
      data.append(`guarantees[${index}][duration]`, item.duration)
      data.append(`guarantees[${index}][durationUnit]`, item.durationUnit)
    })

    // afterSaleServices?.forEach((item, index) => {
    //   data.append(`afterSaleServices[${index}]`, item)
    // })

    dispatch(updateUnit(data))
      .then(action => {
        const { statusCode, success, message } = action?.payload
        if (statusCode === 200 && success) {
          dispatch(showAlert({ text: message, type: "success" }))
        } else {
          dispatch(showAlert({ text: message, type: "error" }))
        }
      })
      .catch(error => {})
  }
  return (
    <div>
      <Formik
        initialValues={formValues}
        validationSchema={unitFeatureSchema}
        onSubmit={handleOnSubmit}
        enableReinitialize={true}
      >
        {({ values, errors, touched, setFieldValue }) => (
          <Form>
            <FieldArray name="gaurantees">
              {({ remove, push }) => (
                <div>
                  <div className="g_between">
                    <h2 className="fs_24">{t("Guarantees")}</h2>
                    <button
                      type="button"
                      className="cancel_btn mx-2"
                      onClick={() =>
                        push({
                          name: "",
                          nameAr: "",
                          duration: null,
                          durationUnit: "",
                        })
                      }
                    >
                      {t("Add More")}
                    </button>
                  </div>
                  {values?.gaurantees?.map((field, index) => (
                    <div key={index} className="primary_bg_10">
                      <Row>
                        <Col lg={6}>
                          <div className="form_field">
                            <CustomLabel
                              htmlFor={`gaurantees.${index}.serviceId`}
                              label={t("Service")}
                              isRequired={true}
                            />
                            <div className="select_wrapper">
                              <Field
                                as="select"
                                name={`gaurantees.${index}.serviceId`}
                                className={`form-control  ${
                                  errors.gaurantees?.[index]?.serviceId &&
                                  touched.gaurantees?.[index]?.serviceId
                                    ? "is-invalid"
                                    : ""
                                } `}
                                onChange={e => {
                                  const selectedValue = e.target.value
                                  setFieldValue(
                                    `gaurantees.${index}.serviceId`,
                                    selectedValue
                                  )
                                }}
                              >
                                <option hidden>{t("Service")}</option>
                                {servicesList?.map(item => (
                                  <option key={item._id} value={item._id}>
                                    {language === "sa"
                                      ? item.nameAr
                                      : item.name}
                                  </option>
                                ))}
                              </Field>
                            </div>
                            <CustomErrorMessage
                              name={`gaurantees.${index}.serviceId`}
                            />
                          </div>
                        </Col>

                        <Col lg={6}>
                          <div className="form_field">
                            <CustomLabel
                              htmlFor={`gaurantees.${index}.duration`}
                              label={t("Duration")}
                              isRequired={true}
                            />

                            <Field
                              name={`gaurantees.${index}.duration`}
                              type={"number"}
                              id={`gaurantees.${index}.duration`}
                              placeholder={t("Duration")}
                              className={`form-control  ${
                                errors.gaurantees?.[index]?.duration &&
                                touched.gaurantees?.[index]?.duration
                                  ? "is-invalid"
                                  : ""
                              } `}
                            />
                            <CustomErrorMessage
                              name={`gaurantees.${index}.duration`}
                            />
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="form_field">
                            <CustomLabel
                              htmlFor={`gaurantees.${index}.durationUnit`}
                              label={t("Duration Unit")}
                              isRequired={true}
                            />
                            <div className="select_wrapper">
                              <Field
                                as="select"
                                name={`gaurantees.${index}.durationUnit`} // Adjust name to match the specific field in the array
                                className={`form-control b_r_12 ${
                                  errors.gaurantees?.[index]?.durationUnit &&
                                  touched.gaurantees?.[index]?.durationUnit
                                    ? "is-invalid"
                                    : ""
                                }`}
                                onChange={e => {
                                  const selectedValue = e.target.value
                                  setFieldValue(
                                    `gaurantees.${index}.durationUnit`,
                                    selectedValue
                                  )
                                }}
                              >
                                <option hidden>{t("Duration Unit")}</option>
                                {yearOptions?.map(item => (
                                  <option key={item.value} value={item.value}>
                                    {t(item.label)}
                                  </option>
                                ))}
                              </Field>
                            </div>
                            <CustomErrorMessage
                              name={`gaurantees.${index}.durationUnit`}
                            />
                          </div>
                        </Col>
                        <Col lg={12}>
                          <div className="d-flex justify-content-end mt-3">
                            {values.gaurantees.length > 1 && (
                              <button
                                type="button"
                                className="reject_btn mx-2"
                                onClick={() => remove(index)}
                              >
                                {t("Remove")}
                              </button>
                            )}
                          </div>
                        </Col>
                      </Row>
                      <hr />
                    </div>
                  ))}
                </div>
              )}
            </FieldArray>
            {/* <Row>
              <h2 className="fs_24">{t("After Sales Service")}</h2>
              {afterSalesServiceLists?.map((item, index) => (
                <Col lg={4} key={item._id}>
                  <div className="g_label my-3 d-flex">
                    <input
                      type="checkbox"
                      className="form-check-input me-2"
                      checked={values.afterSaleServices.includes(item._id)}
                      onClick={e => {
                        if (!e.target.checked) {
                          // Add the attribute ID if not checked
                          setFieldValue("afterSaleServices", [
                            ...values.afterSaleServices,
                            item._id,
                          ])
                        } else {
                          // Remove the attribute ID if checked
                          setFieldValue(
                            "afterSaleServices",
                            values.afterSaleServices.filter(
                              attrId => attrId !== item._id
                            )
                          )
                        }
                      }}
                    />
                    <CustomLabel htmlFor={``} label={item.name} />
                  </div>
                </Col>
              ))}
            </Row> */}
            <div className="d-flex justify-content-end mt-3 gap-3">
              <button
                type="button"
                className="cancel_btn mx-2"
                onClick={() => navigate(-1)}
              >
                {t("Cancel")}
              </button>
              <button
                className="primary_btn"
                type="submit"
                disabled={isLoading}
              >
                {t("Update")}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default Feature
