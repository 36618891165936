import React from "react"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap"
import { useTranslation } from "react-i18next"
import { ErrorMessage, Field, Form, Formik } from "formik"
import Select from "react-select"
import { pushNotificationSchema } from "components/Common/validation"
import { useNavigate } from "react-router-dom"
import TopTitle from "components/Shared/TopTitle"
import CustomLabel from "components/Shared/CustomLabel"
import CustomErrorMessage from "components/Shared/CustomErrorMessage"
import FormButtons from "components/Shared/FormButtons"
import { useDispatch, useSelector } from "react-redux"
import { getCustomersList } from "store/actions/customersAction"
import Switch from "components/Shared/Switch"
import { DatePicker } from "rsuite"
import "rsuite/dist/rsuite.min.css"
import { showAlert } from "store/reducers/alertReducer"
import { pushNotificationBroadCast } from "store/actions/notificationsActions"

const initialValues = {
  type: "all",
  title: "",
  titleAr: "",
  body: "",
  bodyAr: "",
  customers: [],
  isScheduled: false,
  scheduledTime: "",
}

const PushNotifications = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  document.title = t("Notifications | Takween")
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const name = useSelector(state => state.Login.userProfile?.name) || ""
  const { customersList } = useSelector(state => state.Customers)
  const { isLoading } = useSelector(state => state.Notifications)

  const handleOnSubmit = (values, { resetForm }) => {
    const {
      type,
      title,
      titleAr,
      body,
      bodyAr,
      customers,
      isScheduled,
      scheduledTime,
    } = values

    const updatedCustomers =
      type === "specific-customers"
        ? customers?.map(item => item.value) || []
        : []

    const payload = {
      type: type, //all , specific-customers
      title: {
        en: title,
        ar: titleAr,
      },
      body: {
        en: body,
        ar: bodyAr,
      },
      sound: "default",
      isScheduled: isScheduled,
      scheduledTime: scheduledTime,
      customers: updatedCustomers,
      data: {},
    }

    dispatch(pushNotificationBroadCast(payload))
      .then(action => {
        const { statusCode, success, message } = action?.payload
        if (statusCode === 200 && success) {
          dispatch(showAlert({ text: message, type: "success" }))
          resetForm()
        } else {
          dispatch(showAlert({ text: message, type: "error" }))
        }
      })
      .catch(error => {})
  }

  const disablePastDates = date => {
    const today = new Date()
    today.setHours(0, 0, 0, 0)
    return date < today
  }

  const fetchCustomersList = value => {
    const payload = {
      keyword: value,
    }
    dispatch(getCustomersList(payload))
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <TopTitle
            title={t("Notification Control")}
            subTitle={t(
              `Hi, {{name}}. Welcome back to your Marketing portal!`,
              { name: name }
            )}
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <h2 className="fs_18_700">{t("Push Notifications")}</h2>
                  <Formik
                    initialValues={initialValues}
                    validationSchema={pushNotificationSchema}
                    onSubmit={handleOnSubmit}
                  >
                    {({ values, errors, touched, setFieldValue }) => (
                      <Form>
                        <Row>
                          <Col lg={6}>
                            <div className="form_field">
                              <CustomLabel htmlFor="" label={t("English")} />
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="form_field">
                              <CustomLabel htmlFor="" label={t("Arabic")} />
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="form_field">
                              <CustomLabel
                                htmlFor="title"
                                label={t("Title")}
                                isRequired={true}
                              />
                              <Field
                                name="title"
                                type="text"
                                id="title"
                                placeholder={t("Title")}
                                className={`form-control ${
                                  touched.title && errors.title
                                    ? "is-invalid"
                                    : ""
                                }`}
                              />
                              <CustomErrorMessage name={"title"} />
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="form_field">
                              <CustomLabel
                                htmlFor="titleAr"
                                label={t("Title")}
                                isRequired={true}
                                isArabic={true}
                              />
                              <Field
                                name="titleAr"
                                type="text"
                                id="titleAr"
                                placeholder={t("Title")}
                                className={`rtl form-control ${
                                  touched.titleAr && errors.titleAr
                                    ? "is-invalid"
                                    : ""
                                }`}
                              />
                              <CustomErrorMessage name={"titleAr"} />
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="form_field">
                              <CustomLabel
                                htmlFor="body"
                                label={t("Message(English)")}
                                isRequired={true}
                              />
                              <Field
                                as="textarea"
                                id="body"
                                name="body"
                                rows={4}
                                placeholder={t("Message(English)")}
                                className={`form-control ${
                                  touched.body && errors.body
                                    ? "is-invalid"
                                    : ""
                                }`}
                              />
                              <CustomErrorMessage name={"body"} />
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="form_field">
                              <CustomLabel
                                htmlFor="bodyAr"
                                label={t("Message(عربي)")}
                                isRequired={true}
                              />
                              <Field
                                as="textarea"
                                id="bodyAr"
                                name="bodyAr"
                                rows={4}
                                placeholder={t("Message(عربي)")}
                                className={`rtl form-control ${
                                  touched.bodyAr && errors.bodyAr
                                    ? "is-invalid"
                                    : ""
                                }`}
                              />
                              <CustomErrorMessage name={"bodyAr"} />
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="mt-3 g_label">
                              <CustomLabel
                                htmlFor=""
                                label={t("Select Type")}
                              />
                              <div className="d-flex ">
                                <div className="form-check mx-2">
                                  <Field
                                    className="form-check-input"
                                    type="radio"
                                    name="type"
                                    id="all"
                                    value="all"
                                    onClick={() => {
                                      setFieldValue("type", "all")
                                    }}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="all"
                                  >
                                    {t("All")}
                                  </label>
                                </div>
                                <div className="form-check mx-2">
                                  <Field
                                    className="form-check-input"
                                    type="radio"
                                    name="type"
                                    id="specific-customers"
                                    value="specific-customers"
                                    onClick={() => {
                                      setFieldValue(
                                        "type",
                                        "specific-customers"
                                      )
                                    }}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="specific-customers"
                                  >
                                    {t("Specific Customers")}
                                  </label>
                                </div>
                              </div>
                            </div>
                          </Col>
                          {values.type === "specific-customers" && (
                            <Col md={6} className="g_label">
                              <>
                                <CustomLabel
                                  htmlFor=""
                                  label={t("Please Type To Search Customer")}
                                  isRequired={true}
                                />
                                <Select
                                  value={values.customers}
                                  isMulti={true}
                                  closeMenuOnSelect={false}
                                  onChange={selectedValue => {
                                    setFieldValue("customers", selectedValue)
                                  }}
                                  options={customersList?.map(item => ({
                                    label: `${item.name || ""} ${
                                      item.phone || ""
                                    }`,
                                    value: item._id,
                                  }))}
                                  onInputChange={value =>
                                    fetchCustomersList(value)
                                  }
                                  placeholder={t(
                                    "Please Type To Search Customer"
                                  )}
                                  className={`select2-selection b_r_12 ${
                                    touched.customers && errors.customers
                                      ? "danger_color_border"
                                      : ""
                                  } `}
                                />
                                <CustomErrorMessage name={"customers"} />
                              </>
                            </Col>
                          )}

                          <Col md={6}>
                            <div className="g_label">
                              <CustomLabel
                                htmlFor=""
                                label={t("Scheduled Notification")}
                              />
                              <Switch
                                isChecked={values.isScheduled}
                                onClickHandler={() => {
                                  setFieldValue(
                                    "isScheduled",
                                    !values.isScheduled
                                  )
                                }}
                              />
                            </div>
                          </Col>

                          {values.isScheduled && (
                            <Col md={6}>
                              <div className="form_field full_rs_picker">
                                <CustomLabel
                                  htmlFor=""
                                  label={t("Schedule Date & Time")}
                                  isRequired={true}
                                />

                                <DatePicker
                                  showTime={{ format: "HH:mm" }}
                                  format="yyyy-MM-dd HH:mm"
                                  onChange={value => {
                                    setFieldValue("scheduledTime", value)
                                  }}
                                  className={`${
                                    touched.scheduledTime &&
                                    errors.scheduledTime
                                      ? "danger_color_border"
                                      : ""
                                  } custom-date-picker`}
                                  placement="autoVerticalStart"
                                  value={values.scheduledTime}
                                  placeholder=" 2024-07-16 14:30"
                                  disabledDate={disablePastDates}
                                />
                                <CustomErrorMessage name={"scheduledTime"} />
                              </div>
                            </Col>
                          )}

                          <Col lg={12}>
                            <FormButtons
                              buttonText={t("Send")}
                              isLoading={isLoading}
                            />
                          </Col>
                        </Row>
                      </Form>
                    )}
                  </Formik>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default PushNotifications
