import {
  paymentStatusOptions,
  transactionsTypes,
} from "components/Common/constants"
import Paginations from "components/Common/Pagination"
import FormateDate from "components/Shared/FormateDate"
import NoDataAvailable from "components/Shared/NoDataAvailable"
import Spinners from "components/Shared/Spinners"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { Table } from "reactstrap"
import { getAllTransactions } from "store/actions/transactionsAction"

const CustomerPaymentHistory = ({ id, activeTab }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const {
    loading,
    transactions,
    totalCount,
    totalPages,
    currentPage,
    perPage,
  } = useSelector(state => state.Transactions)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    activeTab === 3 && fetchAllTransactions()
  }, [activeTab])

  const fetchAllTransactions = page => {
    const payload = {
      perPage: perPage,
      pageNo: page || 1,
      status: -1,
      orderBy: 1,
      keyword: "",
      dateRange: {},
      unit: [],
      customer: [id],
      paymentMethod: [], //"sadad", "applepay", "cheque", "bank"
      paymentType: [], //1: Down payment,2:Full payment,3: Tax and 4: Installment for rent
      transType: [],
    }

    dispatch(getAllTransactions(payload))
  }

  const onPageChange = page => {
    fetchAllTransactions(page)
  }

  return (
    <div>
      {loading ? (
        <Spinners />
      ) : transactions?.length === 0 ? (
        <NoDataAvailable />
      ) : (
        <Table className="table mb-0 table_corner_radius">
          <thead className="full_th">
            <tr>
              <th>{t("#")}</th>
              {/* <th>{t("Customer Name")}</th>
              <th>{t("Customer Phone")}</th> */}
              <th>{t("Unit Name")}</th>
              <th>{t("Transaction Id")}</th>
              <th>{t("Amount")}</th>
              <th>{t("Payment Method")}</th>
              <th>{t("Transaction Type")}</th>
              <th>{t("Payment Status")}</th>
              <th>{t("Created At")}</th>
            </tr>
          </thead>
          <tbody>
            {transactions.map((payment, index) => (
              <tr
                key={payment._id}
                className="cursor-pointer"
                onClick={() => {
                  navigate(`/payment-history/details?id=${payment._id}`)
                }}
              >
                <th scope="row">{(currentPage - 1) * perPage + index + 1}</th>
                {/* <td>{payment?.userData?.name || "-"}</td>
                <td>{payment?.userData?.phone || "-"}</td> */}
                <td>{payment?.unitData?.name || "-"}</td>
                <td>{payment.transactionId}</td>
                <td>{`${payment?.amount ?? "-"} ${payment?.currency}`}</td>
                <td>{payment.paymentMethod}</td>
                <td>
                  {t(
                    transactionsTypes.find(
                      item => item.value === payment.transType
                    )?.label
                  ) || "-"}
                </td>
                <td>
                  <p
                    className={`${
                      payment.paymentStatus === 0
                        ? "sold_status"
                        : payment.paymentStatus === 1
                        ? "rended_status"
                        : payment.paymentStatus === 2
                        ? "reject_status"
                        : ""
                    } status_tag`}
                  >
                    {t(
                      paymentStatusOptions.find(
                        item => item.value === payment.paymentStatus
                      )?.label
                    ) || "-"}
                  </p>
                </td>
                <td>{FormateDate(payment.createdAt)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
      {!loading && transactions.length > 0 && (
        <>
          <div className="px-3 pb-3 pt-2">
            <Paginations
              currentPage={currentPage}
              totalPages={totalPages}
              totalCount={totalCount}
              onPageChange={onPageChange}
            />
          </div>
        </>
      )}
    </div>
  )
}

export default CustomerPaymentHistory
