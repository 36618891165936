import { handle_4_3_FileUpload } from "components/Common/Utils"
import { createServiceItemSchema } from "components/Common/validation"
import CustomErrorMessage from "components/Shared/CustomErrorMessage"
import CustomField from "components/Shared/CustomField"
import CustomLabel from "components/Shared/CustomLabel"
import FileUpload from "components/Shared/FileUpload"
import FormButtons from "components/Shared/FormButtons"
import Spinners from "components/Shared/Spinners"
import TopTitle from "components/Shared/TopTitle"
import { Field, Form, Formik } from "formik"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useSearchParams } from "react-router-dom"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import {
  createServiceItem,
  getServiceItemDetails,
  updateServiceItem,
} from "store/actions/serviceItemsAction"
import { getServicesList } from "store/actions/servicesAction"
import { showAlert } from "store/reducers/alertReducer"

const createServiceItemsValues = {
  name: "",
  nameAr: "",
  serviceId: "",
  description: "",
  descriptionAr: "",
  price: "",
  image: "",
}

const CreateServiceItem = ({ mode }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  document.title =
    mode === "create"
      ? t("Create Service Item | Takween")
      : t("Update Service Item | Takween")

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()
  const id = searchParams.get("id")

  const { loading, isLoading, serviceItemDetails } = useSelector(
    state => state.ServiceItems
  )

  const name = useSelector(state => state.Login.userProfile?.name) || ""

  const { servicesList } = useSelector(state => state.Services)

  const [formValues, setFormValues] = useState(createServiceItemsValues)

  useEffect(() => {
    const fetchDetails = () => {
      const payload = {
        id,
      }
      dispatch(getServiceItemDetails(payload))
    }
    mode === "update" && fetchDetails()
  }, [])

  useEffect(() => {
    if (serviceItemDetails && mode === "update") {
      const {
        name,
        nameAr,
        description,
        descriptionAr,
        price,
        imageUrl,
        serviceId,
      } = serviceItemDetails
      setFormValues({
        name,
        nameAr,
        description,
        descriptionAr,
        serviceId: serviceId?._id,
        price,
        image: imageUrl,
      })
    }
  }, [mode, serviceItemDetails, servicesList])

  const handleOnSubmit = values => {
    const {
      name,
      nameAr,
      serviceId,
      description,
      descriptionAr,
      price,
      image,
    } = values

    let data = new FormData()
    data.append("name", name)
    data.append("nameAr", nameAr)
    data.append("serviceId", serviceId)
    data.append("description", description)
    data.append("descriptionAr", descriptionAr)
    data.append("price", price)
    data.append("currency", "SAR") // STATIC FOR NOW
    data.append("image", image)

    if (mode === "create") {
      dispatch(createServiceItem(data))
        .then(action => {
          const { statusCode, success, message } = action?.payload
          if (statusCode === 200 && success) {
            dispatch(showAlert({ text: message, type: "success" }))
            navigate("/service-items")
          } else {
            dispatch(showAlert({ text: message, type: "error" }))
          }
        })
        .catch(error => {})
    } else if (mode === "update") {
      data.append("id", id)
      dispatch(updateServiceItem(data))
        .then(action => {
          const { statusCode, success, message } = action?.payload
          if (statusCode === 200 && success) {
            dispatch(showAlert({ text: message, type: "success" }))
            navigate("/service-items")
          } else {
            dispatch(showAlert({ text: message, type: "error" }))
          }
        })
        .catch(error => {})
    }
  }

  useEffect(() => {
    fetchServicesList()
  }, [])

  const fetchServicesList = () => {
    const payload = {
      type: ["features"],
    }
    dispatch(getServicesList(payload))
  }

  return (
    <React.Fragment>
      <div className="page-content">
        {mode === "update" && loading ? (
          <Spinners />
        ) : (
          <Container fluid>
            <TopTitle
              title={t("Service Items")}
              subTitle={t(
                `Hi, {{name}}. Welcome back to your Marketing portal!`,
                { name: name }
              )}
            />
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <h2 className="fs_18_700">
                      {mode === "create"
                        ? t("Create Service Item")
                        : t("Update Service Item")}
                    </h2>
                    <Formik
                      initialValues={formValues}
                      enableReinitialize={true}
                      validationSchema={createServiceItemSchema}
                      onSubmit={handleOnSubmit}
                    >
                      {({ values, errors, touched, setFieldValue }) => (
                        <Form>
                          <Row>
                            <Col lg={6}>
                              <div className="form_field">
                                <CustomLabel
                                  htmlFor="name"
                                  label={t("Name(English)")}
                                  isRequired={true}
                                />
                                <CustomField
                                  name="name"
                                  type="text"
                                  placeholder={t("Name(English)")}
                                  touched={touched}
                                  errors={errors}
                                />
                              </div>
                            </Col>
                            <Col lg={6}>
                              <div className="form_field">
                                <CustomLabel
                                  htmlFor="nameAr"
                                  label={t("Name(Arabic)")}
                                  isRequired={true}
                                />
                                <CustomField
                                  name="nameAr"
                                  type="text"
                                  isArabic={true}
                                  placeholder={t("Name(Arabic)")}
                                  touched={touched}
                                  errors={errors}
                                />
                              </div>
                            </Col>
                            <Col lg={6}>
                              <div className="form_field">
                                <CustomLabel
                                  htmlFor="description"
                                  label={t("Description(English)")}
                                  isRequired={true}
                                />
                                <Field
                                  as="textarea"
                                  id="description"
                                  name="description"
                                  rows={5}
                                  placeholder={t("Description(English)")}
                                  className={`form-control b_r_12 ${
                                    errors.description && touched.description
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                />
                                <CustomErrorMessage name={"description"} />
                              </div>
                            </Col>
                            <Col lg={6}>
                              <div className="form_field">
                                <CustomLabel
                                  htmlFor="descriptionAr"
                                  label={t("Description(عربي)")}
                                  isRequired={true}
                                />
                                <Field
                                  as="textarea"
                                  id="descriptionAr"
                                  name="descriptionAr"
                                  rows={5}
                                  isArabic={true}
                                  placeholder={t("Description(عربي)")}
                                  className={`form-control b_r_12 ${
                                    errors.descriptionAr &&
                                    touched.descriptionAr
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                />
                                <CustomErrorMessage name={"descriptionAr"} />
                              </div>
                            </Col>
                            <Col lg={6}>
                              <div className="form_field">
                                <CustomLabel
                                  htmlFor="price"
                                  label={t("Price")}
                                  isRequired={true}
                                />
                                <CustomField
                                  name="price"
                                  type="text"
                                  placeholder={t("Price")}
                                  touched={touched}
                                  errors={errors}
                                />
                              </div>
                            </Col>
                            <Col lg={6}>
                              <div className="form_field">
                                <CustomLabel
                                  htmlFor="serviceId"
                                  label={t("Select Service")}
                                  isRequired={true}
                                />
                                <div className="select_wrapper">
                                  <Field
                                    as="select"
                                    name="serviceId"
                                    className={`form-control b_r_12 ${
                                      errors.serviceId && touched.serviceId
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    onChange={e => {
                                      const selectedValue = e.target.value
                                      setFieldValue("serviceId", selectedValue)
                                    }}
                                  >
                                    <option hidden>
                                      {t("Select Service")}
                                    </option>
                                    {servicesList?.map(item => (
                                      <option key={item._id} value={item._id}>
                                        {item.name}
                                      </option>
                                    ))}
                                  </Field>
                                </div>
                                <CustomErrorMessage name={"serviceId"} />
                              </div>
                            </Col>
                            <Col lg={6}>
                              <div className="form_field">
                                <CustomLabel
                                  htmlFor="image"
                                  label={t("Upload Image")}
                                  isRequired={true}
                                />
                                <FileUpload
                                  name="image"
                                  value={values.image}
                                  touched={touched.image}
                                  error={errors.image}
                                  labelText={t("Upload Image")}
                                  setFieldValue={setFieldValue}
                                  handleFileUpload={event =>
                                    handle_4_3_FileUpload(
                                      event,
                                      setFieldValue,
                                      "image"
                                    )
                                  }
                                />
                                <CustomErrorMessage name={"image"} />
                                <p className="fs_14_500 opacity-75">
                                  {t(
                                    "Recommended Image Size: Rectangle (4:3 ratio, e.g., 800*600 px)"
                                  )}
                                </p>
                              </div>
                            </Col>
                            <Col lg={12}>
                              <FormButtons
                                buttonText={
                                  mode === "create" ? t("Create") : t("Update")
                                }
                                isLoading={isLoading}
                              />
                            </Col>
                          </Row>
                        </Form>
                      )}
                    </Formik>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        )}
      </div>
    </React.Fragment>
  )
}

export default CreateServiceItem
