import React from "react"
import { useTranslation } from "react-i18next"
import { Card, CardBody, Col, Row } from "reactstrap"
import PushNotification from "assets/images/icons/marketing/notification.svg"
import SMS from "assets/images/icons/marketing/sms.svg"
import Banner from "assets/images/icons/marketing/banner.svg"
import AppContent from "assets/images/icons/marketing/appContent.svg"
import { Link } from "react-router-dom"

const MarketingDashboardTopBar = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  const marketingDashboardTopBar = [
    {
      id: 1,
      imgUrl: PushNotification,
      text: t("Push Notifications"),
      redirectLink: "/push-notifications",
    },
    {
      id: 1,
      imgUrl: SMS,
      text: t("SMS Notifications"),
      redirectLink: "/sms-notifications",
    },
    {
      id: 2,
      imgUrl: Banner,
      text: t("Manage Banners"),
      redirectLink: "/banners",
    },
    {
      id: 3,
      imgUrl: AppContent,
      text: t("Mobile App Content"),
      redirectLink: "/app-look-and-feel",
    },
  ]

  return (
    <Card className="dashboard_top_bar">
      <CardBody>
        <Row>
          <h2>{t("Team")}</h2>
          <p>{t("Marketing Team")}</p>
          {marketingDashboardTopBar &&
            marketingDashboardTopBar.map(item => (
              <Col lg={3} key={item.id}>
                <Link to={item.redirectLink}>
                  <div className="inner_section">
                    <img src={item.imgUrl} alt="unit icon" />
                    <h3>{t(item.text)}</h3>
                  </div>
                </Link>
              </Col>
            ))}
        </Row>
      </CardBody>
    </Card>
  )
}

export default MarketingDashboardTopBar
