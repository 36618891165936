import React, { useEffect, useRef, useState } from "react"
import { Card, Col, Container, Modal, Row, Table } from "reactstrap"
import { useTranslation } from "react-i18next"
import { useLocation, useNavigate } from "react-router-dom"
import useFilters from "components/Shared/useFilters"
import Filters from "components/Shared/Filters"
import { ascDescOptions, paymentStatus } from "components/Common/constants"
import Title from "components/Shared/Title"
import NoDataAvailable from "components/Shared/NoDataAvailable"
import Paginations from "components/Common/Pagination"
import DetailsInnerDiv from "components/Shared/DetailsInnerDiv"
import { useDispatch, useSelector } from "react-redux"
import {
  approveEdaatPayment,
  cancelBooking,
  getAllBuyingPayments,
  getBuyingPaymentDetails,
  holdUnHoldPayment,
} from "store/actions/buyingPaymentsAction"
import {
  DownloadCSV,
  DownloadXLSX,
  getDateRange,
} from "components/Common/Utils"
import Spinners from "components/Shared/Spinners"
import ViewIcon from "assets/images/icons/view.svg"
import { showAlert } from "store/reducers/alertReducer"
import IsLoading from "components/Shared/IsLoading"
import FormateDate from "components/Shared/FormateDate"
import DeleteModal from "components/Common/DeleteModal"
import { EXPORT_DATA } from "helpers/url_helper"
import { exportData } from "helpers/api_helper"
import { getUnitsList } from "store/actions/unitsAction"
import { Form, Formik } from "formik"
import { cancelBookingSchema } from "components/Common/validation"
import CustomLabel from "components/Shared/CustomLabel"
import CustomField from "components/Shared/CustomField"

const DownPayments = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  document.title = t("Down Payments | Takween")

  const dispatch = useDispatch()

  const { search } = useLocation()
  const pageNumber = Number(new URLSearchParams(search).get("page")) || 1

  const {
    loading,
    isLoading,
    isGettingDetails,
    buyingPayments,
    buyingPaymentDetails,
    totalCount,
    totalPages,
    currentPage,
    perPage,
  } = useSelector(state => state.BuyingPayments)

  const { unitsList } = useSelector(state => state.Units)

  const [
    filters,
    keyword,
    setKeyword,
    dateRange,
    setDateRange,
    flatpickrRef,
    selectedId,
    setSelectedId,
    itemIdToDelete,
    setItemIdToDelete,
    showDeleteModal,
    setShowDeleteModal,
    isApiCalled,
    setIsApiCalled,
    handleFilterChange,
    resetFilters,
  ] = useFilters({
    orderBy: 2,
    unit: [],
    status: -1,
  })

  const [modal, setModal] = useState(false)
  const [cancelModal, setCancelModal] = useState(false)
  const [isHold, setIsHold] = useState(null)

  useEffect(() => {
    fetchAllBuyingPayments()
    fetchUnitsList()
  }, [])

  const fetchAllBuyingPayments = page => {
    const { orderBy, unit, status } = filters
    const updatedUnits = unit?.map(item => item.value) || []

    const payload = {
      perPage,
      pageNo: page || pageNumber,
      orderBy,
      status: Number(status),
      unit: updatedUnits,
      customer: [], // STATIC
      paymentType: [1], //1: Down payment,2:Full payment,3: Payment Contract and 4: Delivery Letter 5: Water/Electricity Bill 6: Tax Exemption 7: Deed
      keyword,
      dateRange: getDateRange(dateRange),
    }
    dispatch(getAllBuyingPayments(payload))
    setIsApiCalled(true)
  }

  const fetchUnitsList = () => {
    const payload = {
      project: [],
    }
    dispatch(getUnitsList(payload))
  }

  const additionOptions = [
    {
      name: "status",
      type: "select",
      options: paymentStatus.map(item => ({
        label: item.label,
        value: item.value,
      })),
      placeholder: t("Status"),
    },
    {
      name: "unit",
      type: "multiSelect",
      options: unitsList.map(item => ({
        label: language === "sa" ? item.nameAr : item.name,
        value: item._id,
      })),
      placeholder: t("Select Units"),
    },
  ]

  const filterOptions = [...ascDescOptions, ...additionOptions]

  useEffect(() => {
    modal && fetchPaymentDetails()
  }, [modal])

  const fetchPaymentDetails = () => {
    const payload = {
      id: selectedId,
    }
    dispatch(getBuyingPaymentDetails(payload))
  }

  useEffect(() => {
    if (isApiCalled) {
      handleSearch()
    }
  }, [keyword, filters])

  useEffect(() => {
    if (isApiCalled) {
      if (dateRange.min && dateRange.max) handleSearch()
    }
  }, [dateRange])

  const handleSearch = () => {
    fetchAllBuyingPayments(1)
  }

  const onPageChange = page => {
    fetchAllBuyingPayments(page)
  }

  const toggle = () => {
    modal && setSelectedId(null)
    setModal(!modal)
  }

  const handleHoldUnHold = () => {
    const payload = {
      id: selectedId,
      hold: isHold,
    }
    dispatch(holdUnHoldPayment(payload))
      .then(action => {
        const { statusCode, success, message } = action?.payload
        if (statusCode === 200 && success) {
          dispatch(showAlert({ text: message, type: "success" }))
          setSelectedId(null)
        } else {
          dispatch(showAlert({ text: message, type: "error" }))
        }
      })
      .catch(error => {})
  }

  const getExportFilters = () => {
    const { unit, status } = filters
    const updatedUnits = unit?.map(item => item.value) || []

    return {
      status: status,
      unit: updatedUnits,
      customer: [],
      paymentType: [1],
    }
  }

  const handleDownloadCSV = async () => {
    const payload = {
      type: "BuyingDetails",
      exportType: "csv",
      filter: getExportFilters(),
    }

    const response = await exportData(EXPORT_DATA, payload)
    if (response) {
      const csvData = response
      DownloadCSV(csvData, t("Down Payments"))
    }
  }

  const handleDownloadXLSX = async () => {
    const payload = {
      type: "BuyingDetails",
      exportType: "xlsx",
      filter: getExportFilters(),
    }

    const response = await exportData(EXPORT_DATA, payload)
    if (response) {
      const csvData = response
      DownloadXLSX(csvData, t("Down Payments"))
    }
  }

  const handleEdaatPaymentApprove = bookingId => {
    setSelectedId(bookingId)
    const payload = {
      id: bookingId,
    }

    const type = 1
    dispatch(approveEdaatPayment({ payload, type }))
      .then(action => {
        const { statusCode, success, message } = action?.payload
        if (statusCode === 200 && success) {
          dispatch(showAlert({ text: message, type: "success" }))
          setSelectedId(null)
        } else {
          dispatch(showAlert({ text: message, type: "error" }))
          setSelectedId(null)
        }
      })
      .catch(error => {
        setSelectedId(null)
      })
  }

  const toggleCancelModal = () => {
    cancelModal && setSelectedId(null)
    setCancelModal(!cancelModal)
  }

  const handleCancelBooking = values => {
    const { note } = values
    const payload = {
      id: selectedId,
      note,
    }
    dispatch(cancelBooking(payload))
      .then(action => {
        const { statusCode, success, message } = action?.payload
        if (statusCode === 200 && success) {
          dispatch(showAlert({ text: message, type: "success" }))
          toggleCancelModal()
        } else {
          dispatch(showAlert({ text: message, type: "error" }))
        }
      })
      .catch(error => {})
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg={12}>
              <Card>
                <Title
                  title={t("Down Payments")}
                  isExport={true}
                  handleDownloadCSV={handleDownloadCSV}
                  handleDownloadXLSX={handleDownloadXLSX}
                />
                <Filters
                  isGlobalFilter={true}
                  SearchPlaceholder={t("Search")}
                  keyword={keyword}
                  setKeyword={setKeyword}
                  filters={filterOptions}
                  selectedFilters={filters}
                  handleFilterChange={handleFilterChange}
                  handleReset={() => resetFilters()}
                  isRangeDate={true}
                  flatpickrRef={flatpickrRef}
                  dateRange={dateRange}
                  setDateRange={setDateRange}
                  isResetButton={true}
                  colSize={4}
                />
                {loading ? (
                  <Spinners />
                ) : buyingPayments.length === 0 ? (
                  <NoDataAvailable />
                ) : (
                  <div className="table-responsive">
                    <Table className="table mb-0">
                      <thead className="full_th">
                        <tr>
                          <th>{t("#")}</th>
                          <th>{t("First Name")}</th>
                          <th>{t("Last Name")}</th>
                          <th>{t("Unit Name")}</th>
                          <th>{t("Booking Id")}</th>
                          <th>{t("Down Payment")}</th>
                          <th>{t("Remaining  Payment")}</th>
                          <th>{t("Payment Method")}</th>
                          <th>{t("Payment Status")}</th>
                          <th>{t("Action")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {buyingPayments.map((item, index) => (
                          <tr key={item._id}>
                            <th scope="row">
                              {(currentPage - 1) * perPage + index + 1}
                            </th>
                            <td>{item?.buyerDetails?.firstName}</td>
                            <td>{item?.buyerDetails?.lastName}</td>
                            <td>
                              {language === "sa"
                                ? item?.unitData?.nameAr
                                : item?.unitData?.name || "-"}
                            </td>
                            <td>{item.bookingNo || "-"}</td>
                            <td>
                              {item?.paymentDetails?.downPaymentAmount ?? "-"}{" "}
                              {t("SAR")}
                            </td>
                            <td>
                              {item?.paymentDetails?.remaining ?? "-"}{" "}
                              {t("SAR")}
                            </td>

                            <td className="text-capitalize">
                              {t(
                                item?.invoices?.find(
                                  invoice => invoice?.type === "downPayment"
                                )?.paymentMethod
                              )}
                            </td>
                            <td>
                              {item?.paymentDetails?.paymentHoldAt ? (
                                <p className="sold_status status_tag">
                                  {t("Hold")}
                                </p>
                              ) : !item?.paymentDetails
                                  ?.downPaymentApprovedAt &&
                                !item?.paymentDetails?.downPaymentRejectedAt ? (
                                <p className="sold_status status_tag">
                                  {t("Pending")}
                                </p>
                              ) : item?.paymentDetails
                                  ?.downPaymentApprovedAt ? (
                                <p className="rended_status status_tag">
                                  {t("Approved")}
                                </p>
                              ) : item?.paymentDetails
                                  ?.downPaymentRejectedAt ? (
                                <p className="reject_status status_tag">
                                  {t("Rejected")}
                                </p>
                              ) : (
                                "-"
                              )}
                            </td>
                            <td>
                              <div className="d-flex align-items-center gap-3">
                                <img
                                  src={ViewIcon}
                                  alt="Trash Icon"
                                  width={24}
                                  height={24}
                                  className="cursor-pointer"
                                  onClick={() => {
                                    toggle()
                                    setSelectedId(item._id)
                                  }}
                                />
                                {item?.status !== 2 &&
                                  !item?.paymentDetails
                                    ?.downPaymentApprovedAt &&
                                  !item?.paymentDetails
                                    ?.downPaymentRejectedAt && (
                                    <button
                                      className="approve_btn fit_content"
                                      disabled={
                                        isLoading && item._id === selectedId
                                      }
                                      style={{ width: "unset" }}
                                      onClick={() => {
                                        handleEdaatPaymentApprove(item._id)
                                      }}
                                    >
                                      {t("Approve Payment")}
                                    </button>
                                  )}
                                {item?.canHoldUnholdDownPayment && (
                                  <>
                                    {item?.paymentDetails?.paymentHoldAt ? (
                                      <button
                                        className="un_hold_btn fit_content"
                                        disabled={
                                          isLoading && item._id === selectedId
                                        }
                                        onClick={() => {
                                          // handleHoldUnHold(item._id, false)
                                          setSelectedId(item._id)
                                          setIsHold(false)
                                          setShowDeleteModal(true)
                                        }}
                                      >
                                        {t("Un Hold")}
                                      </button>
                                    ) : (
                                      <button
                                        className="hold_btn fit_content"
                                        disabled={
                                          isLoading && item._id === selectedId
                                        }
                                        onClick={() => {
                                          // handleHoldUnHold(item._id, true)
                                          setSelectedId(item._id)
                                          setIsHold(true)
                                          setShowDeleteModal(true)
                                        }}
                                      >
                                        {t("Hold")}
                                      </button>
                                    )}
                                  </>
                                )}

                                {item?.canCancelBooking ? (
                                  <button
                                    className="reject_btn fit_content"
                                    onClick={() => {
                                      toggleCancelModal()
                                      setSelectedId(item._id)
                                    }}
                                  >
                                    {t("Cancel Booking")}
                                  </button>
                                ) : null}

                                {item?.status === 2 && (
                                  <p className="reject_status status_tag fit_content opacity-75">
                                    {t("Booking Cancelled")}
                                  </p>
                                )}
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                )}
              </Card>
            </Col>
            {!loading && buyingPayments.length > 0 && (
              <Paginations
                currentPage={currentPage}
                totalPages={totalPages}
                totalCount={totalCount}
                onPageChange={onPageChange}
              />
            )}
          </Row>
        </Container>
      </div>

      <DeleteModal
        show={showDeleteModal}
        onDeleteClick={handleHoldUnHold}
        onCloseClick={() => {
          setSelectedId(null)
          setShowDeleteModal(false)
          setIsHold(null)
        }}
        isLoading={isLoading}
        buttonText={isHold ? t("Hold") : t("Un Hold")}
        iconName={isHold ? "HOLD" : "UN_HOLD"}
        bodyTitle={t("Are you sure you want to {{name}}?", {
          name: isHold ? t("Hold") : t("Un Hold"),
        })}
      />
      <Modal
        isOpen={modal}
        toggle={toggle}
        centered
        className={`g_modal w_850`}
      >
        <h2 className="modal_title">{t("Down Payment Details")}</h2>
        <button type="button" onClick={toggle} className="modal_close_btn">
          <i className="mdi mdi-close"></i>
        </button>
        <div>
          {isGettingDetails ? (
            <IsLoading />
          ) : (
            <Row>
              <Col md={4}>
                <DetailsInnerDiv
                  label={t("First Name")}
                  value={buyingPaymentDetails?.buyerDetails?.firstName || "-"}
                />
              </Col>
              <Col md={4}>
                <DetailsInnerDiv
                  label={t("Last Name")}
                  value={buyingPaymentDetails?.buyerDetails?.lastName || "-"}
                />
              </Col>
              <Col md={4}>
                <DetailsInnerDiv
                  label={t("Email")}
                  value={buyingPaymentDetails?.buyerDetails?.email || "-"}
                />
              </Col>
              <Col md={4}>
                <DetailsInnerDiv
                  label={t("Unit Name")}
                  value={
                    language === "sa"
                      ? buyingPaymentDetails?.unitId?.nameAr
                      : buyingPaymentDetails?.unitId?.name || "-"
                  }
                />
              </Col>
              <Col md={4}>
                <DetailsInnerDiv
                  label={t("Booking Number")}
                  value={buyingPaymentDetails?.bookingNo || "-"}
                />
              </Col>
              <Col md={4}>
                <DetailsInnerDiv
                  label={t("Total Payment")}
                  value={`${buyingPaymentDetails?.paymentDetails?.total} ${t(
                    "SAR"
                  )}`}
                />
              </Col>
              <Col md={4}>
                <DetailsInnerDiv
                  label={t("Down Payment")}
                  value={`${
                    buyingPaymentDetails?.paymentDetails?.downPaymentAmount
                  } ${t("SAR")}`}
                />
              </Col>
              <Col md={4}>
                <DetailsInnerDiv
                  label={t("Remaining Payment")}
                  value={`${
                    buyingPaymentDetails?.paymentDetails?.remaining
                  } ${t("SAR")}`}
                />
              </Col>
              <Col md={4}>
                <DetailsInnerDiv
                  label={t("Payment Method")}
                  value={t(
                    buyingPaymentDetails?.invoices?.find(
                      invoice => invoice?.type === "downPayment"
                    )?.paymentMethod || "-"
                  )}
                  valueClass="text-capitalize"
                />
              </Col>
              <Col md={4}>
                <DetailsInnerDiv
                  label={t("Payment Status")}
                  value={
                    buyingPaymentDetails?.paymentDetails?.paymentHoldAt
                      ? t("Hold")
                      : !buyingPaymentDetails?.paymentDetails
                          ?.downPaymentApprovedAt &&
                        !buyingPaymentDetails?.paymentDetails
                          ?.downPaymentRejectedAt
                      ? t("Pending")
                      : buyingPaymentDetails?.paymentDetails
                          ?.downPaymentApprovedAt
                      ? t("Approved")
                      : buyingPaymentDetails?.paymentDetails
                          ?.downPaymentRejectedAt
                      ? t("Rejected")
                      : "-"
                  }
                  valueClass={
                    buyingPaymentDetails?.paymentDetails?.paymentHoldAt
                      ? ""
                      : !buyingPaymentDetails?.paymentDetails
                          ?.downPaymentApprovedAt &&
                        !buyingPaymentDetails?.paymentDetails
                          ?.downPaymentRejectedAt
                      ? "pending_color"
                      : buyingPaymentDetails?.paymentDetails
                          ?.downPaymentApprovedAt
                      ? "active_color"
                      : buyingPaymentDetails?.paymentDetails
                          ?.downPaymentRejectedAt
                      ? "danger_color"
                      : "-"
                  }
                />
              </Col>
              <Col md={4}>
                <DetailsInnerDiv
                  label={t("Full Payment Deadline")}
                  value={FormateDate(
                    buyingPaymentDetails?.paymentDetails?.fullPaymentDeadline
                  )}
                />
              </Col>
              {buyingPaymentDetails?.paymentDetails?.downPaymentApprovedAt && (
                <Col md={4}>
                  <DetailsInnerDiv
                    label={t("Down Payment Approved At")}
                    value={FormateDate(
                      buyingPaymentDetails?.paymentDetails
                        ?.downPaymentApprovedAt
                    )}
                  />
                </Col>
              )}
              {buyingPaymentDetails?.paymentDetails?.downPaymentRejectedAt && (
                <Col md={4}>
                  <DetailsInnerDiv
                    label={t("Down Payment Rejected At")}
                    value={FormateDate(
                      buyingPaymentDetails?.paymentDetails
                        ?.downPaymentRejectedAt
                    )}
                  />
                </Col>
              )}

              <Col md={4}>
                <DetailsInnerDiv
                  label={t("Created At")}
                  value={FormateDate(buyingPaymentDetails?.createdAt)}
                />
              </Col>

              <Col md={4}>
                <DetailsInnerDiv
                  label={t("Updated At")}
                  value={FormateDate(buyingPaymentDetails?.updatedAt)}
                />
              </Col>
            </Row>
          )}
        </div>
      </Modal>

      <Modal
        isOpen={cancelModal}
        toggle={toggleCancelModal}
        centered
        className={`g_modal w_600`}
      >
        <h2 className="modal_title">{t("Cancel Booking")}</h2>
        <button
          type="button"
          onClick={toggleCancelModal}
          className="modal_close_btn"
        >
          <i className="mdi mdi-close"></i>
        </button>
        <div>
          <Formik
            initialValues={{
              note: "",
            }}
            validationSchema={cancelBookingSchema}
            onSubmit={handleCancelBooking}
          >
            {({ errors, touched }) => (
              <Form>
                <Row>
                  <Col lg={12}>
                    <div className="form_field">
                      <CustomLabel
                        htmlFor="note"
                        label={t("Note")}
                        isRequired={true}
                      />
                      <CustomField
                        name="note"
                        type="text"
                        placeholder={t("Note")}
                        touched={touched}
                        errors={errors}
                      />
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="mt-3">
                      <button
                        className="primary_btn ms-auto d-block"
                        type="submit"
                        disabled={isLoading}
                      >
                        {t("Cancel Booking")}
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default DownPayments
