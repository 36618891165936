import { createSlice } from "@reduxjs/toolkit"
import {
  getAllCustomerSupport,
  getCustomerSupportDetails,
  updateCustomerSupportStatus,
} from "store/actions/customerSupportAction"

const initialState = {
  loading: true,
  isLoading: false,
  perPage: 10,
  totalCount: null,
  totalPages: null,
  currentPage: null,
  customerSupport: [],
  customerSupportDetails: {}, 
}
const customerSupport = createSlice({
  name: "customer support",
  initialState,
  reducers: {},
  extraReducers: builder => {
    // GET ALL
    builder
      .addCase(getAllCustomerSupport.pending, state => {
        state.loading = true
      })
      .addCase(getAllCustomerSupport.fulfilled, (state, action) => {
        state.loading = false
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { data, totalCount, totalPages, currentPage } = action.payload
          state.customerSupport = data
          state.totalCount = totalCount
          state.totalPages = totalPages
          state.currentPage = currentPage
        }
      })
      .addCase(getAllCustomerSupport.rejected, (state, action) => {
        state.loading = false
      })

    // GET DETAILS
    builder
      .addCase(getCustomerSupportDetails.pending, state => {
        state.loading = true
        state.customerSupportDetails = {}
      })
      .addCase(getCustomerSupportDetails.fulfilled, (state, action) => {
        state.loading = false
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { data } = action.payload
          state.customerSupportDetails = data
        }
      })
      .addCase(getCustomerSupportDetails.rejected, (state, action) => {
        state.loading = false
      })

    // UPDATE STATUS
    builder
      .addCase(updateCustomerSupportStatus.pending, state => {
        state.isLoading = true
      })
      .addCase(updateCustomerSupportStatus.fulfilled, (state, action) => {
        state.isLoading = false
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { id, status } = action.meta.arg
          state.customerSupport = state.customerSupport.map(item =>
            item._id === id ? { ...item, status: Number(status) } : item
          )
        }
      })
      .addCase(updateCustomerSupportStatus.rejected, (state, action) => {
        state.isLoading = false
      })
  },
})

export default customerSupport.reducer
