import PropTypes from "prop-types"
import React, { useState } from "react"

import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
  Spinner,
} from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import { Link, useNavigate } from "react-router-dom"
import withRouter from "components/Common/withRouter"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

// actions
import { socialLogin } from "../../store/actions"

// import images
import { useTranslation } from "react-i18next"
import { login } from "store/actions/authAction"
import { showAlert } from "store/reducers/alertReducer"
import ButtonLoader from "components/Shared/ButtonLoader"
import { setUserType } from "store/reducers/authReducer"
import { userTypeOptions } from "components/Common/constants"
import hidePasswordIcon from "assets/images/icons/open-eye.svg"
import showPasswordIcon from "assets/images/icons/eye-slash.svg"
import PublicNavbar from "components/CommonForBoth/PublicNavbar"

const Login = props => {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  //meta title
  document.title = t("Login | Takween")

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { loading } = useSelector(state => state.Login)
  const [showPassword, setShowPassword] = useState(false)

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "superadmin@gmail.com",
      password: "Admin@123" || "",
      // userType: "finance",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: values => {
      // const authUser = {
      //   _id: "66a0a99d58947d47a0c9236f",
      //   email: "superadmin@gmail.com",
      //   accountType: "superadmin",
      //   roleId: "66050c9897735c0ba4sdf3435bf",
      //   token:
      //     "eyJhbGciOiJIUzI1NisdfsfsdfIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NmEwYTk5ZDU4OTQ3ZDQ3YTBjOTIzNmYiLCJlbWFpbCI6ImFraGxhay5wYXRlbEBhcHBsbGlnZW50LmNvbSIsImFjY291bnRUeXBlIjoic3VwZXJhZG1pbiIsInJvbGVJZCI6IjY2MDUwYzk4OTc3MzVjMGJhNDM0MzViZiIsImlhdCI6MTcyNDA2NjEwNn0.kkH-Fo-1jhYSUgNB-8ERuQNP2WzLo-gFDc4-fGT6W0E",
      // }
      // localStorage.setItem("authUser", JSON.stringify(authUser))
      // localStorage.setItem("userType", values.userType)
      // dispatch(setUserType(values.userType))
      // navigate("/dashboard")

      dispatch(login({ ...values, history: props.router.navigate }))
        .then(action => {
          const { statusCode, success, message , data} = action?.payload
          if (statusCode === 200 && success) {
            dispatch(showAlert({ text: message, type: "success" }))
          } else {
            dispatch(showAlert({ text: message, type: "error" }))
            const { uuid } = data
            if (uuid) {
              navigate(`/reset-password/${uuid}`)
            }
          }
        })
        .catch(err => {})
    },
  })

  const LoginProperties = createSelector(
    state => state.Login,
    login => ({
      error: login.error,
    })
  )

  const { error } = useSelector(LoginProperties)

  const handleShowPassword = () => {
    setShowPassword(!showPassword)
  }

  return (
    <React.Fragment>
      <PublicNavbar />
      <div className="account-pages g_center login_page position-relative h_100_75">
        <div className="login_form">
          <div className="login_inner">
            <div className="login_content">
              <h2>{t("Login")}</h2>
              <p>{t("Enter the details for sign in")}</p>
            </div>
            <div className="p-2">
              <Form
                className="form-horizontal"
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                {error ? <Alert color="danger">{error}</Alert> : null}

                <div className="mb-3">
                  <Label className="form-label">{t("Email")}</Label>
                  <Input
                    name="email"
                    className="form-control"
                    placeholder={t("Enter email")}
                    type="email"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.email || ""}
                    invalid={
                      validation.touched.email && validation.errors.email
                        ? true
                        : false
                    }
                  />
                  {validation.touched.email && validation.errors.email ? (
                    <FormFeedback type="invalid">
                      {validation.errors.email}
                    </FormFeedback>
                  ) : null}
                </div>

                <div className="mb-3 ">
                  <Label className="form-label">{t("Password")}</Label>
                  <div className="position-relative">
                    <Input
                      name="password"
                      value={validation.values.password || ""}
                      type={showPassword ? "text" : "password"}
                      placeholder={t("Enter Password")}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      invalid={
                        validation.touched.password &&
                        validation.errors.password
                          ? true
                          : false
                      }
                    />
                    {showPassword ? (
                      <img
                        src={showPasswordIcon}
                        alt="eye"
                        width={24}
                        height={24}
                        className="eye_icon"
                        onClick={handleShowPassword}
                      />
                    ) : (
                      <img
                        src={hidePasswordIcon}
                        alt="eye"
                        width={24}
                        height={24}
                        className="eye_icon"
                        onClick={handleShowPassword}
                      />
                    )}
                  </div>
                  {validation.touched.password && validation.errors.password ? (
                    <FormFeedback type="invalid">
                      {validation.errors.password}
                    </FormFeedback>
                  ) : null}
                </div>

                {/* <div className="mb-3">
                  <Label className="form-label">{t("Select User Type")}</Label>
                  <Input
                    name="userType"
                    value={validation.values.userType || ""}
                    type="select"
                    placeholder={t("User Type")}
                    onChange={validation.handleChange}
                  >
                    <option value="" disabled hidden>
                      {t("User Type")}
                    </option>
                    {userTypeOptions.map(option => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Input>
                </div> */}

                <Link
                  to="/forgot-password"
                  className="text-white  forget_password_link"
                >
                  {t("Forget password?")}
                </Link>

                <div className="mt-3 d-grid">
                  <ButtonLoader
                    text={t("Log In")}
                    loading={loading}
                  ></ButtonLoader>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}
