import React, { useState } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap"
import SimpleBar from "simplebar-react"

import { withTranslation } from "react-i18next"
import { useSelector } from "react-redux"

import NotificationIcon from "assets/images/icons/notificationIcon.svg"
import NotificationBlueIcon from "assets/images/icons/notificationBlue.svg"

const NotificationDropdown = props => {
  const [menu, setMenu] = useState(false)
  const { userType } = useSelector(state => state.Login)
  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block custom_notification"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item position-relative "
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <div className="notification_bar g_center">
            <img src={NotificationIcon} alt="" width={19} height={19} />
            {/* <i className="bx bx-bell bx-tada" /> */}
            <span className="notification_count g_center">
              {userType === "sales" ? 1 : 0}
            </span>
          </div>
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 notification_dropdown">
          <SimpleBar>
            {userType === "sales" && (
              <Link
                to="/delivery-letter"
                className="text-reset notification-item notification_inner"
              >
                <div className="d-flex">
                  <div className="avatar g_center me-3">
                    <img
                      src={NotificationBlueIcon}
                      alt=""
                      width={16}
                      height={16}
                    />
                  </div>
                  <div className="msg_div">
                    <h6 className="">
                      {props.t(
                        "There are new delivery for units, clicks here to view"
                      )}
                    </h6>
                    <p className="mb-0">{props.t("3 min ago")}</p>
                  </div>
                </div>
              </Link>
            )}
          </SimpleBar>
          {/* <div className="p-2 border-top d-grid">
            <Link
              className="btn btn-sm btn-link font-size-14 text-center"
              to="#"
            >
              <i className="mdi mdi-arrow-right-circle me-1"></i>{" "}
              <span key="t-view-more">{props.t("View More..")}</span>
            </Link>
          </div> */}
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

export default withTranslation()(NotificationDropdown)

NotificationDropdown.propTypes = {
  t: PropTypes.any,
}
