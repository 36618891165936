import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Container, FormFeedback, Input, Label } from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import withRouter from "components/Common/withRouter"

import * as Yup from "yup"
import { ErrorMessage, Field, Form, Formik, useFormik } from "formik"

// import images
import logo from "../../assets/images/logo-light.svg"
import { useTranslation } from "react-i18next"
import PublicNavbar from "components/CommonForBoth/PublicNavbar"
import {
  forgetPasswordSchema,
  resetPasswordSchema,
  verifyPasswordSchema,
} from "components/Common/validation"
import {
  forgotPassword,
  resetPassword,
  verifyOtp,
} from "store/actions/authAction"
import { showAlert } from "store/reducers/alertReducer"
import CustomErrorMessage from "components/Shared/CustomErrorMessage"

const ForgetPasswordPage = props => {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  //meta title
  document.title = t("Forget Password | Takween")
  const { isLoading } = useSelector(state => state.Login)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [step, setStep] = useState("FORGET-PASSWORD")

  const [passwordFields, setPasswordFields] = useState({
    email: "",
    otp: "",
    newPassword: "",
    confirmPassword: "",
  })

  const handleForgetPassword = values => {
    dispatch(forgotPassword(values))
      .then(action => {
        const { statusCode, success, message, } = action?.payload
        if (statusCode === 200 && success) {
          dispatch(showAlert({ text: message, type: "success" }))
          setPasswordFields(prev => ({
            ...prev,
            email: values.email,
          }))
          setStep("VERIFY-OTP")
        } else {
          dispatch(showAlert({ text: message, type: "error" }))
        }
      })
      .catch(error => {})
  }
  const handleVerifyOtp = values => {
    const { code } = values
    const payload = {
      email: passwordFields.email,
      otp: code,
    }
    dispatch(verifyOtp(payload))
      .then(action => {
        const { statusCode, success, message,  } = action?.payload
        if (statusCode === 200 && success) {
          dispatch(showAlert({ text: message, type: "success" }))
          setPasswordFields(prev => ({
            ...prev,
            email: passwordFields.email,
            otp: code,
          }))
          setStep("RESET-PASSWORD")
        } else {
          dispatch(showAlert({ text: message, type: "error" }))
        }
      })
      .catch(error => {})
  }
  const handleResetPassword = values => {
    const { password, confirmPassword } = values

    const payload = {
      email: passwordFields.email,
      otp: passwordFields.otp,
      newPassword: password,
      confirmPassword: confirmPassword,
    }

    dispatch(resetPassword(payload))
      .then(action => {
        const { statusCode, success, message,  } = action?.payload
        if (statusCode === 200 && success) {
          dispatch(showAlert({ text: message, type: "success" }))
          setPasswordFields(prev => ({
            ...prev,
            email: passwordFields.email,
            otp: code,
          }))
          navigate("/login")
        } else {
          dispatch(showAlert({ text: message, type: "error" }))
        }
      })
      .catch(error => {})
  }

  return (
    <React.Fragment>
      <PublicNavbar />
      <div className="account-pages g_center h_100_75 forget_password_page">
        <Container>
          <div className="g_center">
            <div className="forget_password_inner">
              {step === "FORGET-PASSWORD" && (
                <div>
                  <Formik
                    initialValues={{ email: "" }}
                    enableReinitialize={true}
                    validationSchema={forgetPasswordSchema}
                    onSubmit={handleForgetPassword}
                  >
                    {({ values, errors, touched }) => (
                      <Form className="form-horizontal">
                        <h1>{t("Forget Password")}</h1>
                        <div>
                          <Label className="form-label">
                            {t("Email Address")}
                          </Label>
                          <Field
                            name="email"
                            type="email"
                            id="email"
                            placeholder={t("Email Address")}
                            className={`form-control ${
                              touched.email && errors.email ? "is-invalid" : ""
                            }`}
                          />
                          <CustomErrorMessage name={"email"} />
                        </div>

                        <button type="submit" disabled={isLoading}>
                          {t("Submit")}
                        </button>
                      </Form>
                    )}
                  </Formik>
                </div>
              )}
              {step === "VERIFY-OTP" && (
                <div>
                  <Formik
                    initialValues={{ code: "" }}
                    enableReinitialize={true}
                    validationSchema={verifyPasswordSchema}
                    onSubmit={handleVerifyOtp}
                  >
                    {({ values, errors, touched }) => (
                      <Form className="form-horizontal">
                        <h1>{t("Verify Code")}</h1>
                        <div>
                          <Label className="form-label">
                            {t("Enter Code")}
                          </Label>
                          <Field
                            name="code"
                            type="text"
                            id="code"
                            placeholder={t("Enter Code")}
                            className={`form-control ${
                              touched.code && errors.code ? "is-invalid" : ""
                            }`}
                          />
                          <CustomErrorMessage name={"code"} />
                        </div>

                        <button type="submit" disabled={isLoading}>
                          {t("Verify")}
                        </button>
                      </Form>
                    )}
                  </Formik>
                </div>
              )}
              {step === "RESET-PASSWORD" && (
                <div>
                  <Formik
                    initialValues={{ password: "", confirmPassword: "" }}
                    enableReinitialize={true}
                    validationSchema={resetPasswordSchema}
                    onSubmit={handleResetPassword}
                  >
                    {({ values, errors, touched }) => (
                      <Form className="form-horizontal">
                        <h1>{t("Reset Password")}</h1>
                        <div>
                          <Label className="form-label">
                            {t("New Password")}
                          </Label>
                          <Field
                            name="password"
                            type="password"
                            id="password"
                            placeholder={t("New Password")}
                            className={`form-control ${
                              touched.password && errors.password
                                ? "is-invalid"
                                : ""
                            }`}
                          />
                          <CustomErrorMessage name={"password"} />
                        </div>
                        <div>
                          <Label className="form-label">
                            {t("Confirm New Password")}
                          </Label>
                          <Field
                            name="confirmPassword"
                            type="password"
                            id="confirmPassword"
                            placeholder={t("Confirm New Password")}
                            className={`form-control ${
                              touched.confirmPassword && errors.confirmPassword
                                ? "is-invalid"
                                : ""
                            }`}
                          />
                          <CustomErrorMessage name={"confirmPassword"} />
                        </div>

                        <button type="submit" disabled={isLoading}>
                          {t("Save")}
                        </button>
                      </Form>
                    )}
                  </Formik>
                </div>
              )}
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

ForgetPasswordPage.propTypes = {
  history: PropTypes.object,
}

export default withRouter(ForgetPasswordPage)
