import React, { useEffect, useState } from "react"
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap"
import { useTranslation } from "react-i18next"
import { useNavigate, useSearchParams } from "react-router-dom"
import Title from "components/Shared/Title"
import { ErrorMessage, Field, Form, Formik } from "formik"
import { createUserSchema } from "components/Common/validation"
import Select from "react-select"
// import { userTypeOptions } from "components/Common/constants"
import TopTitle from "components/Shared/TopTitle"
import FormButtons from "components/Shared/FormButtons"
import {
  createUser,
  getRolesList,
  getUserDetails,
  updateUser,
} from "store/actions/usersAction"
import { showAlert } from "store/reducers/alertReducer"
import { useDispatch, useSelector } from "react-redux"
import Spinners from "components/Shared/Spinners"
import { createUserValues } from "constants/initialValues"

const CreateUser = ({ mode }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  document.title =
    mode === "create" ? t("Create User | Takween") : t("Update User | Takween")

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()
  const id = searchParams.get("id")

  const { loading, isLoading, rolesList, userDetails } = useSelector(
    state => state.Users
  )

  const [formValues, setFormValues] = useState(createUserValues)

  useEffect(() => {
    const fetchDetails = () => {
      const payload = {
        id,
      }
      dispatch(getUserDetails(payload))
    }
    mode === "update" && fetchDetails()
    fetchRolesList()
  }, [])

  useEffect(() => {
    if (userDetails && mode === "update") {
      const { firstName, lastName, email, role } = userDetails
      setFormValues(prev => ({
        ...prev,
        firstName,
        lastName,
        email,
        // phoneNumber,
        role: role?._id,
      }))
    }
  }, [mode, rolesList, userDetails])

  const fetchRolesList = () => {
    dispatch(getRolesList())
  }

  const handleOnSubmit = values => {
    if (mode === "create") {
      dispatch(createUser(values))
        .then(action => {
          const { statusCode, success, message } = action?.payload
          if (statusCode === 200 && success) {
            dispatch(showAlert({ text: message, type: "success" }))
            navigate("/users")
          } else {
            dispatch(showAlert({ text: message, type: "error" }))
          }
        })
        .catch(error => {})
    } else if (mode === "update") {
      const payload = { ...values, id }
      dispatch(updateUser(payload))
        .then(action => {
          const { statusCode, success, message } = action?.payload
          if (statusCode === 200 && success) {
            dispatch(showAlert({ text: message, type: "success" }))
            navigate("/users")
          } else {
            dispatch(showAlert({ text: message, type: "error" }))
          }
        })
        .catch(error => {})
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        {mode === "update" && loading ? (
          <Spinners />
        ) : (
          <Container fluid>
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <h2 className="fs_18_700">
                      {mode === "create" ? t("Create User") : t("Update User")}
                    </h2>
                    <Formik
                      initialValues={formValues}
                      enableReinitialize={true}
                      validationSchema={createUserSchema(mode)}
                      onSubmit={handleOnSubmit}
                    >
                      {({ values, errors, touched, setFieldValue }) => (
                        <Form>
                          <Row>
                            <Col lg={6}>
                              <div className="form_field">
                                <label htmlFor="firstName">
                                  {t("First Name")}
                                  <span className="text-danger px-1">*</span>
                                </label>
                                <Field
                                  name="firstName"
                                  type="text"
                                  id="firstName"
                                  placeholder={t("First Name")}
                                  className={`form-control ${
                                    touched.firstName && errors.firstName
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                />
                                <ErrorMessage
                                  name="firstName"
                                  component="div"
                                  className="text-danger"
                                />
                              </div>
                            </Col>
                            <Col lg={6}>
                              <div className="form_field">
                                <label htmlFor="lastName">
                                  {t("Last Name")}
                                  <span className="text-danger px-1">*</span>
                                </label>
                                <Field
                                  name="lastName"
                                  type="text"
                                  id="lastName"
                                  placeholder={t("Name")}
                                  className={`form-control ${
                                    touched.lastName && errors.lastName
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                />
                                <ErrorMessage
                                  name="lastName"
                                  component="div"
                                  className="text-danger"
                                />
                              </div>
                            </Col>

                            <Col lg={6}>
                              <div className="form_field">
                                <label htmlFor="email">
                                  {t("Email")}
                                  <span className="text-danger px-1">*</span>
                                </label>
                                <Field
                                  name="email"
                                  type="email"
                                  id="email"
                                  placeholder={t("Email")}
                                  className={`form-control ${
                                    touched.email && errors.email
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                />
                                <ErrorMessage
                                  name="email"
                                  component="div"
                                  className="text-danger"
                                />
                              </div>
                            </Col>
                            <Col lg={6}>
                              <div className="form_field">
                                <label htmlFor="role">
                                  {t("Select Role")}
                                  <span className="text-danger px-1">*</span>
                                </label>
                                <div className="select_wrapper">
                                  <Field
                                    as="select"
                                    name="role"
                                    className={`form-control b_r_12 ${
                                      errors.role && touched.role
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    onChange={e => {
                                      const selectedValue = e.target.value
                                      setFieldValue("role", selectedValue)
                                    }}
                                  >
                                    <option hidden>{t("Select Role")}</option>
                                    {rolesList?.map(item => (
                                      <option key={item._id} value={item._id}>
                                        {language === "sa"
                                          ? item.nameAr
                                          : item.name}
                                      </option>
                                    ))}
                                  </Field>
                                </div>

                                <ErrorMessage
                                  name="role"
                                  component="div"
                                  className="text-danger"
                                />
                              </div>
                            </Col>
                            {/* <Col lg={6}>
                              <div className="form_field">
                                <label htmlFor="phoneNumber">
                                  {t("Phone")}
                                  <span className="text-danger px-1">*</span>
                                </label>
                                <Field
                                  name="phoneNumber"
                                  type="text"
                                  id="phoneNumber"
                                  placeholder={t("Phone")}
                                  className={`form-control ${
                                    touched.phoneNumber && errors.phoneNumber
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                />
                                <ErrorMessage
                                  name="phoneNumber"
                                  component="div"
                                  className="text-danger"
                                />
                              </div>
                            </Col> */}

                            <Col lg={12}>
                              <FormButtons
                                buttonText={
                                  mode === "create" ? t("Create") : t("Update")
                                }
                                isLoading={isLoading}
                              />
                            </Col>
                          </Row>
                        </Form>
                      )}
                    </Formik>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        )}
      </div>
    </React.Fragment>
  )
}

export default CreateUser
