import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  activeLinkType: null,
}

const sharedSlice = createSlice({
  name: "shared",
  initialState,
  reducers: {
    showActiveLink(state, action) {
      state.activeLinkType = action.payload
      // console.log(action.payload)
    },
  },
})

export const { showActiveLink } = sharedSlice.actions

export default sharedSlice.reducer
