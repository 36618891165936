import React, { useEffect } from "react"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import { useTranslation } from "react-i18next"

import Banner from "assets/images/garbage/banner.png"
import TopTitle from "components/Shared/TopTitle"
import DetailsTitle from "components/Shared/DetailsTitle"
import DetailsInnerDiv from "components/Shared/DetailsInnerDiv"
import { useDispatch, useSelector } from "react-redux"
import { useLocation } from "react-router-dom"
import { getBannerDetails } from "store/actions/bannersAction"
import { ShimmerThumbnail } from "react-shimmer-effects"
import NoDataAvailable from "components/Shared/NoDataAvailable"

const BannerDetails = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  document.title = t("Banner Details | Takween")

  const dispatch = useDispatch()
  const { loading, bannerDetails } = useSelector(state => state.Banners)
  const name = useSelector(state => state.Login.userProfile?.name) || "";


  const { search } = useLocation()
  const id = new URLSearchParams(search).get("id")

  useEffect(() => {
    const fetchBannerDetails = () => {
      const payload = {
        id,
      }
      dispatch(getBannerDetails(payload))
    }
    fetchBannerDetails()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <TopTitle
            title={t("Banner Details")}
            subTitle={t(
              `Hi, {{name}}. Welcome back to your Marketing portal!`,
              { name: name }
            )}
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <DetailsTitle title={t("Banner Details")} />
                  {loading ? (
                    <ShimmerThumbnail height={400} rounded className={"mb-0"} />
                  ) : Object.values(bannerDetails).length === 0 ? (
                    <p className="text-center my-3">
                      <NoDataAvailable />
                    </p>
                  ) : (
                    <Row className="g_details">
                      <Col md={3}>
                        <DetailsInnerDiv
                          label={t("Id")}
                          value={bannerDetails._id}
                        />
                      </Col>
                      <Col md={3}>
                        <DetailsInnerDiv
                          label={t("Title(English)")}
                          value={bannerDetails.title}
                        />
                      </Col>
                      <Col md={3}>
                        <DetailsInnerDiv
                          label={t("Title(عربي)")}
                          value={bannerDetails.titleAr}
                        />
                      </Col>
                      <Col md={3}>
                        <DetailsInnerDiv
                          label={t("Display Order")}
                          value={bannerDetails.displayOrder}
                        />
                      </Col>
                      <Col md={3}>
                        <DetailsInnerDiv
                          label={t("Apply same banner for both English & Arabic")}
                          value={
                            bannerDetails.applySameImageOnBothLang
                              ? t("Yes")
                              : t("No")
                          }
                        />
                      </Col>
                      <Col md={3}>
                        <DetailsInnerDiv
                          label={t("Status")}
                          value={t(
                            bannerDetails.status === 1 ? "Active" : "Deactive"
                          )}
                          valueClass={`${
                            bannerDetails.status === 1
                              ? "active_color"
                              : "danger_color"
                          } `}
                        />
                      </Col>

                      <Col lg={9}>
                        <div className="d-flex mt-3">
                          <div>
                            <h2 className="fs_18_700 mb-3">{t("English")}</h2>
                            <img
                              src={bannerDetails.imageUrl}
                              alt={bannerDetails.title}
                              className="img-fluid rounded-3"
                              style={{ objectFit: "contain" }}
                            />
                          </div>
                          <div className="ms-4">
                            <h2 className="fs_18_700 mb-3">{t("Arabic")}</h2>
                            <img
                              src={bannerDetails.imageUrlAr}
                              alt={bannerDetails.titleAr}
                              className="img-fluid rounded-3"
                              style={{ objectFit: "contain" }}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default BannerDetails
