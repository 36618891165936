import React, { useEffect, useState } from "react"
import { Card, CardBody, Col, Row } from "reactstrap"
import { useTranslation } from "react-i18next"
import StackedColumnChart from "pages/Dashboard/StackedColumnChart"
import { useDispatch, useSelector } from "react-redux"
import { getDashboardData } from "store/actions/commonAction"
import { getDashboardDateRange, getMonthLabels } from "components/Common/Utils"
import { getProjectsList } from "store/actions/projectsAction"
import { getUnitsList } from "store/actions/unitsAction"
import { ShimmerThumbnail } from "react-shimmer-effects"
import ResetWhite from "assets/images/icons/resetWhite.svg"
import Select from "react-select"

const FinanceDashboard = ({ dateRange, setDateRange }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const dispatch = useDispatch()

  const { loading, dashboardData } = useSelector(state => state.Common)

  const [periodData, setPeriodData] = useState([])
  const [chartLabels, setChartLabels] = useState([])
  const { projectsList } = useSelector(state => state.Projects)
  const { unitsList } = useSelector(state => state.Units)
  const [resetting, setResetting] = useState(false)

  const [filters, setFilters] = useState({
    project: [],
    unit: [],
  })

  useEffect(() => {
    if (!resetting) {
      fetchDashboardData()
      fetchUnitsList()
    }
  }, [filters])

  useEffect(() => {
    fetchProjectsList()
  }, [])

  useEffect(() => {
    if (dateRange[0] && dateRange[1]) {
      fetchDashboardData()
    }
  }, [dateRange])

  const fetchDashboardData = ({ isReset = false } = {}) => {
    const { project, unit } = filters
    const updatedProjects = isReset
      ? []
      : project?.map(item => item.value) || []
    const updatedUnits = isReset ? [] : unit?.map(item => item.value) || []

    const payload = {
      type: "finance",
      filter: {
        dateRange: isReset ? {} : getDashboardDateRange(dateRange),
        project: updatedProjects,
        unit: updatedUnits,
      },
    }
    dispatch(getDashboardData(payload))
  }

  const fetchProjectsList = () => {
    dispatch(getProjectsList())
  }

  const fetchUnitsList = ({ isReset = false } = {}) => {
    const { project } = filters
    const updatedProjects = isReset
      ? []
      : project?.map(item => item.value) || []

    const payload = {
      project: updatedProjects,
    }
    dispatch(getUnitsList(payload))
  }

  useEffect(() => {
    if (
      dashboardData?.unitsSoldByMonth &&
      dashboardData.unitsSoldByMonth.length > 0
    ) {
      // Extract the first and last month entries
      const startMonthData = dashboardData.unitsSoldByMonth[0]
      const endMonthData =
        dashboardData.unitsSoldByMonth[
          dashboardData.unitsSoldByMonth.length - 1
        ]

      // Format the start and end month strings
      const startDate = `${startMonthData.year}-${String(
        startMonthData.month
      ).padStart(2, "0")}`
      const endDate = `${endMonthData.year}-${String(
        endMonthData.month
      ).padStart(2, "0")}`

      // Generate month labels based on start and end dates
      const monthLabels = getMonthLabels(startDate, endDate)

      // Set the period data based on the API response
      setPeriodData([
        {
          name: t("Units Sold"),
          data: dashboardData.unitsSoldByMonth.map(item => item.totalSoldCount),
        },
        {
          name: t("Units In Progress"),
          data: dashboardData.unitsSoldByMonth.map(
            item => item.totalInProgressCount
          ),
        },
        {
          name: t("Units Cancelled"),
          data: dashboardData.unitsSoldByMonth.map(
            item => item.totalCancelledCount
          ),
        },
      ])

      // Pass month labels to StackedColumnChart if necessary
      setChartLabels(monthLabels)
    }
  }, [dashboardData, language])

  const handleFilterChange = (filterName, selectedValue) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [filterName]: selectedValue ? selectedValue : [],
    }))
  }

  const handleReset = () => {
    setResetting(true)
    setDateRange([])
    setFilters({ project: [], unit: [] })
    fetchDashboardData({ isReset: true })
    fetchUnitsList({ isReset: true })
    setTimeout(() => {
      setResetting(false)
    }, 1000)
  }

  return (
    <Row className="marketing_dashboard sales_dashboard_graph">
      <Col lg={12}>
        <Card>
          <CardBody>
            {loading ? (
              <ShimmerThumbnail height={450} rounded className={"mb-0"} />
            ) : (
              <>
                <div>
                  <Row>
                    <Col>
                      <div>
                        <h2 className="fs_20_600">
                          {t("Total Amount Earned")}
                        </h2>
                        <p className="fs_20_600 opacity-50">
                          {dashboardData?.totalAmountEarned ?? "-"} {t("SAR")}
                        </p>
                      </div>
                    </Col>
                    <Col>
                      <div className="ms-4">
                        <h2 className="fs_20_600">{t("Total Units Sold")}</h2>
                        <p className="fs_20_600 opacity-50">
                          {dashboardData?.totalUnitsSold ?? "-"}
                        </p>
                      </div>
                    </Col>
                    <Col>
                      <div className="ms-4">
                        <h2 className="fs_20_600">
                          {t("Total Units In Progress")}
                        </h2>
                        <p className="fs_20_600 opacity-50">
                          {dashboardData?.totalUnitsInProgress ?? "-"}
                        </p>
                      </div>
                    </Col>
                    <Col>
                      <div className="ms-4">
                        <h2 className="fs_20_600">
                          {t("Total Units Cancelled")}
                        </h2>
                        <p className="fs_20_600 opacity-50">
                          {dashboardData?.totalUnitsCancelled ?? "-"}
                        </p>
                      </div>
                    </Col>

                    <Col lg={12} className="mt-3">
                      <Row>
                        <Col>
                          <div>
                            <label htmlFor="">{t("Choose Project")}</label>
                            <Select
                              value={filters.project}
                              isMulti={true}
                              closeMenuOnSelect={false}
                              onChange={selectedOptions =>
                                handleFilterChange("project", selectedOptions)
                              }
                              options={projectsList?.map(item => ({
                                label:
                                  language === "sa" ? item.nameAr : item.name,
                                value: item._id,
                              }))}
                              // menuIsOpen={true}
                              placeholder={t("Projects")}
                              className="select2-selection"
                            />
                          </div>
                        </Col>
                        <Col>
                          <div>
                            <label htmlFor="">{t("Choose Unit")}</label>
                            <Select
                              value={filters.unit}
                              isMulti={true}
                              closeMenuOnSelect={false}
                              onChange={selectedOptions =>
                                handleFilterChange("unit", selectedOptions)
                              }
                              options={unitsList?.map(item => ({
                                label:
                                  language === "sa" ? item.nameAr : item.name,
                                value: item._id,
                              }))}
                              placeholder={t("Choose Unit")}
                              className="select2-selection"
                            />
                          </div>
                        </Col>
                        <Col style={{ maxWidth: 60, paddingLeft: 6 }}>
                          <button
                            className="reset_btn dashboard_reset_btn"
                            style={{ marginTop: 26, marginRight: 20 }}
                            onClick={handleReset}
                          >
                            <img src={ResetWhite} alt="" />
                          </button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
                <div style={{ direction: "ltr" }}>
                  <StackedColumnChart
                    periodData={periodData}
                    dataColors='["#0C3553","#D5B086","#3CB3C1"]'
                    height={392}
                    chartLabels={chartLabels}
                  />
                </div>
              </>
            )}
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default FinanceDashboard
