// Retrieve the authUser object from localStorage
const getAccessToken = () => {
  const authUserString = localStorage.getItem("authUser");
  let token = null; // Initialize token variable

  if (authUserString) {
    // Parse the JSON string to convert it into an object
    const authUser = JSON.parse(authUserString);

    // Check if the authUser object contains the token property
    if (authUser.token) {
      // Access the token property from the authUser object
      token = authUser.token; // Assign token value
    } else {
      console.error("Token not found in authUser object");
    }
  } else {
    console.error("AuthUser data not found in localStorage");
  }

  return token;
};

export default getAccessToken;
