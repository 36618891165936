import { createAsyncThunk } from "@reduxjs/toolkit"
import { del, formDataPost, formDataPut, get, patch, post, put } from "helpers/api_helper"
import {
  CREATE_SERVICE,
  DELETE_SERVICE,
  GET_ALL_SERVICE,
  GET_SERVICE_DETAILS,
  GET_SERVICES_LIST,
  UPDATE_SERVICE,
  UPDATE_SERVICE_STATUS,
} from "helpers/url_helper"

export const getAllServices = createAsyncThunk(
  GET_ALL_SERVICE,
  async payload => {
    const response = await post(GET_ALL_SERVICE, payload)
    return response
  }
)

export const getServicesList = createAsyncThunk(
  GET_SERVICES_LIST,
  async payload => {
    const response = await post(GET_SERVICES_LIST, payload)
    return response
  }
)

export const getServiceDetails = createAsyncThunk(
  GET_SERVICE_DETAILS,
  async payload => {
    const response = await post(GET_SERVICE_DETAILS, payload)
    return response
  }
)

export const updateServiceStatus = createAsyncThunk(
  UPDATE_SERVICE_STATUS,
  async payload => {
    const response = await patch(UPDATE_SERVICE_STATUS, payload)
    return response
  }
)

export const deleteService = createAsyncThunk(
  DELETE_SERVICE,
  async payload => {
    const response = await del(DELETE_SERVICE, payload)
    return response
  }
)

export const createService = createAsyncThunk(
  CREATE_SERVICE,
  async payload => {
    const response = await formDataPost(CREATE_SERVICE, payload)
    return response
  }
)

export const updateService = createAsyncThunk(
  UPDATE_SERVICE,
  async payload => {
    const response = await formDataPut(UPDATE_SERVICE, payload)
    return response
  }
)

