import React, { useEffect, useState } from "react"
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap"
import { useTranslation } from "react-i18next"
import { useNavigate, useSearchParams } from "react-router-dom"
import Title from "components/Shared/Title"
import { ErrorMessage, Field, Form, Formik } from "formik"
import { createMaintenanceUserSchema } from "components/Common/validation"
import FormButtons from "components/Shared/FormButtons"
import { useDispatch, useSelector } from "react-redux"
import { getServicesList } from "store/actions/servicesAction"
import { getServiceItemList } from "store/actions/serviceItemsAction"
import CustomLabel from "components/Shared/CustomLabel"
import CustomErrorMessage from "components/Shared/CustomErrorMessage"
import Select from "react-select"
import FileUpload from "components/Shared/FileUpload"
import { handleFileUpload } from "components/Common/Utils"
import {
  createMaintenanceUser,
  getMaintenanceUserDetails,
  updateMaintenanceUser,
} from "store/actions/maintenanceUsersAction"
import { showAlert } from "store/reducers/alertReducer"
import Spinners from "components/Shared/Spinners"

const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  phoneCode: "",
  phoneNumber: "",
  nationalId: "",
  serviceId: "",
  serviceItemIds: [],
  file: "",
}

const CreateMaintenanceUser = ({ mode }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  document.title =
    mode === "create"
      ? t("Create MaintenanceUser | Takween")
      : t("Update MaintenanceUser | Takween")

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()
  const id = searchParams.get("id")

  const { isLoading, loading, maintenanceUserDetails } = useSelector(
    state => state.MaintenanceUsers
  )
  const { servicesList } = useSelector(state => state.Services)
  const { serviceItemsList } = useSelector(state => state.ServiceItems)

  const [formValues, setFormValues] = useState(initialValues)

  useEffect(() => {
    const fetchDetails = () => {
      const payload = {
        id,
      }
      dispatch(getMaintenanceUserDetails(payload))
    }
    mode === "update" && fetchDetails()
    const fetchServiceList = () => {
      dispatch(getServicesList())
    }

    fetchServiceList()
  }, [])

  useEffect(() => {
    if (maintenanceUserDetails && mode === "update") {
      const {
        firstName,
        lastName,
        email,
        phoneCode,
        phoneNumber,
        nationalId,
        nationalDocUrl,
        serviceId,
        serviceItemIds,
      } = maintenanceUserDetails

      const updatedServiceId = serviceId
        ? {
            label: language === "sa" ? serviceId?.nameAr : serviceId?.name,
            value: serviceId?._id,
          }
        : {}

      const updatedServiceItemsId =
        serviceItemIds?.map(item => ({
          label: language === "sa" ? item?.nameAr : item?.name,
          value: item?._id,
        })) || []

      fetchServiceItemsList(serviceId?._id)

      setFormValues({
        firstName,
        lastName,
        email,
        phoneCode,
        phoneNumber,
        nationalId,
        serviceId: updatedServiceId,
        serviceItemIds: updatedServiceItemsId,
        file: nationalDocUrl,
      })
    }
  }, [mode, maintenanceUserDetails, language])

  const fetchServiceItemsList = service => {
    const payload = { service: [service] }
    dispatch(getServiceItemList(payload))
  }

  const handleOnSubmit = values => {
    const {
      firstName,
      lastName,
      email,
      phoneCode,
      phoneNumber,
      nationalId,
      serviceId,
      serviceItemIds,
      file,
    } = values

    let data = new FormData()
    data.append("firstName", firstName)
    data.append("lastName", lastName)
    data.append("email", email)
    data.append("phoneCode", phoneCode)
    data.append("phoneNumber", phoneNumber)
    data.append("nationalId", nationalId)
    data.append("serviceId", serviceId.value || "")
    serviceItemIds.forEach((item, index) => {
      data.append(`serviceItemIds[${index}]`, item.value)
    })
    data.append("file", file)

    if (mode === "create") {
      dispatch(createMaintenanceUser(data))
        .then(action => {
          const { statusCode, success, message } = action?.payload
          if (statusCode === 200 && success) {
            dispatch(showAlert({ text: message, type: "success" }))
            navigate("/maintenance-users")
          } else {
            dispatch(showAlert({ text: message, type: "error" }))
          }
        })
        .catch(error => {})
    } else if (mode === "update") {
      data.append("id", id)
      dispatch(updateMaintenanceUser(data))
        .then(action => {
          const { statusCode, success, message } = action?.payload
          if (statusCode === 200 && success) {
            dispatch(showAlert({ text: message, type: "success" }))
            navigate("/maintenance-users")
          } else {
            dispatch(showAlert({ text: message, type: "error" }))
          }
        })
        .catch(error => {})
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        {mode === "update" && loading ? (
          <Spinners />
        ) : (
          <Container fluid>
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <h2 className="fs_18_700">
                      {mode === "create"
                        ? t("Create Maintenance User")
                        : t("Update Maintenance User")}
                    </h2>
                    <Formik
                      initialValues={formValues}
                      enableReinitialize={true}
                      validationSchema={createMaintenanceUserSchema(mode)}
                      onSubmit={handleOnSubmit}
                    >
                      {({ values, errors, touched, setFieldValue }) => (
                        <Form>
                          <Row>
                            <Col lg={6}>
                              <div className="form_field">
                                <label htmlFor="firstName">
                                  {t("First Name")}
                                  <span className="text-danger px-1">*</span>
                                </label>
                                <Field
                                  name="firstName"
                                  type="text"
                                  id="firstName"
                                  placeholder={t("First Name")}
                                  className={`form-control ${
                                    touched.firstName && errors.firstName
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                />
                                <ErrorMessage
                                  name="firstName"
                                  component="div"
                                  className="text-danger"
                                />
                              </div>
                            </Col>
                            <Col lg={6}>
                              <div className="form_field">
                                <label htmlFor="lastName">
                                  {t("Last Name")}
                                  <span className="text-danger px-1">*</span>
                                </label>
                                <Field
                                  name="lastName"
                                  type="text"
                                  id="lastName"
                                  placeholder={t("Last Name")}
                                  className={`form-control ${
                                    touched.lastName && errors.lastName
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                />
                                <ErrorMessage
                                  name="lastName"
                                  component="div"
                                  className="text-danger"
                                />
                              </div>
                            </Col>
                            <Col lg={6}>
                              <div className="form_field">
                                <label htmlFor="nationalId">
                                  {t("National Id")}
                                  <span className="text-danger px-1">*</span>
                                </label>
                                <Field
                                  name="nationalId"
                                  type="text"
                                  id="nationalId"
                                  placeholder={t("National Id")}
                                  className={`form-control ${
                                    touched.nationalId && errors.nationalId
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                />
                                <ErrorMessage
                                  name="nationalId"
                                  component="div"
                                  className="text-danger"
                                />
                              </div>
                            </Col>
                            <Col lg={6}>
                              <div className="form_field">
                                <label htmlFor="email">
                                  {t("Email")}
                                  <span className="text-danger px-1">*</span>
                                </label>
                                <Field
                                  name="email"
                                  type="email"
                                  id="email"
                                  placeholder={t("Email")}
                                  className={`form-control ${
                                    touched.email && errors.email
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                />
                                <ErrorMessage
                                  name="email"
                                  component="div"
                                  className="text-danger"
                                />
                              </div>
                            </Col>
                            <Col lg={6}>
                              <div className="form_field">
                                <label htmlFor="phoneCode">
                                  {t("Phone Code")}
                                  <span className="text-danger px-1">*</span>
                                </label>
                                <Field
                                  name="phoneCode"
                                  type="text"
                                  id="phoneCode"
                                  placeholder={t("Phone Code")}
                                  className={`form-control ${
                                    touched.phoneCode && errors.phoneCode
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                />
                                <ErrorMessage
                                  name="phoneCode"
                                  component="div"
                                  className="text-danger"
                                />
                              </div>
                            </Col>
                            <Col lg={6}>
                              <div className="form_field">
                                <label htmlFor="phoneNumber">
                                  {t("Phone Number")}
                                  <span className="text-danger px-1">*</span>
                                </label>
                                <Field
                                  name="phoneNumber"
                                  type="number"
                                  id="phoneNumber"
                                  placeholder={t("Phone")}
                                  className={`form-control ${
                                    touched.phoneNumber && errors.phoneNumber
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                />
                                <ErrorMessage
                                  name="phoneNumber"
                                  component="div"
                                  className="text-danger"
                                />
                              </div>
                            </Col>

                            <Col lg={6}>
                              <div className="g_label mt-3">
                                <label htmlFor="serviceId">
                                  {t("Select Service")}
                                  <span className="text-danger px-1">*</span>
                                </label>
                                <Select
                                  value={values.serviceId}
                                  onChange={selectedValue => {
                                    fetchServiceItemsList(selectedValue.value)
                                    setFieldValue(`serviceId`, selectedValue)
                                    setFieldValue(`serviceItemIds`, [])
                                  }}
                                  options={servicesList?.map(item => ({
                                    label:
                                      language === "sa"
                                        ? item?.nameAr
                                        : item?.name,
                                    value: item._id,
                                  }))}
                                  placeholder={t("Select Service")}
                                  className={`select2-selection b_r_12 h_46 ${
                                    touched.serviceId && errors.serviceId
                                      ? "danger_color_border"
                                      : ""
                                  } `}
                                />
                                <ErrorMessage
                                  name="serviceId"
                                  component="div"
                                  className="text-danger"
                                />
                              </div>
                            </Col>

                            <Col lg={6}>
                              <div className="g_label mt-3">
                                <CustomLabel
                                  htmlFor={`serviceItemIds`}
                                  label={t("Select Services Items")}
                                  isRequired={true}
                                />
                                <Select
                                  value={values.serviceItemIds}
                                  isMulti={true}
                                  closeMenuOnSelect={false}
                                  onChange={selectedValue =>
                                    setFieldValue(
                                      `serviceItemIds`,
                                      selectedValue
                                    )
                                  }
                                  options={serviceItemsList?.map(item => ({
                                    label:
                                      language === "sa"
                                        ? item?.nameAr
                                        : item?.name,
                                    value: item._id,
                                  }))}
                                  placeholder={t("Select Services Items")}
                                  className={`select2-selection b_r_12 h_46 ${
                                    touched.serviceItemIds &&
                                    errors.serviceItemIds
                                      ? "danger_color_border"
                                      : ""
                                  } `}
                                />
                                <CustomErrorMessage name={`serviceItemIds`} />
                              </div>
                            </Col>
                            <Col lg={12}>
                              <div className="form_field mb-0 mt-3">
                                <CustomLabel
                                  htmlFor="file"
                                  label={t("Upload National Doc")}
                                  isRequired={true}
                                />
                                <FileUpload
                                  name="file"
                                  value={values.file}
                                  touched={touched.file}
                                  error={errors.file}
                                  accept={".pdf"}
                                  customClasses="h_72"
                                  labelText={t("Upload File")}
                                  setFieldValue={setFieldValue}
                                  showNameOnly={true}
                                  handleFileUpload={event =>
                                    handleFileUpload(
                                      event,
                                      setFieldValue,
                                      "file"
                                    )
                                  }
                                />
                                <CustomErrorMessage name={"file"} />
                              </div>
                            </Col>

                            <Col lg={12}>
                              <FormButtons
                                buttonText={
                                  mode === "create" ? t("Create") : t("Update")
                                }
                                isLoading={isLoading}
                              />
                            </Col>
                          </Row>
                        </Form>
                      )}
                    </Formik>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        )}
      </div>
    </React.Fragment>
  )
}

export default CreateMaintenanceUser
