import React from "react"
import ImgPreview from "./ImgPreview"
import TrashIcon from "assets/images/icons/trash.svg"
import GalleryIcon from "assets/images/icons/gallery.svg"
import VideoIcon from "assets/images/icons/video.svg"
import VideoPreview from "./VideoPreview"

const FileUpload = ({
  name,
  value,
  touched,
  error,
  labelText,
  setFieldValue,
  handleFileUpload,
  accept = "image/jpeg, image/jpg, image/png, image/webp", //image/svg+xml
  fileType = "image",
  customClasses = "",
  height = null,
  showNameOnly = false,
}) => {
  return (
    <div>
      <label
        htmlFor={name}
        className={`file_label g_center position-relative ${customClasses} ${
          touched && error ? "text-danger border-danger" : ""
        }`}
        style={{ height: height || null }}
      >
        {value ? (
          value && (
            <>
              {showNameOnly ? (
                <p>{value.name || value || "-"}</p>
              ) : fileType === "video" ? (
                <VideoPreview url={value} />
              ) : (
                <ImgPreview image={value} />
              )}
              <img
                src={TrashIcon}
                alt="Trash Icon"
                width={24}
                height={24}
                className="cursor-pointer ms-3 trash_icon"
                onClick={e => {
                  e.preventDefault()
                  setFieldValue(name, "")
                }}
              />
            </>
          )
        ) : (
          <>
            <img
              src={fileType === "video" ? VideoIcon : GalleryIcon}
              alt="gallery Icon"
              className="mx-2"
            />
            <span>{labelText}</span>
          </>
        )}
      </label>
      <input
        name={name}
        type="file"
        id={name}
        accept={accept}
        className={`form-control d-none ${
          touched && error ? "is-invalid" : ""
        }`}
        onChange={event => handleFileUpload(event, setFieldValue, name)}
      />
    </div>
  )
}

export default FileUpload
