import { createAsyncThunk } from "@reduxjs/toolkit"
import { del, get, patch, post, put } from "helpers/api_helper"
import {
  GET_ALL_CUSTOMER_SUPPORT,
  GET_CUSTOMER_SUPPORT_DETAILS,
  UPDATE_CUSTOMER_SUPPORT_STATUS,
} from "helpers/url_helper"

export const getAllCustomerSupport = createAsyncThunk(
  GET_ALL_CUSTOMER_SUPPORT,
  async payload => {
    const response = await post(GET_ALL_CUSTOMER_SUPPORT, payload)
    return response
  }
)

export const updateCustomerSupportStatus = createAsyncThunk(
  UPDATE_CUSTOMER_SUPPORT_STATUS,
  async payload => {
    const response = await patch(UPDATE_CUSTOMER_SUPPORT_STATUS, payload)
    return response
  }
)

export const getCustomerSupportDetails = createAsyncThunk(
  GET_CUSTOMER_SUPPORT_DETAILS,
  async payload => {
    const response = await post(GET_CUSTOMER_SUPPORT_DETAILS, payload)
    return response
  }
)
