import React, { useEffect } from "react"
import { useDispatch } from "react-redux"
import { hideAlert } from "store/reducers/alertReducer"
import Swal from "sweetalert2"

const SweatAlert = ({ text, type }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    showDirectToast()

    const timeout = setTimeout(() => {
      dispatch(hideAlert())
    }, 2000) // Hide alert after 3 seconds

    return () => {
      clearTimeout(timeout)
    }
  }, [dispatch])

  const showDirectToast = () => {
    Swal.fire({
      toast: true,
      icon: type,
      title: text,
      position: "top-right",
      showConfirmButton: false,
      timer: 2000,
    })
  }
}

export default SweatAlert
