import { createSlice } from "@reduxjs/toolkit"
import {
  createNews,
  deleteNews,
  getAllNews,
  getNewsDetails,
  updateNews,
  updateNewsStatus,
} from "store/actions/newsAction"

const initialState = {
  loading: true,
  isLoading: false,
  perPage: 9,
  totalCount: null,
  totalPages: null,
  currentPage: null,
  news: [],
  newsDetails: {},
}
const newsSlice = createSlice({
  name: "news",
  initialState,
  reducers: {},
  extraReducers: builder => {
    // GET ALL
    builder
      .addCase(getAllNews.pending, state => {
        state.loading = true
      })
      .addCase(getAllNews.fulfilled, (state, action) => {
        state.loading = false
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { data, totalCount, totalPages, currentPage } = action.payload
          state.news = data
          state.totalCount = totalCount
          state.totalPages = totalPages
          state.currentPage = currentPage
        }
      })
      .addCase(getAllNews.rejected, (state, action) => {
        state.loading = false
      })

    // GET DETAILS
    builder
      .addCase(getNewsDetails.pending, state => {
        state.loading = true
        state.newsDetails = {}
      })
      .addCase(getNewsDetails.fulfilled, (state, action) => {
        state.loading = false
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { data } = action.payload
          state.newsDetails = data
        }
      })
      .addCase(getNewsDetails.rejected, (state, action) => {
        state.loading = false
      })

    // UPDATE STATUS
    builder
      .addCase(updateNewsStatus.pending, state => {
        state.isLoading = true
      })
      .addCase(updateNewsStatus.fulfilled, (state, action) => {
        state.isLoading = false
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { id, status } = action.meta.arg
          state.news = state.news.map(item =>
            item._id === id ? { ...item, status: Number(status) } : item
          )
        }
      })
      .addCase(updateNewsStatus.rejected, (state, action) => {
        state.isLoading = false
      })

    // DELETE
    builder
      .addCase(deleteNews.pending, state => {
        state.isLoading = true
      })
      .addCase(deleteNews.fulfilled, (state, action) => {
        state.isLoading = false
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { id } = action.meta.arg
          state.news = state.news.filter(item => item._id !== id)
          if (state.totalCount !== null) {
            state.totalCount -= 1
          }
        }
      })
      .addCase(deleteNews.rejected, (state, action) => {
        state.isLoading = false
      })

    // CREATE
    builder
      .addCase(createNews.pending, state => {
        state.isLoading = true
      })
      .addCase(createNews.fulfilled, (state, action) => {
        state.isLoading = false
      })
      .addCase(createNews.rejected, (state, action) => {
        state.isLoading = false
      })

    // UPDATE
    builder
      .addCase(updateNews.pending, state => {
        state.isLoading = true
      })
      .addCase(updateNews.fulfilled, (state, action) => {
        state.isLoading = false
      })
      .addCase(updateNews.rejected, (state, action) => {
        state.isLoading = false
      })
  },
})

export default newsSlice.reducer
