import * as Yup from "yup"
import {
  getFieldRequiredMessage,
  getGreaterThanZeroMessage,
  getMin6PasswordMessage,
  getMinOneCustomer,
  getMinOneOption,
  getOnlyNumMessage,
  getPasswordError,
  getPasswordMatchMessage,
  getPasswordMinSix,
  getValidArabicMessage,
  getValidEmail,
} from "./validationMessage"

const arabicNameOrNumberPattern = /^[\u0600-\u06FF0-9\s\p{P}\p{S}]+$/u
const onlyNumberPattern = /^[0-9]+$/
const passwordPattern = /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])/

const createSchemaShape = shape => {
  return Yup.object().shape(shape)
}

const addBannerSchema = () => {
  const shape = {
    // title: Yup.string().required(getFieldRequiredMessage()),
    titleAr: Yup.string()
      // .required(getFieldRequiredMessage())
      .matches(arabicNameOrNumberPattern, getValidArabicMessage()),
    image: Yup.string().required(getFieldRequiredMessage()),
    imageAr: Yup.string().when("applySameImageOnBothLang", {
      is: val => val === false,
      then: () => Yup.string().required(getFieldRequiredMessage()),
      otherwise: schema => schema.notRequired(),
    }),
    displayOrder: Yup.string()
      // .required(getFieldRequiredMessage())
      .typeError(getFieldRequiredMessage())
      .matches(onlyNumberPattern, getOnlyNumMessage()),
  }

  return createSchemaShape(shape)
}

const deliveryLetterSchema = () => {
  const shape = {
    file: Yup.string().required(getFieldRequiredMessage()),
  }

  return createSchemaShape(shape)
}

const deedSubmissionSchema = () => {
  const shape = {
    deedFile: Yup.string().required(getFieldRequiredMessage()),
    // warranties: Yup.string().required(getFieldRequiredMessage()),
    // paymentReceipt: Yup.string().required(getFieldRequiredMessage()),
  }

  return createSchemaShape(shape)
}

const createNewsSchema = () => {
  const shape = {
    title: Yup.string().required(getFieldRequiredMessage()),
    titleAr: Yup.string()
      .required(getFieldRequiredMessage())
      .matches(arabicNameOrNumberPattern, getValidArabicMessage()),
    description: Yup.string().required(getFieldRequiredMessage()),
    descriptionAr: Yup.string()
      .required(getFieldRequiredMessage())
      .matches(arabicNameOrNumberPattern, getValidArabicMessage()),
    image: Yup.string().required(getFieldRequiredMessage()),
  }

  return createSchemaShape(shape)
}

const createPartnerSchema = () => {
  const shape = {
    image: Yup.string().required(getFieldRequiredMessage()),
  }

  return createSchemaShape(shape)
}

const pushNotificationSchema = () => {
  const shape = {
    title: Yup.string().required(getFieldRequiredMessage()),
    titleAr: Yup.string()
      .required(getFieldRequiredMessage())
      .matches(arabicNameOrNumberPattern, getValidArabicMessage()),
    body: Yup.string().required(getFieldRequiredMessage()),
    bodyAr: Yup.string()
      .required(getFieldRequiredMessage())
      .matches(arabicNameOrNumberPattern, getValidArabicMessage()),
    customers: Yup.array().when("type", {
      is: val => val === "specific-customers",
      then: () =>
        Yup.array()
          .min(1, getMinOneCustomer())
          .required(getFieldRequiredMessage()),
      otherwise: schema => schema.notRequired(),
    }),
    scheduledTime: Yup.string().when("isScheduled", {
      is: val => val === true,
      then: () => Yup.string().required(getFieldRequiredMessage()),
      otherwise: schema => schema.notRequired(),
    }),
  }
  return createSchemaShape(shape)
}

const smsNotificationSchema = () => {
  const shape = {
    body: Yup.string().required(getFieldRequiredMessage()),
    customers: Yup.array().when("type", {
      is: val => val === "specific-customers",
      then: () =>
        Yup.array()
          .min(1, getMinOneCustomer())
          .required(getFieldRequiredMessage()),
      otherwise: schema => schema.notRequired(),
    }),
  }
  return createSchemaShape(shape)
}
const createMaintenanceUserSchema = mode => {
  var shape = {
    firstName: Yup.string().required(getFieldRequiredMessage()),
    lastName: Yup.string().required(getFieldRequiredMessage()),
    phoneCode: Yup.string().required(getFieldRequiredMessage()),
    phoneNumber: Yup.string().required(getFieldRequiredMessage()),
    nationalId: Yup.string().required(getFieldRequiredMessage()),
    email: Yup.string()
      .email()
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        getValidEmail()
      )
      .required(getFieldRequiredMessage()),
    serviceId: Yup.object().required(getFieldRequiredMessage()),
    serviceItemIds: Yup.array()
      .min(1, getMinOneOption())
      .required(getFieldRequiredMessage()),
    file: Yup.string().required(getFieldRequiredMessage()),
  }

  return createSchemaShape(shape)
}

const createUserSchema = mode => {
  var shape = {
    firstName: Yup.string().required(getFieldRequiredMessage()),
    lastName: Yup.string().required(getFieldRequiredMessage()),
    email: Yup.string()
      .email()
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        getValidEmail()
      )
      .required(getFieldRequiredMessage()),
    // phoneNumber: Yup.string()
    //   .required(getFieldRequiredMessage())
    //   .typeError(getFieldRequiredMessage())
    //   .matches(onlyNumberPattern, getOnlyNumMessage()),

    role: Yup.string().required(getFieldRequiredMessage()),
  }

  return createSchemaShape(shape)
}

const forgetPasswordSchema = mode => {
  var shape = {
    email: Yup.string()
      .email()
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        getValidEmail()
      )
      .required(getFieldRequiredMessage()),
  }

  return createSchemaShape(shape)
}

const verifyPasswordSchema = mode => {
  var shape = {
    code: Yup.string().required(getFieldRequiredMessage()),
  }

  return createSchemaShape(shape)
}

const resetPasswordSchema = mode => {
  var shape = {
    password: Yup.string()
      .min(6, getMin6PasswordMessage())
      .matches(passwordPattern, getPasswordError())
      .required(getFieldRequiredMessage()),
    confirmPassword: Yup.string()
      .required(getFieldRequiredMessage())
      .oneOf([Yup.ref("password"), null], getPasswordMatchMessage()),
  }

  return createSchemaShape(shape)
}

const changePasswordSchema = mode => {
  var shape = {
    password: Yup.string()
      .min(6, getMin6PasswordMessage())
      // .matches(passwordPattern, getPasswordError())
      .required(getFieldRequiredMessage()),
    newPassword: Yup.string()
      .min(6, getMin6PasswordMessage())
      .matches(passwordPattern, getPasswordError())
      .required(getFieldRequiredMessage()),
    confirmPassword: Yup.string()
      .required(getFieldRequiredMessage())
      .oneOf([Yup.ref("newPassword"), null], getPasswordMatchMessage()),
  }

  return createSchemaShape(shape)
}

const updateProfileSchema = mode => {
  var shape = {
    firstName: Yup.string().required(getFieldRequiredMessage()),
    lastName: Yup.string().required(getFieldRequiredMessage()),
    email: Yup.string().required(getFieldRequiredMessage()),
    phoneNumber: Yup.string()
      .required(getFieldRequiredMessage())
      .typeError(getFieldRequiredMessage())
      .matches(onlyNumberPattern, getOnlyNumMessage()),
  }

  return createSchemaShape(shape)
}

const complaintsStatusUpdateSchema = mode => {
  var shape = {
    status: Yup.string().required(getFieldRequiredMessage()),
  }

  return createSchemaShape(shape)
}

const createProjectSchema = mode => {
  var shape = {
    name: Yup.string().required(getFieldRequiredMessage()),
    nameAr: Yup.string()
      .required(getFieldRequiredMessage())
      .matches(arabicNameOrNumberPattern, getValidArabicMessage()),
    numberOfFloor: Yup.string()
      .typeError(getFieldRequiredMessage())
      .matches(onlyNumberPattern, getOnlyNumMessage())
      .required(getFieldRequiredMessage()),
    interface: Yup.string().required(getFieldRequiredMessage()),
    cityId: Yup.string().required(getFieldRequiredMessage()),
    purpose: Yup.string().required(getFieldRequiredMessage()),
    address: Yup.string().required(getFieldRequiredMessage()),
    latitude: Yup.string().required(getFieldRequiredMessage()),
    longitude: Yup.string().required(getFieldRequiredMessage()),
    statusType: Yup.string().required(getFieldRequiredMessage()),
    image: Yup.string().required(getFieldRequiredMessage()),
    description: Yup.string().required(getFieldRequiredMessage()),
    descriptionAr: Yup.string()
      .required(getFieldRequiredMessage())
      .matches(arabicNameOrNumberPattern, getValidArabicMessage()),
  }

  return createSchemaShape(shape)
}

const facilityAndServicesSchema = () => {
  var shape = {
    // facilitiesAndServices: Yup.array()
    //   .of(
    //     Yup.object().shape({
    //       name: Yup.string().required(getFieldRequiredMessage()),
    //       nameAr: Yup.string()
    //         .required(getFieldRequiredMessage())
    //         .matches(arabicNameOrNumberPattern, getValidArabicMessage()),
    //       description: Yup.string().required(getFieldRequiredMessage()),
    //       descriptionAr: Yup.string()
    //         .required(getFieldRequiredMessage())
    //         .matches(arabicNameOrNumberPattern, getValidArabicMessage()),
    //       lists: Yup.array()
    //         .min(1, getMinOneOption())
    //         .required(getFieldRequiredMessage()), // Lists validation
    //       // address: Yup.string().required(getFieldRequiredMessage()),
    //       // latitude: Yup.string().required(getFieldRequiredMessage()),
    //       // longitude: Yup.string().required(getFieldRequiredMessage()),
    //     })
    //   )
    //   .min(1, getMinOneOption()), // Ensuring at least one set of fields
  }

  return createSchemaShape(shape)
}

const nearbyLandmarksSchema = () => {
  var shape = {
    nearByLandMarks: Yup.array()
      .of(
        Yup.object().shape({
          name: Yup.string().required(getFieldRequiredMessage()),
          nameAr: Yup.string()
            .required(getFieldRequiredMessage())
            .matches(arabicNameOrNumberPattern, getValidArabicMessage()),
          distance: Yup.string()
            .typeError(getFieldRequiredMessage())
            .matches(onlyNumberPattern, getOnlyNumMessage())
            .required(getFieldRequiredMessage()),
          distanceUnit: Yup.string().required(getFieldRequiredMessage()),
        })
      )
      .min(1, getMinOneOption()), // Ensuring at least one set of fields
  }

  return createSchemaShape(shape)
}

const createUnitSchema = mode => {
  var shape = {
    name: Yup.string().required(getFieldRequiredMessage()),
    nameAr: Yup.string()
      .required(getFieldRequiredMessage())
      .matches(arabicNameOrNumberPattern, getValidArabicMessage()),
    unitType: Yup.string().required(getFieldRequiredMessage()),
    statusType: Yup.string().required(getFieldRequiredMessage()),
    area: Yup.number()
      .typeError(getFieldRequiredMessage())
      .moreThan(0, getGreaterThanZeroMessage())
      .required(getFieldRequiredMessage()),
    doorPassword: Yup.string().required(getFieldRequiredMessage()),
    areaUnit: Yup.string().required(getFieldRequiredMessage()),
    image: Yup.string().required(getFieldRequiredMessage()),
    address: Yup.string().required(getFieldRequiredMessage()),
    // latitude: Yup.string().required(getFieldRequiredMessage()),
    // longitude: Yup.string().required(getFieldRequiredMessage()),
    unitPrice: Yup.number()
      .typeError(getFieldRequiredMessage())
      .moreThan(0, getGreaterThanZeroMessage())
      .required(getFieldRequiredMessage()),
    realEstateTax: Yup.number()
      .typeError(getFieldRequiredMessage())
      .moreThan(0, getGreaterThanZeroMessage())
      .required(getFieldRequiredMessage()),
    // pursuit: Yup.number()
    //   .typeError(getFieldRequiredMessage())
    //   .moreThan(0, getGreaterThanZeroMessage())
    //   .required(getFieldRequiredMessage()),
    // questTax: Yup.number()
    //   .typeError(getFieldRequiredMessage())
    //   .moreThan(0, getGreaterThanZeroMessage())
    //   .required(getFieldRequiredMessage()),
    description: Yup.string().required(getFieldRequiredMessage()),
    descriptionAr: Yup.string()
      .required(getFieldRequiredMessage())
      .matches(arabicNameOrNumberPattern, getValidArabicMessage()),

    roomDetails: Yup.array()
      .of(
        Yup.object().shape({
          roomType: Yup.string().required(getFieldRequiredMessage()),
          count: Yup.number()
            .typeError(getFieldRequiredMessage())
            .moreThan(0, getGreaterThanZeroMessage())
            .required(getFieldRequiredMessage()),
        })
      )
      .min(1, getMinOneOption()), // Ensuring at least one set of fields
  }
  if (mode === "allUnits") {
    shape = {
      ...shape,
      project: Yup.string().required(getFieldRequiredMessage()),
    }
  }
  if (mode === "updateUnit") {
    shape = {
      ...shape,
      deedNumber: Yup.string().required(getFieldRequiredMessage()),
      document: Yup.string().required(getFieldRequiredMessage()),
    }
  }

  return createSchemaShape(shape)
}

const unitFeatureSchema = () => {
  var shape = {
    gaurantees: Yup.array()
      .of(
        Yup.object().shape({
          serviceId: Yup.string().required(getFieldRequiredMessage()),
          duration: Yup.number()
            .typeError(getFieldRequiredMessage())
            .moreThan(0, getGreaterThanZeroMessage())
            .required(getFieldRequiredMessage()),
          durationUnit: Yup.string().required(getFieldRequiredMessage()),
        })
      )
      .min(1, getMinOneOption()), // Ensuring at least one set of fields
  }

  return createSchemaShape(shape)
}

const cancelBookingSchema = () => {
  const shape = {
    note: Yup.string().required(getFieldRequiredMessage()),
  }

  return createSchemaShape(shape)
}

const paymentContractSchema = () => {
  const shape = {
    file: Yup.string().required(getFieldRequiredMessage()),
    // contents: Yup.string().required(getFieldRequiredMessage()),
  }

  return createSchemaShape(shape)
}

const RETTCertificateUploadSchema = () => {
  const shape = {
    file: Yup.string().required(getFieldRequiredMessage()),
  }

  return createSchemaShape(shape)
}

const generateQrCodeSchema = () => {
  const shape = {
    projectId: Yup.object().required(getFieldRequiredMessage()),
    type: Yup.object().required(getFieldRequiredMessage()),
  }

  return createSchemaShape(shape)
}

const createAttributeSchema = () => {
  const shape = {
    name: Yup.string().required(getFieldRequiredMessage()),
    nameAr: Yup.string()
      .required(getFieldRequiredMessage())
      .matches(arabicNameOrNumberPattern, getValidArabicMessage()),
    type: Yup.string().required(getFieldRequiredMessage()),
    image: Yup.string().required(getFieldRequiredMessage()),
  }

  return createSchemaShape(shape)
}

const createServiceSchema = () => {
  const shape = {
    name: Yup.string().required(getFieldRequiredMessage()),
    nameAr: Yup.string()
      .required(getFieldRequiredMessage())
      .matches(arabicNameOrNumberPattern, getValidArabicMessage()),
    // type: Yup.string().required(getFieldRequiredMessage()),
    image: Yup.string().required(getFieldRequiredMessage()),
  }

  return createSchemaShape(shape)
}

const createServiceItemSchema = () => {
  const shape = {
    name: Yup.string().required(getFieldRequiredMessage()),
    nameAr: Yup.string()
      .required(getFieldRequiredMessage())
      .matches(arabicNameOrNumberPattern, getValidArabicMessage()),
    description: Yup.string().required(getFieldRequiredMessage()),
    descriptionAr: Yup.string()
      .required(getFieldRequiredMessage())
      .matches(arabicNameOrNumberPattern, getValidArabicMessage()),
    image: Yup.string().required(getFieldRequiredMessage()),
    serviceId: Yup.string().required(getFieldRequiredMessage()),
    price: Yup.number()
      .typeError(getFieldRequiredMessage())
      .moreThan(0, getGreaterThanZeroMessage())
      .required(getFieldRequiredMessage()),
  }

  return createSchemaShape(shape)
}

const createRoomTypeSchema = () => {
  const shape = {
    name: Yup.string().required(getFieldRequiredMessage()),
    nameAr: Yup.string()
      .required(getFieldRequiredMessage())
      .matches(arabicNameOrNumberPattern, getValidArabicMessage()),
  }

  return createSchemaShape(shape)
}

const contactInfoSchema = () => {
  const shape = {
    whatsappNumber: Yup.string()
      .required(getFieldRequiredMessage())
      .typeError(getFieldRequiredMessage())
      .matches(onlyNumberPattern, getOnlyNumMessage()),
    phoneNumber: Yup.string()
      .required(getFieldRequiredMessage())
      .typeError(getFieldRequiredMessage())
      .matches(onlyNumberPattern, getOnlyNumMessage()),
    email: Yup.string()
      .email()
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        getValidEmail()
      )
      .required(getFieldRequiredMessage()),
  }

  return createSchemaShape(shape)
}
const bannerDurationSchema = () => {
  const shape = {
    autoScrollInSeconds: Yup.string()
      .required(getFieldRequiredMessage())
      .typeError(getFieldRequiredMessage())
      .matches(onlyNumberPattern, getOnlyNumMessage()),
  }

  return createSchemaShape(shape)
}

const createCitySchema = () => {
  const shape = {
    name: Yup.string().required(getFieldRequiredMessage()),
    nameAr: Yup.string()
      .required(getFieldRequiredMessage())
      .matches(arabicNameOrNumberPattern, getValidArabicMessage()),
  }

  return createSchemaShape(shape)
}

const updateNotificationSchema = () => {
  const shape = {
    title: Yup.string().required(getFieldRequiredMessage()),
    titleAr: Yup.string()
      .required(getFieldRequiredMessage())
      .matches(arabicNameOrNumberPattern, getValidArabicMessage()),
    description: Yup.string().required(getFieldRequiredMessage()),
    descriptionAr: Yup.string()
      .required(getFieldRequiredMessage())
      .matches(arabicNameOrNumberPattern, getValidArabicMessage()),
  }

  return createSchemaShape(shape)
}

export {
  addBannerSchema,
  paymentContractSchema,
  cancelBookingSchema,
  deliveryLetterSchema,
  deedSubmissionSchema,
  createNewsSchema,
  createPartnerSchema,
  pushNotificationSchema,
  smsNotificationSchema,
  createMaintenanceUserSchema,
  createUserSchema,
  forgetPasswordSchema,
  verifyPasswordSchema,
  resetPasswordSchema,
  changePasswordSchema,
  updateProfileSchema,
  complaintsStatusUpdateSchema,
  createProjectSchema,
  facilityAndServicesSchema,
  nearbyLandmarksSchema,
  createUnitSchema,
  unitFeatureSchema,
  generateQrCodeSchema,
  createAttributeSchema,
  createServiceSchema,
  createRoomTypeSchema,
  contactInfoSchema,
  bannerDurationSchema,
  createCitySchema,
  updateNotificationSchema,
  createServiceItemSchema,
  RETTCertificateUploadSchema,
}
