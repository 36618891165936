import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Col, Row } from "reactstrap"
import CustomLabel from "components/Shared/CustomLabel"
import GalleryIcon from "assets/images/icons/gallery.svg"
import TrashIcon from "assets/images/icons/trash.svg"
import {
  updateProject,
  uploadProjectGallery,
} from "store/actions/projectsAction"
import { showAlert } from "store/reducers/alertReducer"
import { useDispatch, useSelector } from "react-redux"
import { updateUnit, uploadUnitGallery } from "store/actions/unitsAction"

const Gallery = ({ actionType, id }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const dispatch = useDispatch()
  const { isLoading, projectDetails } = useSelector(state => state.Projects)
  const { isLoading: isUnitLoading, unitDetails } = useSelector(
    state => state.Units
  )

  const [galleryImages, setGalleryImages] = useState([])
  const [isUploading, setIsUploading] = useState(false)
  const [selectedId, setSelectedId] = useState(null)

  useEffect(() => {
    const details = actionType === "PROJECT" ? projectDetails : unitDetails
    if (details) {
      const { galleryImages } = details
      setGalleryImages(galleryImages)
    }
  }, [projectDetails])

  // Function to validate image dimensions
  const validateImage = file => {
    return new Promise(resolve => {
      const img = new Image()
      img.src = URL.createObjectURL(file) // Create URL for the file

      img.onload = function () {
        // Resolve true if square, else false
        resolve(this.width === this.height)
      }

      img.onerror = function () {
        // Resolve false on error
        resolve(false)
      }
    })
  }

  const handleFileUpload = async event => {
    setIsUploading(true)
    const files = event.target.files

    if (files.length > 0) {
      const formData = new FormData()
      formData.append("id", id)
      let allImagesValid = true // Flag to track if all images are valid

      // Validate all images first
      for (let i = 0; i < files.length; i++) {
        const file = files[i]
        const isValid = await validateImage(file) // Validate each image

        if (!isValid) {
          allImagesValid = false // Set flag to false if any image is invalid
          break // Exit the loop as we found an invalid image
        } else {
          formData.append("galleryImages", file) // Append valid images to formData
        }
      }

      if (!allImagesValid) {
        dispatch(
          showAlert({
            text: t("Please upload square images only"),
            type: "error",
          })
        )
        setIsUploading(false)
        return // Exit if any image is invalid
      }

      // Proceed to upload if all images are valid
      const updatedAction =
        actionType === "PROJECT" ? uploadProjectGallery : uploadUnitGallery
      dispatch(updatedAction(formData))
        .then(action => {
          const { statusCode, success, message, data } = action?.payload
          if (statusCode === 200 && success) {
            setGalleryImages(prevImages => [...(prevImages || []), ...data])
            dispatch(showAlert({ text: message, type: "success" }))
          } else {
            dispatch(showAlert({ text: message, type: "error" }))
          }
        })
        .catch(error => {
          dispatch(
            showAlert({ text: "Error uploading images.", type: "error" })
          )
        })
        .finally(() => {
          setIsUploading(false)
        })
    }
  }

  const handleDeleteImage = imageUrl => {
    setSelectedId(imageUrl)
    const updatedGalleryImages = galleryImages.filter(
      image => image !== imageUrl
    )
    let formData = new FormData()
    formData.append("id", id)

    updatedGalleryImages.forEach((image, index) => {
      formData.append(`galleryImages[${index}]`, image)
    })
    const updatedAction = actionType === "PROJECT" ? updateProject : updateUnit

    dispatch(updatedAction(formData))
      .then(action => {
        const { statusCode, success, message } = action?.payload
        if (statusCode === 200 && success) {
          setGalleryImages(updatedGalleryImages)
          dispatch(showAlert({ text: message, type: "success" }))
        } else {
          dispatch(showAlert({ text: message, type: "error" }))
        }
        setSelectedId(false)
      })
      .catch(error => {})
  }

  return (
    <div className="product_gallery_images">
      <Row>
        <Col lg={12}>
          <div className="form_field">
            <CustomLabel
              htmlFor="image"
              label={t("Upload Gallery Images")}
              isRequired={true}
            />
          </div>
        </Col>
        {galleryImages?.map((image, index) => (
          <Col key={index} lg={3}>
            <div className="single_gallery">
              <img
                src={image}
                className="gallery_image"
                alt={`gallery image ${index + 1}`}
              />
              <img
                src={TrashIcon}
                className={`trash_icon ${
                  (selectedId === image && isLoading) ||
                  (selectedId === image && isUnitLoading)
                    ? "disabled"
                    : ""
                }`}
                alt="trash icon"
                width={12}
                height={12}
                onClick={() => handleDeleteImage(image)}
              />
            </div>
          </Col>
        ))}
        <Col lg={3}>
          <label
            htmlFor="galleryImage"
            className={`file_label g_center position-relative ${
              isUploading ? "disabled" : ""
            }`}
          >
            <img src={GalleryIcon} alt="gallery Icon" className="mx-2" />
            <span>{isUploading ? t("Uploading...") : t("Upload Images")}</span>
          </label>
          <input
            name="galleryImage"
            type="file"
            id="galleryImage"
            disabled={isUploading || isUnitLoading}
            multiple
            accept="image/jpeg, image/jpg, image/png"
            className="form-control d-none"
            onChange={handleFileUpload}
          />
        </Col>
      </Row>
    </div>
  )
}

export default Gallery
