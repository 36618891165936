import { createAsyncThunk } from "@reduxjs/toolkit"
import {
  del,
  formDataPost,
  formDataPut,
  get,
  patch,
  post,
  put,
} from "helpers/api_helper"
import {
  CREATE_UNIT,
  DELETE_UNIT,
  GET_ALL_UNITS,
  GET_UNIT_DETAILS,
  GET_UNITS_LIST,
  UPDATE_UNIT,
  UPDATE_UNIT_STATUS,
  UPLOAD_UNIT_GALLERY,
} from "helpers/url_helper"

export const getAllUnits = createAsyncThunk(GET_ALL_UNITS, async payload => {
  const response = await post(GET_ALL_UNITS, payload)
  return response
})

export const getUnitsList = createAsyncThunk(GET_UNITS_LIST, async payload => {
  const response = await post(GET_UNITS_LIST, payload)
  return response
})

export const updateUnitStatus = createAsyncThunk(
  UPDATE_UNIT_STATUS,
  async payload => {
    const response = await patch(UPDATE_UNIT_STATUS, payload)
    return response
  }
)

export const deleteUnit = createAsyncThunk(DELETE_UNIT, async payload => {
  const response = await del(DELETE_UNIT, payload)
  return response
})

export const createUnit = createAsyncThunk(CREATE_UNIT, async payload => {
  const response = await formDataPost(CREATE_UNIT, payload)
  return response
})

export const getUnitDetails = createAsyncThunk(
  GET_UNIT_DETAILS,
  async payload => {
    const response = await post(GET_UNIT_DETAILS, payload)
    return response
  }
)

export const updateUnit = createAsyncThunk(UPDATE_UNIT, async payload => {
  const response = await formDataPut(UPDATE_UNIT, payload)
  return response
})


export const uploadUnitGallery = createAsyncThunk(
  UPLOAD_UNIT_GALLERY,
  async payload => {
    const response = await formDataPost(UPLOAD_UNIT_GALLERY, payload)
    return response
  }
)
