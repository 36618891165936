import { createSlice } from "@reduxjs/toolkit"
import {
  getAllGlobalSettings,
  updateGlobalSettings,
} from "store/actions/globalSettingsAction"

const initialState = {
  loading: true,
  isLoading: false,
  globalSettings: {},
}
const globalSettings = createSlice({
  name: "global settings",
  initialState,
  reducers: {},
  extraReducers: builder => {
    // GET ALL GLOBAL SETTINGS
    builder
      .addCase(getAllGlobalSettings.pending, state => {
        state.loading = true
      })
      .addCase(getAllGlobalSettings.fulfilled, (state, action) => {
        state.loading = false
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { data } = action.payload
          state.globalSettings = data
        }
      })
      .addCase(getAllGlobalSettings.rejected, (state, action) => {
        state.loading = false
      })

    // UPDATE GLOBAL SETTINGS
    builder
      .addCase(updateGlobalSettings.pending, state => {
        state.isLoading = true
      })
      .addCase(updateGlobalSettings.fulfilled, (state, action) => {
        state.isLoading = false
      })
      .addCase(updateGlobalSettings.rejected, (state, action) => {
        state.isLoading = false
      })
  },
})

export default globalSettings.reducer
