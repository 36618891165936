import { createSlice } from "@reduxjs/toolkit"
import {
  createPartner,
  deletePartner,
  getAllPartners,
  getPartnerDetails,
  updatePartner,
  updatePartnerStatus,
} from "store/actions/partnersAction"

const initialState = {
  loading: true,
  isLoading: false,
  isGettingDetails: false,
  perPage: 9,
  totalCount: null,
  totalPages: null,
  currentPage: null,
  partners: [],
  partnerDetails: {},
}
const partnersSlice = createSlice({
  name: "partners",
  initialState,
  reducers: {},
  extraReducers: builder => {
    // GET ALL
    builder
      .addCase(getAllPartners.pending, state => {
        state.loading = true
      })
      .addCase(getAllPartners.fulfilled, (state, action) => {
        state.loading = false
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { data, totalCount, totalPages, currentPage } = action.payload
          state.partners = data
          state.totalCount = totalCount
          state.totalPages = totalPages
          state.currentPage = currentPage
        }
      })
      .addCase(getAllPartners.rejected, (state, action) => {
        state.loading = false
      })

    // UPDATE STATUS
    builder
      .addCase(updatePartnerStatus.pending, state => {
        state.isLoading = true
      })
      .addCase(updatePartnerStatus.fulfilled, (state, action) => {
        state.isLoading = false
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { id, status } = action.meta.arg
          state.partners = state.partners.map(item =>
            item._id === id ? { ...item, status: Number(status) } : item
          )
        }
      })
      .addCase(updatePartnerStatus.rejected, (state, action) => {
        state.isLoading = false
      })

    // DELETE
    builder
      .addCase(deletePartner.pending, state => {
        state.isLoading = true
      })
      .addCase(deletePartner.fulfilled, (state, action) => {
        state.isLoading = false
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { id } = action.meta.arg
          state.partners = state.partners.filter(item => item._id !== id)
          if (state.totalCount !== null) {
            state.totalCount -= 1
          }
        }
      })
      .addCase(deletePartner.rejected, (state, action) => {
        state.isLoading = false
      })

    // GET  DETAILS
    builder
      .addCase(getPartnerDetails.pending, state => {
        state.isGettingDetails = true
        state.partnerDetails = {}
      })
      .addCase(getPartnerDetails.fulfilled, (state, action) => {
        state.isGettingDetails = false
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { data } = action.payload
          state.partnerDetails = data
        }
      })
      .addCase(getPartnerDetails.rejected, (state, action) => {
        state.isGettingDetails = false
      })

    // CREATE
    builder
      .addCase(createPartner.pending, state => {
        state.isLoading = true
      })
      .addCase(createPartner.fulfilled, (state, action) => {
        state.isLoading = false
        const { statusCode, success, data } = action.payload
        if (statusCode === 200 && success) {
          state.partners = [data, ...state.partners]
          if (state.totalCount !== null) {
            state.totalCount += 1
          }
        }
      })
      .addCase(createPartner.rejected, (state, action) => {
        state.isLoading = false
      })

    // UPDATE
    builder
      .addCase(updatePartner.pending, state => {
        state.isLoading = true
      })
      .addCase(updatePartner.fulfilled, (state, action) => {
        const { statusCode, success, data } = action.payload

        if (statusCode === 200 && success) {
          state.partners = state?.partners?.map(partner =>
            partner._id === data._id ? data : partner
          )
        }
        state.isLoading = false
      })
      .addCase(updatePartner.rejected, (state, action) => {
        state.isLoading = false
      })
  },
})

export default partnersSlice.reducer
