import { createSlice } from "@reduxjs/toolkit"
import {
  getAllTransactions,
  getTransactionDetails,
} from "store/actions/transactionsAction"

const initialState = {
  loading: true,
  isLoading: false,
  perPage: 10,
  totalCount: null,
  totalPages: null,
  currentPage: null,
  transactions: [],
  transactionDetails: {},
}
const transactionsSlice = createSlice({
  name: "transactions",
  initialState,
  reducers: {},
  extraReducers: builder => {
    // GET ALL
    builder
      .addCase(getAllTransactions.pending, state => {
        state.loading = true
      })
      .addCase(getAllTransactions.fulfilled, (state, action) => {
        state.loading = false
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { data, totalCount, totalPages, currentPage } = action.payload
          state.transactions = data
          state.totalCount = totalCount
          state.totalPages = totalPages
          state.currentPage = currentPage
        }
      })
      .addCase(getAllTransactions.rejected, (state, action) => {
        state.loading = false
      })

    // GET DETAILS
    builder
      .addCase(getTransactionDetails.pending, state => {
        state.loading = true
        state.transactionDetails = {}
      })
      .addCase(getTransactionDetails.fulfilled, (state, action) => {
        state.loading = false
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { data } = action.payload
          state.transactionDetails = data
        }
      })
      .addCase(getTransactionDetails.rejected, (state, action) => {
        state.loading = false
      })
  },
})

export default transactionsSlice.reducer
