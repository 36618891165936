import { createAsyncThunk } from "@reduxjs/toolkit"
import { del, get, patch, post, put } from "helpers/api_helper"
import {
  GET_ALL_TRANSACTIONS,
  GET_TRANSACTION_DETAILS,
} from "helpers/url_helper"

export const getAllTransactions = createAsyncThunk(
  GET_ALL_TRANSACTIONS,
  async payload => {
    const response = await post(GET_ALL_TRANSACTIONS, payload)
    return response
  }
)

export const getTransactionDetails = createAsyncThunk(
  GET_TRANSACTION_DETAILS,
  async payload => {
    const response = await post(GET_TRANSACTION_DETAILS, payload)
    return response
  }
)
