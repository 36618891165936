import { commonFilterOptions } from "components/Common/constants"
import Filters from "components/Shared/Filters"
import NoDataAvailable from "components/Shared/NoDataAvailable"
import Title from "components/Shared/Title"
import useFilters from "components/Shared/useFilters"
import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { Card, Col, Container, Row, Table } from "reactstrap"
import {
  DownloadCSV,
  DownloadXLSX,
  getDateRange,
  handleStopPropagation,
} from "components/Common/Utils"
import TrashIcon from "assets/images/icons/trash.svg"
import ViewIcon from "assets/images/icons/view.svg"
import DeleteModal from "components/Common/DeleteModal"
import Switch from "components/Shared/Switch"
import Paginations from "components/Common/Pagination"
import { useDispatch, useSelector } from "react-redux"
import {
  deleteCustomer,
  getAllCustomers,
  updateCustomerStatus,
} from "store/actions/customersAction"
import Spinners from "components/Shared/Spinners"
import FormateDate from "components/Shared/FormateDate"
import { showAlert } from "store/reducers/alertReducer"
import { exportData } from "helpers/api_helper"
import { EXPORT_DATA } from "helpers/url_helper"

const Customers = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  document.title = t("Customers | Takween")

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { search } = useLocation()
  const pageNumber = Number(new URLSearchParams(search).get("page")) || 1

  const {
    loading,
    isLoading,
    customers,
    totalCount,
    totalPages,
    currentPage,
    perPage,
  } = useSelector(state => state.Customers)

  const [
    filters,
    keyword,
    setKeyword,
    dateRange,
    setDateRange,
    flatpickrRef,
    selectedId,
    setSelectedId,
    itemIdToDelete,
    setItemIdToDelete,
    showDeleteModal,
    setShowDeleteModal,
    isApiCalled,
    setIsApiCalled,
    handleFilterChange,
    resetFilters,
  ] = useFilters({
    status: -1,
    orderBy: 2,
  })

  useEffect(() => {
    fetchAllCustomers()
  }, [])

  const fetchAllCustomers = page => {
    const { status, orderBy } = filters

    const payload = {
      perPage: perPage,
      pageNo: page || pageNumber,
      status: status,
      orderBy,
      keyword,
      dateRange: getDateRange(dateRange),
    }

    dispatch(getAllCustomers(payload))
    setIsApiCalled(true)

    navigate(`/customers?page=${page || pageNumber}`)
  }

  useEffect(() => {
    if (isApiCalled) {
      handleSearch()
    }
  }, [keyword, filters])

  useEffect(() => {
    if (isApiCalled) {
      if (dateRange.min && dateRange.max) handleSearch()
    }
  }, [dateRange])

  const handleSearch = () => {
    fetchAllCustomers(1)
  }

  const onPageChange = page => {
    fetchAllCustomers(page)
  }

  const handleStatusClick = (id, status) => {
    setSelectedId(id)
    const payload = {
      id,
      status: status === 1 ? "0" : "1",
    }
    dispatch(updateCustomerStatus(payload))
      .then(action => {
        const { statusCode, success, message } = action?.payload
        if (statusCode === 200 && success) {
          dispatch(showAlert({ text: message, type: "success" }))
          setSelectedId(null)
        } else {
          dispatch(showAlert({ text: message, type: "error" }))
        }
      })
      .catch(error => {})
  }

  const handleDelete = () => {
    const payload = {
      id: itemIdToDelete,
      status: "2", // 2 FOR DELETE
    }

    dispatch(deleteCustomer(payload))
      .then(action => {
        const { statusCode, success, message } = action?.payload
        if (statusCode === 200 && success) {
          dispatch(showAlert({ text: message, type: "success" }))
          setItemIdToDelete(null)
          setShowDeleteModal(false)
        } else {
          dispatch(showAlert({ text: message, type: "error" }))
        }
      })
      .catch(error => {})
  }

  const getExportFilters = () => {
    const { status } = filters

    return {
      status,
      dateRange: getDateRange(dateRange),
    }
  }

  const handleDownloadCSV = async () => {
    const payload = {
      type: "User",
      exportType: "csv",
      filter: getExportFilters(),
    }

    const response = await exportData(EXPORT_DATA, payload)
    if (response) {
      const csvData = response
      DownloadCSV(csvData, t("Customers"))
    }
  }

  const handleDownloadXLSX = async () => {
    const payload = {
      type: "User",
      exportType: "xlsx",
      filter: getExportFilters(),
    }

    const response = await exportData(EXPORT_DATA, payload)
    if (response) {
      const csvData = response
      DownloadXLSX(csvData, t("Customers"))
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg={12}>
              <Card>
                <Title
                  title={t("Customers")}
                  isExport={true}
                  handleDownloadCSV={handleDownloadCSV}
                  handleDownloadXLSX={handleDownloadXLSX}
                />
                <Filters
                  isGlobalFilter={true}
                  SearchPlaceholder={t("Search")}
                  keyword={keyword}
                  setKeyword={setKeyword}
                  filters={commonFilterOptions}
                  selectedFilters={filters}
                  handleFilterChange={handleFilterChange}
                  handleReset={() => resetFilters()}
                  isRangeDate={true}
                  flatpickrRef={flatpickrRef}
                  dateRange={dateRange}
                  setDateRange={setDateRange}
                  isResetButton={true}
                />
                {loading ? (
                  <Spinners />
                ) : customers.length === 0 ? (
                  <NoDataAvailable />
                ) : (
                  <div className="table-responsive">
                    <Table className="table mb-0">
                      <thead className="full_th">
                        <tr>
                          <th>{t("#")}</th>
                          <th>{t("Name")}</th>
                          <th>{t("Email")}</th>
                          <th>{t("Phone")}</th>
                          <th>{t("Join Date")}</th>
                          <th>{t("Status")}</th>
                          <th>{t("Action")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {customers.map((item, index) => (
                          <tr
                            key={item._id}
                            className="cursor-pointer"
                            onClick={() => {
                              navigate(`/customers/details?id=${item._id}`)
                            }}
                          >
                            <th scope="row">
                              {(currentPage - 1) * perPage + index + 1}
                            </th>
                            <td>{item.name || "-"}</td>
                            <td>{item.email || "-"}</td>
                            <td>{item.phone || "-"}</td>
                            <td>{FormateDate(item.createdAt)}</td>
                            <td>
                              {item.status === 1 ? (
                                <p className="rended_status status_tag">
                                  {t("Active")}
                                </p>
                              ) : item.status === 0 ? (
                                <p className="reject_status status_tag">
                                  {t("Deactive")}
                                </p>
                              ) : (
                                "-"
                              )}
                            </td>
                            <td onClick={handleStopPropagation}>
                              <div className="d-flex align-items-center">
                                <img
                                  src={TrashIcon}
                                  alt="Trash Icon"
                                  width={24}
                                  height={24}
                                  className="cursor-pointer mx-3"
                                  onClick={() => {
                                    setItemIdToDelete(item._id)
                                    setShowDeleteModal(true)
                                  }}
                                />
                                <img
                                  src={ViewIcon}
                                  alt="View Icon"
                                  width={24}
                                  height={24}
                                  className="cursor-pointer me-3"
                                  onClick={() =>
                                    navigate(
                                      `/customers/details?id=${item._id}`
                                    )
                                  }
                                />
                                <Switch
                                  onClickHandler={() => {
                                    handleStatusClick(item._id, item.status)
                                  }}
                                  isChecked={item.status === 1 ? true : false}
                                  disabled={
                                    isLoading && selectedId === item._id
                                  }
                                />
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                )}
              </Card>
            </Col>
            {!loading && customers.length > 0 && (
              <Paginations
                currentPage={currentPage}
                totalPages={totalPages}
                totalCount={totalCount}
                onPageChange={onPageChange}
              />
            )}
          </Row>
        </Container>
      </div>
      <DeleteModal
        show={showDeleteModal}
        onDeleteClick={handleDelete}
        onCloseClick={() => {
          setItemIdToDelete(null)
          setShowDeleteModal(false)
        }}
        isLoading={isLoading}
        buttonText={t("Delete")}
        bodyTitle={t("Are you sure you want to delete this {{name}}?", {
          name: t("Customer"),
        })}
      />
    </React.Fragment>
  )
}

export default Customers
