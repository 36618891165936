import { createAsyncThunk } from "@reduxjs/toolkit"
import { del, get, patch, post, put } from "helpers/api_helper"
import { DASHBOARD_DATA, PAYMENT_METHOD_REPORT, SALES_REPORT } from "helpers/url_helper"

export const getDashboardData = createAsyncThunk(
  DASHBOARD_DATA,
  async payload => {
    const response = await post(DASHBOARD_DATA, payload)
    return response
  }
)

export const getSalesReport = createAsyncThunk(SALES_REPORT, async payload => {
  const response = await post(SALES_REPORT, payload)
  return response
})

export const getPaymentMethodReport = createAsyncThunk(PAYMENT_METHOD_REPORT, async payload => {
  const response = await post(PAYMENT_METHOD_REPORT, payload)
  return response
})
