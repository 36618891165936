import React from "react"
import { format, isValid, parseISO } from "date-fns"

const FormateDate = (date = "") => {
  // Parse the date and check if it's valid

  if (date == null) {
    return "-"
  }

  const parsedDate = parseISO(date)

  if (!isValid(parsedDate)) {
    return null
  }

  return format(parsedDate, "MMMM dd, yyyy hh:mm a")
}

export default FormateDate
