import React from "react"
import { Spinner } from "reactstrap"

const ButtonLoader = ({ text, loading }) => {
  return (
    <button
      className="btn btn-primary btn-block"
      type="submit"
      disabled={loading}
    >
      {text}
      {loading && <Spinner color="white mx-3 button_spinner" />}
    </button>
  )
}

export default ButtonLoader
