import React, { useEffect, useState } from "react"
import { resetPasswordSchema } from "components/Common/validation"
import PublicNavbar from "components/CommonForBoth/PublicNavbar"
import CustomErrorMessage from "components/Shared/CustomErrorMessage"
import CustomLabel from "components/Shared/CustomLabel"
import Spinners from "components/Shared/Spinners"
import { Field, Form, Formik } from "formik"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { Container } from "reactstrap"
import { resetPasswordForNewUser, validateUUID } from "store/actions/authAction"
import { showAlert } from "store/reducers/alertReducer"

const ResetPassword = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  document.title = t("Reset Password | Takween")

  const { uuid } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const { isLoading } = useSelector(state => state.Login)
  const [uuidValidateInfo, setUuidValidateInfo] = useState({})

  useEffect(() => {
    uuid && validateUuid()
  }, [])

  const validateUuid = async () => {
    const payload = {
      uuid,
    }
    dispatch(validateUUID(payload))
      .then(action => {
        const { statusCode, success, message, data } = action?.payload
        if (statusCode === 200 && success) {
          setUuidValidateInfo(data)
        } else {
          dispatch(showAlert({ text: message, type: "error" }))
          navigate("/login")
        }
      })
      .catch(error => {})
  }

  const handleNewPassword = async (values, { resetForm }) => {
    const { password, confirmPassword } = values

    const { _id } = uuidValidateInfo

    const payload = {
      uuid,
      id: _id,
      newPassword: password,
      confirmPassword,
    }

    dispatch(resetPasswordForNewUser(payload))
      .then(action => {
        const { statusCode, success, message, data } = action?.payload
        if (statusCode === 200 && success) {
          dispatch(showAlert({ text: message, type: "success" }))
          navigate("/login")
        } else {
          dispatch(showAlert({ text: message, type: "error" }))
        }
      })
      .catch(error => {})
  }

  if (loading) return <Spinners />

  return (
    <React.Fragment>
      <PublicNavbar />
      <div className="account-pages g_center h_100_75 forget_password_page">
        <Container>
          <div className="g_center">
            <div className="forget_password_inner">
              <Formik
                initialValues={{ password: "", confirmPassword: "" }}
                validationSchema={resetPasswordSchema}
                onSubmit={handleNewPassword}
              >
                {({ values, errors, touched }) => (
                  <Form>
                    <h1>{t("Reset Password")}</h1>
                    <div>
                      <CustomLabel
                        htmlFor="password"
                        label={t("New Password")}
                        isRequired={true}
                      />

                      <Field
                        name="password"
                        type="password"
                        id="password"
                        placeholder={t("New Password")}
                        className={`form-control ${
                          touched.password && errors.password
                            ? "is-invalid"
                            : ""
                        }`}
                      />
                      <CustomErrorMessage name={"password"} />
                    </div>
                    <div>
                      <CustomLabel
                        htmlFor="confirmPassword"
                        label={t("Confirm New Password")}
                        isRequired={true}
                      />
                      <Field
                        name="confirmPassword"
                        type="password"
                        id="confirmPassword"
                        placeholder={t("Confirm New Password")}
                        className={`form-control ${
                          touched.confirmPassword && errors.confirmPassword
                            ? "is-invalid"
                            : ""
                        }`}
                      />
                      <CustomErrorMessage name={"confirmPassword"} />
                    </div>
                    <button type="submit" disabled={isLoading}>
                      {t("Reset")}
                    </button>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ResetPassword
