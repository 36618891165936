import React, { useEffect } from "react"
import {
  Badge,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Table,
} from "reactstrap"
import { useTranslation } from "react-i18next"
import BackButton from "components/Shared/BackButton"
import TopTitle from "components/Shared/TopTitle"
import DetailsInnerDiv from "components/Shared/DetailsInnerDiv"
import DetailsTitle from "components/Shared/DetailsTitle"
import { useDispatch, useSelector } from "react-redux"
import { useLocation } from "react-router-dom"
import { getNewsDetails } from "store/actions/newsAction"
import NoDataAvailable from "components/Shared/NoDataAvailable"
import { ShimmerThumbnail } from "react-shimmer-effects"
import { getAttributeDetails } from "store/actions/attributeAction"
import { attributeTypeOptions } from "components/Common/constants"

const AttributeDetails = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  document.title = t("Attribute Details | Takween")

  const dispatch = useDispatch()
  const { loading, attributeDetails } = useSelector(state => state.Attributes)
  const name = useSelector(state => state.Login.userProfile?.name) || ""

  const { search } = useLocation()
  const id = new URLSearchParams(search).get("id")

  useEffect(() => {
    const fetchDetails = () => {
      const payload = {
        id,
      }
      dispatch(getAttributeDetails(payload))
    }
    fetchDetails()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <TopTitle
            title={t("Attribute Details")}
            subTitle={t(
              `Hi, {{name}}. Welcome back to your Marketing portal!`,
              { name: name }
            )}
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <DetailsTitle title={t("Attribute Details")} />
                  {loading ? (
                    <ShimmerThumbnail height={400} rounded className={"mb-0"} />
                  ) : Object.values(attributeDetails).length === 0 ? (
                    <p className="text-center my-3">
                      <NoDataAvailable />
                    </p>
                  ) : (
                    <Row className="g_details">
                      <Col lg={4}>
                        <div className="d-flex mt-3">
                          <div>
                            <p className="fs_14_600 opacity-50 mb-2">
                              {t("Icon")}
                            </p>
                            {attributeDetails.imageUrl ? (
                              <img
                                src={attributeDetails.imageUrl}
                                alt=""
                                className="img-fluid rounded-3"
                                style={{ objectFit: "contain" }}
                              />
                            ) : (
                              "-"
                            )}
                          </div>
                        </div>
                      </Col>
                      <Col md={4}>
                        <DetailsInnerDiv
                          label={t("Id")}
                          value={attributeDetails._id}
                        />
                      </Col>
                      <Col md={4}>
                        <DetailsInnerDiv
                          label={t("Name")}
                          value={attributeDetails.name}
                        />
                      </Col>
                      <Col md={4}>
                        <DetailsInnerDiv
                          label={t("Status")}
                          value={
                            attributeDetails.status === 1
                              ? t("Active")
                              : t("Deactive")
                          }
                          valueClass={`${
                            attributeDetails.status === 1
                              ? "active_color"
                              : "danger_color"
                          }`}
                        />
                      </Col>
                      <Col md={4}>
                        <DetailsInnerDiv
                          label={t("Type")}
                          value={t(
                            attributeTypeOptions.find(
                              att => att.value === attributeDetails.type
                            )?.label || "-"
                          )}
                        />
                      </Col>
                    </Row>
                  )}{" "}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AttributeDetails
