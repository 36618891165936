import React, { useEffect, useState } from "react"
import CustomErrorMessage from "components/Shared/CustomErrorMessage"
import CustomField from "components/Shared/CustomField"
import CustomLabel from "components/Shared/CustomLabel"
import Spinners from "components/Shared/Spinners"
import TopTitle from "components/Shared/TopTitle"
import { Field, Form, Formik } from "formik"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useSearchParams } from "react-router-dom"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import { attributeTypeOptions } from "components/Common/constants"
import FileUpload from "components/Shared/FileUpload"
import { handleFileUpload_24_24 } from "components/Common/Utils"
import FormButtons from "components/Shared/FormButtons"
import { createAttributeSchema } from "components/Common/validation"
import {
  createAttribute,
  getAttributeDetails,
  updateAttribute,
} from "store/actions/attributeAction"
import { showAlert } from "store/reducers/alertReducer"

const createAttributeValues = {
  name: "",
  nameAr: "",
  type: "",
  image: "",
}

const CreateAttribute = ({ mode }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  document.title =
    mode === "create"
      ? t("Create Attribute | Takween")
      : t("Update Attribute | Takween")

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()
  const id = searchParams.get("id")

  const { loading, isLoading, attributeDetails } = useSelector(
    state => state.Attributes
  )
  const name = useSelector(state => state.Login.userProfile?.name) || ""

  const [formValues, setFormValues] = useState(createAttributeValues)

  useEffect(() => {
    const fetchDetails = () => {
      const payload = {
        id,
      }
      dispatch(getAttributeDetails(payload))
    }
    mode === "update" && fetchDetails()
  }, [])

  useEffect(() => {
    if (attributeDetails && mode === "update") {
      const { name, nameAr, type, imageUrl } = attributeDetails

      setFormValues(prev => ({
        name,
        nameAr,
        type,
        image: imageUrl,
      }))
    }
  }, [mode, attributeDetails])

  const handleOnSubmit = values => {
    const { name, nameAr, type, image } = values

    let data = new FormData()
    data.append("name", name)
    data.append("nameAr", nameAr)
    data.append("type", type)
    data.append("image", image)

    if (mode === "create") {
      dispatch(createAttribute(data))
        .then(action => {
          const { statusCode, success, message } = action?.payload
          if (statusCode === 200 && success) {
            dispatch(showAlert({ text: message, type: "success" }))
            navigate("/attributes")
          } else {
            dispatch(showAlert({ text: message, type: "error" }))
          }
        })
        .catch(error => {})
    } else if (mode === "update") {
      data.append("id", id)
      dispatch(updateAttribute(data))
        .then(action => {
          const { statusCode, success, message } = action?.payload
          if (statusCode === 200 && success) {
            dispatch(showAlert({ text: message, type: "success" }))
            navigate("/attributes")
          } else {
            dispatch(showAlert({ text: message, type: "error" }))
          }
        })
        .catch(error => {})
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        {mode === "update" && loading ? (
          <Spinners />
        ) : (
          <Container fluid>
            <TopTitle
              title={t("Attribute")}
              subTitle={t(
                `Hi, {{name}}. Welcome back to your Marketing portal!`,
                { name: name }
              )}
            />
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <h2 className="fs_18_700">
                      {mode === "create"
                        ? t("Create Attribute")
                        : t("Update Attribute")}
                    </h2>
                    <Formik
                      initialValues={formValues}
                      enableReinitialize={true}
                      validationSchema={createAttributeSchema()}
                      onSubmit={handleOnSubmit}
                    >
                      {({ values, errors, touched, setFieldValue }) => (
                        <Form>
                          <Row>
                            <Col lg={6}>
                              <div className="form_field">
                                <CustomLabel
                                  htmlFor="name"
                                  label={t("Name(English)")}
                                  isRequired={true}
                                />
                                <CustomField
                                  name="name"
                                  type="text"
                                  placeholder={t("Name(English)")}
                                  touched={touched}
                                  errors={errors}
                                />
                              </div>
                            </Col>
                            <Col lg={6}>
                              <div className="form_field">
                                <CustomLabel
                                  htmlFor="nameAr"
                                  label={t("Name(Arabic)")}
                                  isRequired={true}
                                />
                                <CustomField
                                  name="nameAr"
                                  type="text"
                                  isArabic={true}
                                  placeholder={t("Name(Arabic)")}
                                  touched={touched}
                                  errors={errors}
                                />
                              </div>
                            </Col>
                            <Col lg={6}>
                              <div className="form_field">
                                <CustomLabel
                                  htmlFor="type"
                                  label={t("Select Type")}
                                  isRequired={true}
                                />
                                <div className="select_wrapper">
                                  <Field
                                    as="select"
                                    name="type"
                                    className={`form-control b_r_12 ${
                                      errors.type && touched.type
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    onChange={e => {
                                      const selectedValue = e.target.value
                                      setFieldValue("type", selectedValue)
                                    }}
                                  >
                                    <option hidden>{t("Select Type")}</option>
                                    {attributeTypeOptions?.map(item => (
                                      <option
                                        key={item.value}
                                        value={item.value}
                                      >
                                        {t(item.label)}
                                      </option>
                                    ))}
                                  </Field>
                                </div>
                                <CustomErrorMessage name={"type"} />
                              </div>
                            </Col>
                            <Col lg={6}>
                              <div className="form_field">
                                <CustomLabel
                                  htmlFor="image"
                                  label={t("Upload Icon")}
                                  isRequired={true}
                                />
                                <FileUpload
                                  name="image"
                                  value={values.image}
                                  touched={touched.image}
                                  error={errors.image}
                                  height={46}
                                  labelText={t("Upload Icon")}
                                  setFieldValue={setFieldValue}
                                  handleFileUpload={event =>
                                    handleFileUpload_24_24(
                                      event,
                                      setFieldValue,
                                      "image"
                                    )
                                  }
                                />
                                <CustomErrorMessage name={"image"} />
                              <p className="fs_14_500 opacity-75">
                                {t(
                                  "Recommended Image Size: Square (e.g., 24x24 px)"
                                )}
                              </p>
                              </div>
                            </Col>
                            <Col lg={12}>
                              <FormButtons
                                buttonText={
                                  mode === "create" ? t("Create") : t("Update")
                                }
                                isLoading={isLoading}
                              />
                            </Col>
                          </Row>
                        </Form>
                      )}
                    </Formik>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        )}
      </div>
    </React.Fragment>
  )
}

export default CreateAttribute
