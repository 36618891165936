import React, { useEffect, useState } from "react"
import { Line, Pie } from "react-chartjs-2"
import { useTranslation } from "react-i18next"
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js"
import { useDispatch, useSelector } from "react-redux"
import { ShimmerThumbnail } from "react-shimmer-effects"
import { getDashboardData } from "store/actions/commonAction"
import { getDashboardDateRange } from "components/Common/Utils"
import Select from "react-select"
import { customerSupportStatusOptions } from "components/Common/constants"
import ResetWhite from "assets/images/icons/resetWhite.svg"

const CustomerSupportDashboard = ({ dateRange, setDateRange }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  const dispatch = useDispatch()

  const { loading, dashboardData } = useSelector(state => state.Common)

  const [status, setStatus] = useState(-1)
  const [resetting, setResetting] = useState(false)
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: t("Ticket Status"),
        data: [],
        backgroundColor: [],
        borderColor: [],
        borderWidth: 1,
      },
    ],
  })

  const [lineChartData, setLineChartData] = useState({
    labels: [], // Month-Year labels
    datasets: [
      {
        label: t("Total Tickets"),
        data: [],
        backgroundColor: "rgba(12, 53, 83, 0.2)",
        borderColor: "#0C3553",
        pointBackgroundColor: "#0C3553",
        pointBorderColor: "#0C3553",
        borderWidth: 2,
        tension: 0.4,
        fill: true,
      },
    ],
  })

  const statusColors = {
    1: "#FCB859", // Pending
    2: "#0C3553", // Open
    3: "#D5B086", // In progress
    4: "#A9DFD8", // Resolved
    5: "#E26AD3", // Closed
    6: "#FF3D00", // Rejected
  }

  useEffect(() => {
    if (!resetting) {
      fetchDashboardData()
    }
  }, [status])

  const fetchDashboardData = ({ isReset = false } = {}) => {
    const updatedStatus = isReset ? -1 : status.value || -1
    const payload = {
      type: "customer-service",
      filter: {
        status: updatedStatus,
        dateRange: isReset ? {} : getDashboardDateRange(dateRange),
      },
    }
    dispatch(getDashboardData(payload))
  }

  useEffect(() => {
    // Check if dashboardData is available
    if (dashboardData && dashboardData.statusBreakdown) {
      const { statusBreakdown } = dashboardData

      // Create labels and data arrays
      const labels = []
      const data = []
      const backgroundColor = []
      const borderColor = []

      // Loop through the status breakdown and map to labels and counts
      statusBreakdown.forEach(item => {
        const statusOption = customerSupportStatusOptions?.find(
          option => option.value === item.status
        )
        if (statusOption) {
          labels.push(t(statusOption.label))
          data.push(item.totalCount)

          backgroundColor.push(statusColors[item.status])
          borderColor.push(statusColors[item.status])
        }
      })

      setChartData({
        labels,
        datasets: [
          {
            ...chartData.datasets[0],
            data,
            backgroundColor,
            borderColor,
          },
        ],
      })
    }
  }, [dashboardData, language])

  useEffect(() => {
    if (dashboardData && dashboardData.ticketsByMonth) {
      const { ticketsByMonth } = dashboardData

      // Define month names
      const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ]
      const labels = []
      const totalCounts = []

      // Populate labels and total counts for each month
      ticketsByMonth.forEach(item => {
        const monthLabel = `${monthNames[item.month - 1]} ${item.year}`
        labels.push(monthLabel) // Month-Year label
        totalCounts.push(item.totalCount) // Total ticket count for that month
      })

      // Update line chart data state
      setLineChartData({
        labels,
        datasets: [
          {
            ...lineChartData.datasets[0],
            data: totalCounts,
          },
        ],
      })
    }
  }, [dashboardData])

  useEffect(() => {
    if (dateRange[0] && dateRange[1]) {
      fetchDashboardData()
    }
  }, [dateRange])

  const lineOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
        labels: {
          font: {
            size: 14,
          },
          color: "#7990a0",
          usePointStyle: true,
          pointStyle: "circle",
          boxWidth: 10,
          boxHeight: 10,
        },
      },
    },
  }

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
        labels: {
          font: {
            size: 14,
          },
          color: "#7990a0",
          usePointStyle: true,
          pointStyle: "circle",
          boxWidth: 10,
          boxHeight: 10,
        },
      },
    },
  }

  const handleReset = () => {
    setResetting(true)
    setDateRange([])
    setStatus(-1)
    fetchDashboardData({ isReset: true })
    setTimeout(() => {
      setResetting(false)
    }, 1000)
  }

  return (
    <>
      <Card>
        <CardBody>
          <Row>
            <Col lg={8}>
              <h2 className="fs_24"> {t("Choose Status")} </h2>
            </Col>
            <Col>
              <div>
                <Select
                  value={status}
                  // closeMenuOnSelect={false}
                  onChange={selectedOptions => setStatus(selectedOptions)}
                  options={customerSupportStatusOptions?.map(item => ({
                    label: t(item.label),
                    value: item.value,
                  }))}
                  placeholder={t("Choose Status")}
                  className="select2-selection"
                />
              </div>
            </Col>
            <Col style={{ maxWidth: 60, paddingLeft: 6 }}>
              <button
                className="reset_btn dashboard_reset_btn"
                onClick={handleReset}
              >
                <img src={ResetWhite} alt="" />
              </button>
            </Col>
          </Row>
        </CardBody>
      </Card>
      {loading ? (
        <Row>
          {[0, 1]?.map((item, index) => (
            <Col lg={6} key={index}>
              <Card>
                <CardBody>
                  <ShimmerThumbnail height={450} rounded className={"mb-0"} />
                </CardBody>
              </Card>
            </Col>
          ))}
        </Row>
      ) : (
        <Row>
          <Col lg={6}>
            <Card>
              <CardBody>
                <h2 className="fs_24"> {t("Ticket Status Break Down")} </h2>
                <div
                  style={{ height: 400, direction: "ltr" }}
                  className="d-flex"
                >
                  <Pie data={chartData} options={options} />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg={6}>
            <Card className="ticket_over_time_chart">
              <CardBody>
                <h2 className="fs_24"> {t("Tickets By Month")} </h2>
                <div className="d-block mx-auto" style={{ direction: "ltr" }}>
                  <Line
                    data={lineChartData}
                    options={lineOptions}
                    height={300}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
    </>
  )
}

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)

export default CustomerSupportDashboard
