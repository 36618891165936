import React from "react"
import { Link } from "react-router-dom"

const SidebarLink = ({
  to = "#",
  icon = "",
  activeIcon = "",
  label = "",
  className = "",
}) => {
  return (
    <Link to={to} className={className}>
      <img src={icon} alt="" width={24} height={24} className="primary_icon" />
      <img
        src={activeIcon}
        alt=""
        width={24}
        height={24}
        className="hover_icon"
      />
      <span>{label}</span>
    </Link>
  )
}

export default SidebarLink
