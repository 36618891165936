import { createAsyncThunk } from "@reduxjs/toolkit"
import { del, get, patch, post, put } from "helpers/api_helper"
import { GET_GLOBAL_SETTINGS, UPDATE_GLOBAL_SETTINGS } from "helpers/url_helper"

export const getAllGlobalSettings = createAsyncThunk(
  GET_GLOBAL_SETTINGS,
  async payload => {
    const response = await post(GET_GLOBAL_SETTINGS, payload)
    return response
  }
)
export const updateGlobalSettings = createAsyncThunk(
  UPDATE_GLOBAL_SETTINGS,
  async payload => {
    const response = await put(UPDATE_GLOBAL_SETTINGS, payload)
    return response
  }
)
