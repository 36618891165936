import { notificationActionTypeOptions } from "components/Common/constants"
import DetailsInnerDiv from "components/Shared/DetailsInnerDiv"
import DetailsTitle from "components/Shared/DetailsTitle"
import NoDataAvailable from "components/Shared/NoDataAvailable"
import TopTitle from "components/Shared/TopTitle"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useLocation } from "react-router-dom"
import { ShimmerThumbnail } from "react-shimmer-effects"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import { getNotificationDetails } from "store/actions/notificationsActions"

const NotificationDetails = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  document.title = t("Notification Details | Takween")

  const dispatch = useDispatch()
  const { isGettingDetails, notificationDetails } = useSelector(
    state => state.Notifications
  )
  const name = useSelector(state => state.Login.userProfile?.name) || ""

  const { search } = useLocation()
  const id = new URLSearchParams(search).get("id")

  useEffect(() => {
    const fetchDetails = () => {
      const payload = {
        id,
      }
      dispatch(getNotificationDetails(payload))
    }
    fetchDetails()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content customer_details">
        <Container fluid>
          <TopTitle
            title={t("Notification Details")}
            subTitle={t(
              `Hi, {{name}}. Welcome back to your Marketing portal!`,
              { name: name }
            )}
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <DetailsTitle title={t("Notification Details")} />

                  <div>
                    {isGettingDetails ? (
                      <ShimmerThumbnail
                        height={350}
                        rounded
                        className={"mb-0"}
                      />
                    ) : Object.values(notificationDetails).length === 0 ? (
                      <p className="text-center my-3">
                        <NoDataAvailable />
                      </p>
                    ) : (
                      <>
                        <Row className="g_details">
                          <Col md={4}>
                            <DetailsInnerDiv
                              label={t("Id")}
                              value={notificationDetails._id}
                            />
                          </Col>
                          <Col md={4}>
                            <DetailsInnerDiv
                              label={t("Action Type")}
                              value={t(
                                notificationActionTypeOptions.find(
                                  item =>
                                    item.value ===
                                    notificationDetails?.actionType
                                )?.label || "-"
                              )}
                            />
                          </Col>
                          <Col md={4}>
                            <DetailsInnerDiv
                              label={t("Status")}
                              value={
                                notificationDetails.status === 1
                                  ? t("Active")
                                  : t("Deactive")
                              }
                              valueClass={
                                notificationDetails.status === 1
                                  ? "active_color"
                                  : "danger_color"
                              }
                            />
                          </Col>
                          <Col md={12}>
                            <DetailsInnerDiv
                              label={t("Title")}
                              value={notificationDetails?.title?.en || "-"}
                            />
                          </Col>
                          <Col md={12}>
                            <DetailsInnerDiv
                              label={t("Description")}
                              value={notificationDetails?.body?.en || "-"}
                            />
                          </Col>
                          <Col md={12}>
                            <DetailsInnerDiv
                              label={t("Remark")}
                              value={notificationDetails?.remarks || "-"}
                            />
                          </Col>
                        </Row>
                      </>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default NotificationDetails
