import Tooltip from "components/Shared/Tooltip"
import React from "react"
import { Card, CardBody, CardTitle, Col } from "reactstrap"
import { Link, useNavigate } from "react-router-dom"
import Switch from "components/Shared/Switch"
import TrashIcon from "assets/images/icons/trash.svg"
import EditIcon from "assets/images/icons/edit.svg"
import { useTranslation } from "react-i18next"

const SingleNews = ({
  t,
  news,
  setShowDeleteModal,
  handleStatusClick,
  selectedId,
  isLoading,
  setItemIdToDelete,
}) => {
  const {
    i18n: { language },
  } = useTranslation()
  const navigate = useNavigate()
  return (
    <Col md={4}>
      <Card className="border rounded-4">
        <CardBody>
          <div className="single_banner">
            <Link to={`/news/news-details?id=${news._id}`}>
              <h2 className="fs_18_700 in_2_line">
                {language === "sa" ? news.titleAr : news.title}
              </h2>
              <img
                src={news.imageUrl}
                alt=""
                className="banner_img"
                height={250}
              />
            </Link>
            <div className="mt-3 g_between">
              <div>
                <img
                  src={EditIcon}
                  id={`edit_${news._id}`}
                  alt="Edit Icon"
                  width={24}
                  height={24}
                  className="cursor-pointer"
                  onClick={() => navigate(`/news/update?id=${news._id}`)}
                />
                <img
                  src={TrashIcon}
                  alt="Trash Icon"
                  width={24}
                  height={24}
                  id={`delete_${news._id}`}
                  className="cursor-pointer ms-3"
                  onClick={() => {
                    setItemIdToDelete(news._id)
                    setShowDeleteModal(true)
                  }}
                />
              </div>
              <div style={{ marginRight: 10 }}>
                <Switch
                  onClickHandler={() => {
                    handleStatusClick(news._id, news.status)
                  }}
                  isChecked={news.status === 1 ? true : false}
                  disabled={selectedId === news._id && isLoading}
                />
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    </Col>
  )
}

export default SingleNews
