import usFlag from "../assets/images/flags/us.jpg"
import saudi from "../assets/images/flags/saudi.svg"

const languages = {
  en: {
    label: "English",
    flag: usFlag,
  },
  sa: {
    label: "عربي",
    flag: saudi,
  },
}

export default languages
